import React from 'react';
import planeta from './../functions/mainpage.js';
import logo from './../../img/logo.png';
import './../../index.css'


 export default class Login extends React.Component {
    constructor() {
        super();   

        this.state = {
           mail:"", pass:"", action:"login", mode:0, securityToken:""
        };
        this.updateForm = this.updateForm.bind(this);
        this.recovery = this.recovery.bind(this);
        this.ancorano = this.ancorano.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 

    componentDidMount()
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var mail = urlParams.get('mail')
        var token = urlParams.get('token')
        if (planeta.isMail(mail))
        {
            console.log(mail, token);
            this.setState({mail:mail, securityToken:token, mode:0})
        }
        
    }
    ancorano()
    {
        this.setState({mode:0})
    }
    checkIt(ev)
    {
        // var x = planeta.verifyLoginData(ev.target.dataset.content, ev.target.value);
        // if (x){ev.target.parentElement.children[2].innerHTML = x.response;}
        // document.getElementById("login").disabled = planeta.isCorrect("loginForm",document.getElementsByClassName("validation"))
        
    }
    updateForm(data)
    {
        var self = this;
        switch (data.target.dataset.content)
        {
            case "mail": self.setState({mail: data.target.value}); break;
            case "pass": self.setState({pass: data.target.value}); break;
            default:break;
        }
        
    }
    handleSubmit = (ev) =>{
        console.log(ev)
        ev.perventDefault();
    }
    recovery()
    {
        var checkMail = false;
        //if (!planeta.isMail(this.state.login)){return;}
        this.setState({mode:1})
    }
    render() {
        if (this.state.mode===0)
        {
     return(
         <>
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
    <div className="loginform">  
    <div className="row">
    <div className="col-sm-6"><img src={logo} alt="logo"/></div>
    <div className="col-sm-6"><h3><br></br>Planeta Rozwoju</h3> </div>
      
    <div> </div>
   
    </div>  
       
        <div>
            <label>mail</label>
            <input type="mail" data-content="mail" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm} onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div> 
         <div>
            <label>Hasło</label>
            <input type="password" data-content="pass" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm}  onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div>
         <div><small className="red">{this.props.response}</small></div>
         <div>
             <button id="login" data-content={JSON.stringify(this.state)} className="btn btn-success"  onClick={this.props.askit}>Logowanie</button>&nbsp;
             <a className="btn btn-primary" href='https://planetarozwoju.pl/zapisy/' target="__BLANK" >Rejestracja</a>
             {/* <span className="float-right"><button className="btn btn-danger">Odzyskiwanie hasła</button></span> */}
         </div>
         <div>
             <span className="remindPassword"  data-content={JSON.stringify(this.state)}  data-content={JSON.stringify(this.state)} onClick={this.recovery}>Nie pamiętasz hasła? Kliknij, aby je odzyskać</span>
         </div>
        
    </div>
        </>
     );}
     else if (this.state.mode===1)
     {
         if (this.props.recovery==="")
         {
         return (<>
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
    <div className="loginform">  
    <div className="row">
    <div className="col-sm-6"><img src={logo} alt="logo"/></div>
    <div className="col-sm-6"><h3><br></br>Planeta Rozwoju</h3> </div>
      
    <div> </div>
   
    </div>  
       
        <div>
            <label>mail</label>
            <input type="mail" data-content="mail" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm} onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div> 
         <div><small className="red">{this.props.response}</small></div>
         <div>
             <button id="login" data-content={JSON.stringify(this.state)} className="btn btn-success"  onClick={this.props.reset}>Resetuj hasło</button>&nbsp;
             <button id="login" data-content={JSON.stringify(this.state)} className="btn btn-success"  onClick={this.ancorano}>Powrót</button>&nbsp;
             {/* <span className="float-right"><button className="btn btn-danger">Odzyskiwanie hasła</button></span> */}
         </div>
        
        
    </div>
        </>)}
        else if (this.state.securityToken==="")
        {
            return (<>
                <div className="stars"></div>
                <div className="twinkling"></div>
                <div className="clouds"></div>
        <div className="loginform">  
        <div className="row">
                <div className="col-sm-12">
                <h3>Komunikat serwera</h3>
                <span dangerouslySetInnerHTML = {{__html: this.props.recovery}}></span>
                {/* {this.props.recovery} */}
                </div>
        </div>
        </div></>)
        }
        else
        {
            return(<div>fshdlhsdf</div>)
        }
     }

     else {
         return (<><div className="stars"></div>
         <div className="twinkling"></div>
         <div className="clouds"></div>
                <div className="loginform">
                {this.props.response}
                </div>
         </>)
     }
    }
 }