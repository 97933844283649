import React from 'react';
import {FaPowerOff, FaUserAlt, FaGraduationCap} from "react-icons/fa";
import {FiMessageSquare} from 'react-icons/fi';
import {MdForum} from 'react-icons/md';
import $ from 'jquery';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactTooltip from 'react-tooltip'
import planeta from './../functions/timedifference.js'

export default class Toppanel extends React.Component {
  constructor() {  
    super();   
    this.state = {searchBy:1, toSend:"", chatWitch: "Lista chatów", chatDriver:null, chatTimer:10000, 
    chatRooms:{rooms:[]},chatData:[],
    chatComment:"",numberUnreadMessages:0
  }
    this.ask = this.ask.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.prepareMessage = this.prepareMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.openChat = this.openChat.bind(this);
    this.closeChat = this.closeChat.bind(this);
    this.commentElement = this.commentElement.bind(this);
    
    };
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            var modules = self.state.modules;
            switch(command)
            {    
                case 'getMyChats':
                  self.setState({chatRooms:response.data.data});
                  var badges = planeta.chatSumBadges(response.data.data, self.props.userId);
                  self.setState({numberUnreadMessages:badges});
                  break;  
                case 'getOneChat':
                  self.setState({chatData:response.data.data});
                  planeta.markAsReaded(data,response.data.data, self.props.userId)

                break; 
                case 'saveMessage':self.ask("Chat", "getOneChat",JSON.stringify({chat:self.state.chatWitch, user:self.props.userId}));        
                default:break;
            }
            self.setState({modules:modules});
        }
        else
        {
            console.log(response.data);
        }
        }).catch(function (error) {console.log(error);});
    }

    componentDidMount()
    {
     $(document).ready(function(){
       $(".addToMyChat").click(function(ev){alert(ev)})
     })
     
      var self = this;
      this.ask("Chat", "getMyChats", self.props.userId);
      clearInterval(this.state.chatDriver);
      //this.state.chatDriver = setInterval(function(){self.ask("Chat", "getMyChats", self.props.userId)},self.state.chatTimer)
    }
    openChat(ev)
    {
      var self = this;
      $("#chatList").hide();
      $("#chatMessages").show();
      $("#chatTools").show();
      var chat = ev.target.dataset.id;
      this.setState({chatWitch: ev.target.dataset.id});
      this.ask("Chat", "getOneChat",JSON.stringify({chat:chat, user:this.props.userId}));
      clearInterval(this.state.chatDriver);
      this.state.chatDriver = setInterval(function(){self.ask("Chat", "getOneChat",JSON.stringify({chat:chat, user:self.props.userId}));},10000)


    }
    closeChat(ev)
    {
      $("#chatList").show();
      $("#chatMessages").hide();
      $("#chatTools").hide();
      this.setState({chatWitch: "Lista chatów"});
      //$("#chatMessages").html("");
      this.setState({chatData:[]});
      clearInterval(this.state.chatDriver);
      var self=this;
      this.state.chatDriver = setInterval(function(){self.ask("Chat", "getMyChats", self.props.userId)},self.state.chatTimer)

    }
    prepareMessage(ev)
  {
    this.setState({toSend:ev.target.value});
  }
  commentElement(ev)
  {
    // switch (ev.data.dataset.id)
    // {
    //   case "chat": this.setState({chatComment:"Lista chatów"}); break;
    //   default: this.setState({chatComment:"Lista chatów"}); break;
    // }
  }
  sendMessage(ev)
  {
    if (ev.key === 'Enter') {
      var message = this.state.toSend;
      if (message === ""){return;}
      this.ask("Chat", "saveMessage", JSON.stringify({room:this.state.chatWitch, name:this.props.username, message:message}));
      this.setState({toSend:""});
      var date = new Date();
     // $("#chatMessages").append('<div className="chatmessage you"><div className="chatmessage header">Ty (Dziś '+ date.getHours()+':'+ date.getMinutes() +')</div><div className="chatmessage body">'+ message +'</div></div>');
      var element = document.getElementById("chatMessages");
        element.scrollTop = element.scrollHeight;
    }
  }
  toggleChat()
  {
    var chat = document.getElementById('chatPlaneta')
    if (chat.classList.contains("active")){chat.classList.remove("active")}else{chat.classList.add('active')}
  }
    render() {
      var roles=["GodMode", "Administrator", "SubAdministrator", "Manager", "Sekretariat", "Lektor", "Rodzic"]
     return(
         <div className="toppanel"> <FaUserAlt /> {this.props.username} ({roles[parseInt(this.props.userRights)]}) &nbsp;{this.props.userRights}
         {(typeof this.props.activeDepartment.nazwa!=='undefined') ? (
           <span className="defaultDepartment"><FaGraduationCap /> {this.props.activeDepartment.nazwa}</span>
         ):""}
         <span className="float-right ">
            <span className="logout" onClick={this.toggleChat}><FiMessageSquare onClick={this.props.logout}/> ChatPlaneta {(this.state.numberUnreadMessages>0) ? (<span className="badge badge-danger">{this.state.numberUnreadMessages}</span>):""}</span>
            
            &nbsp;&nbsp;
            <span className="logout" onClick={this.props.logout}><FaPowerOff onClick={this.props.logout}/> Wyloguj</span>
         </span>
         
         <div className="chatbox " id="chatPlaneta">
              <div className="chatHeader">ChatPlaneta ({this.state.chatWitch})</div>
              <div className="chatTools" id="chatTools" style={{display:"none"}}>
                <i className="far fa-arrow-alt-circle-left" onClick={this.closeChat} data-id="close"></i>
                <i className="fa fa-times red"></i>
                <span className="float-right">{this.state.chatComment}</span>
              </div>
              <div className="chatList" id="chatList">
                {(this.state.chatRooms.rooms.length==0) ? (<div className="noChatMessage">Na chwilę obecną nie masz żanych otwartych Chatów</div>):(<div className="activeChat">Twoje aktywne chaty</div>)}
                {this.state.chatRooms.rooms.map((key, i)=>(<div key={i} data-id={key.room} onClick={this.openChat} className="chatNode">
                  {key.room}
                  <span className="float-right chatTime">{key.timestamp} <span className="badge badge-pill badge-primary">{key.last}</span></span>
                </div>))}
                 
              </div>
             
              <div className="chatMessages" id="chatMessages" style={{display:"none"}}>
              
              
                {/* <div className="chatmessage my">
                  <div className="chatmessage header">{this.state.chatWitch} (Dziś, 20:17)</div>
                  <div className="chatmessage body"> Moja wiadomość</div>
                </div>
                <div className="chatmessage you">
                  <div className="chatmessage header">Ty (Dziś, 20:17)</div>
                  <div className="chatmessage body"> Moja wiadomość</div>
                </div> */}

                {this.state.chatData.map((key)=>(<div key={key.id} className={(key.author!==this.props.username)? "chatmessage my" : "chatmessage you"}>
                <div className="chatmessage header">{(key.author===this.props.username)? "Ty" : key.author} ({key.timestamp})</div>
                <div className="chatmessage body">{key.message}</div>
                </div>))}
              </div>
              <div className="chatFooter"><input type="text" placeholder="Napisz wiadomość" className="myInput" value={this.state.toSend} onChange={this.prepareMessage} onKeyDown={this.sendMessage}/></div>
            </div>
         </div>

     );
    }
 }