import React from 'react';
import {Link} from 'react-router-dom';


export default class CodesitWeek extends React.Component{
    constructor() {  
        super();   
        this.state = {today:1, rows:[[]]}

        this.componentDidMount = this.componentDidMount.bind(this);
        this.generateDay = this.generateDay.bind(this);
        this.getData = this.getData.bind(this);
        };

    
    componentDidMount()
    {
        var rows = [ "", "", "", "", "", "", "", ""]
      
        rows.push([this.props.end + ":00", "", "", "", "", "", "", ""])
        this.setState({today:this.props.day.getDay(), rows:rows})
    }
    landingZero(x){if (x<10){return "0"+x;}else{return x;}}
    generateDay(i,today, withYear=false)
    {
        if (i===0){return ""}
        var newDate = new Date(this.props.day).getTime() + (i-today)*1000*60*60*24 + (this.props.ofset * 7);
        var odp = new Date(newDate);
        if (withYear)
        {
            return odp.getFullYear() +"-" +this.landingZero(odp.getMonth())+"-"+this.landingZero(odp.getDate());
        }
        else
        {
            return this.landingZero(odp.getMonth())+"-"+this.landingZero(odp.getDate());
        }
        
    }

    calculateStyle(wpis)
    {
        
        // future, current, passat
        var dzien = new Date(wpis.dzien).getTime();
        var closing = new Date(wpis.closing).getTime();
        var now = new Date().getTime();
        console.log("DATY", dzien, closing,now)
        if (dzien>now) {return 0;}
        else if (dzien<closing) {return 1}
        else {return 2}

    }
    getData(i,today)
    {
        var odp ="";
        var icon =['<i className="fas fa-lock-open"></i>','<i className="fas fa-lock-open"></i>', '<i className="fas fa-lock"></i>'];
        var date = this.generateDay(i,today, true)
        for (var x = 0; x<this.props.data.length; x++)
        {
            var wpis = this.props.data[x]
            if (wpis.dzien===date)
            {
                
                this.calculateStyle(wpis); 
                console.log("Dane", wpis);
                odp= "<div className='alert alert-danger'>"+ this.calculateStyle(wpis) +wpis.godzina+ " "+ wpis.nazwa+"</div>";
            }
        }
        return odp;
    }
    render(){  

        if (typeof this.props.data == 'undefined')
        {
            return (<div>Ładowanie....</div>)
        }
        else
        {
            var weekdays =['','poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']
            
            
            return (<div> 
            <table className="codesitweek">
                <thead>
                    <tr>
                        {weekdays.map((key,i)=>(<td key={i} className={(i===this.state.today) ? "codesitweek gray":"codesitweek"}><div>{key}</div><div>{this.generateDay(i, this.state.today)}</div></td>))}
                    </tr>         
                </thead>
                <tbody>
                    <tr>
                        {weekdays.map((key,i)=>(<td key={i} className={(i===this.state.today) ? "codesitweek gray":"codesitweek"}><span dangerouslySetInnerHTML= {{__html:this.getData(i, this.state.today)}} ></span></td>))}
                    </tr>
                </tbody>
                 
            </table>
        </div>)
        }
       
        
    }
}


