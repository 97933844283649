import React from 'react';
import{Link} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal'

// import ParentCalendar from './parentcalendar.js';

// import CoursesList from './courseslist.js';
// import NewsList from './newslist.js'
import TimeLine from './timeline.js';


export default class ParentMainData extends React.Component {
  constructor() {  
    super();   

    this.state = {newses:[], newsPage:0,news:[],
      projectedCourses:[], tip:"Tip dnia"
    };
    this.ask=this.ask.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getNewses = this.getNewses.bind(this);
    this.getProjectedCourses = this.getProjectedCourses.bind(this);

} 

getNewses(){this.ask("info", "getNews", this.state.newsPage);}
getProjectedCourses(){this.ask("info", "getProjectedCourses", this.state.newsPage);}
componentDidMount(){
  // this.getNewses();
  // this.getProjectedCourses();
  this.ask("groups", "getInfoForParent", null);
  this.ask("groups", "getTipOfTheDay", null);
}
  ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK") 
        {
            console.log("COMMAND:", command);
            var modules = self.state.modules;
            switch(command)
            {    
                case 'getNews':
                  var currentNews = self.state.newses;
                  var newNews = response.data.data;
                  for (var a = 0; a<newNews.length; a++)
                  {
                    currentNews.push(newNews[a]);
                  }
                  self.setState({newses:currentNews, newsPage:self.state.newsPage+1})
                break;   
                case 'getProjectedCourses':
                  self.setState({projectedCourses:response.data.data});
                break;
                case 'getInfoForParent':
                    var data = response.data.data;
                    self.setState({news: data});
                    break;
                case 'getTipOfTheDay':
                  self.setState({tip:response.data.data});
                  break;
                default:break;
            }
            self.setState({modules:modules});
        }
        else
        {
            console.log(response.data);
        }
        }).catch(function (error) {console.log(error);});
    }
    render() {
     return(
     <div className="container">

     <div className="row">
        <div className="col-sm-12">
          <TimeLine  data={this.state.news}/>
          <div>
            <h3>Biurko <i className="fas fa-desktop"></i></h3>
            {this.state.news.length===0 && (<div className="alert alert-info"><h5>Info</h5>Nie masz jeszcze żadnych informacji od lektorów. Pojawią się wówczas, gdy lektor wypełni dziennik. Jeśli jesteś zainteresowany jakimś kursem możesz przejść do Sklepu z kursami.  <Link className="btn btn-secondary btn-sm" to="/shop/index">Sklep z kursami</Link></div>)}
            <div className="alert alert-info"><h5>Info</h5>Nie masz więcej wiadomości. Wiadomości pojawią się na biurku wówczas, gdy ktoś z osób zarządzających szkołą lub sam lektor doda wpis w dzienniku kursu.</div>
          {this.state.tip && (<div className="alert alert-success"><h5>Podpowiedź dnia</h5>{this.state.tip}</div>)}
          </div>
        </div>
     </div>
     {/* <div className="row">
        <div className="col-sm-5">
            <ParentCalendar />
        </div>
        <div className="col-sm-7">
            <CoursesList data={this.state.projectedCourses} />
        </div>
    </div>
    <div className="row">
     
        <div className="col-sm-12">
            
            <NewsList data={this.state.newses} moreNews={this.getNewses}/>
        </div>
        
     </div> */}

     <Modal
        show={this.state.createModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideCreateModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Tworzenie nowego programu nauczania
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
              <label>Nazwa</label>
            <input type="text" className="myInput" onChange={this.createName} value={this.state.newName}/>
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideCreateModal}><i className="far fa-times-circle"></i> Zamknij</button>
            <button className="btn btn-success" onClick={this.sendToCreate}><i className="far fa-times-circle"></i> Utwórz</button>

        </Modal.Footer>
      </Modal>

     </div>
     

     );
    }
 }