import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import finder from './../../functions/finddataset.js';
import axios from "axios";
import Cookies from 'js-cookie';
import Loader from './../loader.js';
import {FaRegSave, FaNetworkWired, } from "react-icons/fa";
import {IoIosCalendar}  from "react-icons/io";
import {Link} from 'react-router-dom'
import planeta from './../../functions/mainpage.js';

import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';export default class EditPlace extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false,placedata:[], loaded:true, 
        };
        this.hideModal = this.hideModal.bind(this);

    } 
    

    hideModal()
    {
        this.setState({deleteModal:false})
    }
    
    

    render() {
        if (this.state.loaded && this.state.userRights<5)
        {
      var data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400},
      {name: 'Page A', uv: 200, pv: 2300, amt: 2200},
      {name: 'Page A', uv: 400, pv: 2400, amt: 2400},
      {name: 'Page A', uv: 400, pv: 2400, amt: 2400},
      {name: 'Page A', uv: 400, pv: 2400, amt: 2400},
      {name: 'Page A', uv: 400, pv: 2400, amt: 2400}]
     return(
         <div className="container">
            <div>
            <h3>Resume</h3>
                {JSON.stringify(this.props.resume)}
                <div className="row">
                  <div className="col-sm-6">
                    <LineChart width={(window.innerWidth/3)} height={400} data={data} style={{width:"100%"}}>
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                    </LineChart>
                  </div>
                  <div className="col-sm-6">
                    <LineChart width={window.innerWidth/3} height={400} data={data} style={{width:"100%"}}>
                      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                    </LineChart>
                  </div>
                </div>
                
                <LineChart width={window.innerWidth/3} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                  <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="name" />
                  <YAxis />
                </LineChart>
            </div>
        
     



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           <FaNetworkWired /> Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>OK</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
        }else{return (<Loader />)}
    }
 }