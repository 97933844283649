import React from 'react';
import {Link} from 'react-router-dom';

export class PlanetaCard extends React.Component{
    render(){
        return (<div className="card .bg-light">
        <div className="card-body text-center">
          <p className="card-text"><b>{this.props.title}</b></p>
          <small>{this.props.text}</small>
        </div>
        <Link className="btn btn-secondary btn-xs btn-card-dark" to={this.props.link} >Więcej</Link>
      </div>)
    }
}


