import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Reactable from 'reactable';
import planeta from './../../functions/mainpage.js';
import CodesitTable from './../common/codesittable.js';
export default class ParentIndex extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",genereList:false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.toogleMode = this.toogleMode.bind(this);
        

    } 
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
        var sa = planeta.getStudentIdFromTable(ev);
        if ((sa.tag==="TD" || sa.tag==="TR") && sa.child>2)
        {        
           window.location.replace(window.location.protocol+"//"+window.location.host+ '/parents/studentinfo/' +sa.id )
        }    
    }
    getParentInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
        var sa = planeta.getStudentIdFromTable(ev);
        if ((sa.tag==="TD" || sa.tag==="TR") && sa.child>2)
        {        
           window.location.replace(window.location.protocol+"//"+window.location.host+ '/parents/parentinfo/' +sa.id )
        }
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    componentDidMount()
    {
        this.props.ask('students', 'getStudentsList', null);
        this.props.ask('students', 'getParentList', null);
    }
    toogleMode(){this.setState({genereList:!this.state.genereList})}
    render() {
       var Tr = Reactable.Tr, Thead = Reactable.Thead, Th = Reactable.Th;
       var tryb = "Przełącz na listę rodziców";
       if (this.state.genereList===true){tryb = "Przełącz na listę uczniów"}
       var headers = [{title:"Imię", data:"imie"}, {title:"Nazwisko", data:"nazwisko"}, {title:"Placówka", data:"nazwa"},{title:"Klasa", data:"klasa"},
       {title:"Telefon", data:"telefon"},{title:"Mail opiekuna", data:"mail"}]
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Panel rodzica i dziecka  
                        <span className="float-right btn-group">
                        <button className="btn btn-info" onClick={this.toogleMode}>{tryb}</button>

                        </span></h3>
                        <CodesitTable 
                            headers={headers}
                            data={this.props.studentList} 
                            link={"/parents/studentinfo/"}
                        />

                </div>    
            </div>


         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Title
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }

class PRTable extends React.Component{render(){return (<thead><tr><td>ID</td><td>Nazwa</td><td>Opcje</td></tr></thead>)}}
class PRRow extends React.Component{render(){return (<tr><td>{this.props.id}</td><td>{this.props.nazwa}</td><td>{this.props.opcje}</td></tr>)}}