import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';

export default class EditProceList extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",
            priceList:{data:{body:[]}},data:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.chengeValue = this.chengeValue.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.saveCennik = this.saveCennik.bind(this);
        this.deleteCennik = this.deleteCennik.bind(this);
        
    }
    deleteCennik()
    {
        let self = this;
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Usunięcie cennika powoduje usunięcie zależności pomiędzy cennikiem a poszczególnymi kursami. Czy jesteś pewien, że chceszusunąć ten cennik",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.ask('administration', 'deleteCennik', this.state.priceList.id)}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    
        
    }
    saveCennik()
    {
        var cennik = this.state.priceList;
        let name = this.state.priceList.name;
        let link = window.location.href.split('/')[ window.location.href.split('/').length-1];
        var toSave = JSON.stringify({name:name,data:cennik.data, id:link})
        this.ask("administration", "saveCennik", toSave);
    }
    
    chengeValue(ev)
    {
        var cennik = this.state.priceList;
        //console.log(ev.target.dataset.row, ev.target.dataset.cell, typeof ev.target.dataset.row !=='undefined')
        if (typeof ev.target.dataset.row !=='undefined')
        {
            var v = parseInt(ev.target.value)
            if (v.toString==="NaN"){v=0;}
            cennik.data.body[ev.target.dataset.row-1][ev.target.dataset.cell]=v
        }
        else
        {
            console.log(ev.target.value)
            cennik.name = ev.target.value;
        }
        this.setState({priceList:cennik})
    }
    componentDidMount()
    {
        var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        this.setState({id:id});
         this.ask('administration', "getPriceListToEdit", id)
        // this.ask('administration', "getCennikList", null)
    }
    ChangePanel(ev)
    {
        console.log(ev.target);
        ev.target.class.add("open");

    }
    getInfo(ev)
    {

        console.log("PYTANIE",ev.target.dataset)
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getPriceListToEdit':
                    var data = response.data.data;
                    data.data = JSON.parse(data.data);
                    self.setState({priceList:data});break;
                case 'getCennikList':self.setState({cennik:response.data.data, data:data.data});break;
                case 'saveCennik': window.toast.success('Dane cennika zapisano');break;
              
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.cennik.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        
     return(
        <div className="container">
            <div className="row">
                
                <div className="col-sm-12">
                    <h3>Edycja cennika

                        <span className="float-right">
                        <Link to="/administration/"  className="btn btn-info btn-sm">Powrót</Link>&nbsp;
                        <Link to="/administration/" onClick={this.deleteCennik} className="btn btn-danger btn-sm">Usuń</Link>&nbsp;
                            <button onClick={this.saveCennik} className="btn btn-success btn-sm">Zapisz</button>
                        </span>
                    </h3>

                    <label>Nazwa cennika</label>
                    <input className="myInput" placeholder="Wpisz nazwę cennika" value={this.state.priceList.name} onChange={this.chengeValue}/>
                </div>    
            </div>
            <div className="row">
                <div className="col-sm-12">
                    
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>dzieci</td>
                                    <td>Cena kursu</td>
                                    <td>Koszt lektora</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.priceList.data.body.map((key, i)=>(<tr key={i}>
                                <td>{key[0]}</td>
                                <td><input type="number" data-row={key[0]} data-cell="1" onChange={this.chengeValue} value={key[1]} /></td>
                                <td><input type="number" data-row={key[0]} data-cell="2" onChange={this.chengeValue} value={key[2]} /></td>
                        </tr>))} 
                            </tbody>
                        </table>
                        
                </div>
               
                
            
            </div>



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kasowanie oddziału "{this.state.prepareName}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }

