const rightEngine = {
    cell:{show:false,edit:false,delete:false},
    cells:{id:"",filie:{show:true,edit:false,delete:false},placowki:{show:true,edit:false,delete:false},grupy:{show:false,edit:false,delete:false},programy:{show:false,edit:false,delete:false},pracownicy:{show:false,edit:false,delete:false},rodzice:{show:false,edit:false,delete:false},administracja:{show:false,edit:false,delete:false}, rozliczenia:{show:false,edit:false,delete:false} },
    errors:[],
    jsonRight: "",
    rightInDepartment:{},
    accessRight:[],
    check:function(id, json,dzial,  operation){
        this.initialize(id, json);
        this.select(id);
        var a = this.checkRight(dzial, operation);
        return a;
    },
    select:function(id){
        for (var a = 0; a<this.accessRight.length;a++)
        {
            if (this.accessRight[a].id===id){this.rightInDepartment = this.accessRight[a];}
        }
    },
    checkIfExist(id)
    {
        for (var a = 0; a<this.accessRight.length;a++)
        {
            if (this.accessRight[a].id===id){return true;}
        }
        return false;
    },
    initialize: function(id, json){
        try {
            var temp = JSON.parse(json);
            this.accessRight = temp;
        } catch (error) {
            this.errors.push(error);
            if (!this.checkIfExist(id))
            {
                var upr = this.cells;
                upr.id = id;
                this.accessRight.push(upr)
            }
        }
    },
    checkRight:function(dzial, operation){
        var prawa = false;
        try {
            var prawa = this.rightInDepartment[dzial][operation]
        } catch (error) {
            console.log(error)
            prawa = false;
        }
        return prawa;
    },
    setAcces(id, dzial, operation, access)
    {
        for (var a = 0; a<this.accessRight.length;a++)
        {
            if (this.accessRight[a].id===id){this.accessRight[a][dzial][operation] = access;}
        }
    }
}
export default rightEngine;