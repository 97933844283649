import React from 'react';
import{Link} from 'react-router-dom';
import {IoMdPerson, IoIosCalendar, IoMdStarOutline, IoMdStar} from  "react-icons/io";
import $ from 'jquery';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import axios from 'axios';
import Cookies from 'js-cookie';


 export default class TimeLine extends React.Component {
  constructor() {  
    super();   

    this.state = {
     
    };
   
    this.componentDidMount = this.componentDidMount.bind(this);
    this.runChat = this.runChat.bind(this);
    this.ask = this.ask.bind(this);


} 

ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
           // var modules = self.state.modules;
            switch(command)
            {    
                default:break;
            }
            //self.setState({modules:modules});
        }
        else
        {
            console.log(response.data);
        }
        }).catch(function (error) {console.log(error);});
    }
runChat(ev)
{
  console.log(ev.target.dataset)
  var ds = ev.target.dataset;
  var data = {dest:ds.destination, id:ds.id}
  this.ask("Chat", "addToChat", JSON.stringify(data));
}
componentDidMount()
{
 
}

    render() {
        if (this.props.data.length===0)
        {
            return (<></>)
        }
        else
        {
     return(<VerticalTimeline>

       {this.props.data.map((key, i)=>(
        
         <VerticalTimelineElement
         key={i}
         className="vertical-timeline-element--work"
         contentStyle={(key.temat!=="komunikat") ? { background: 'rgb(175, 219, 216)', color: '#333' }: { background: 'rgb(252, 205, 202)', color: '#333' }}
         contentArrowStyle={(key.temat!=="komunikat") ? { borderRight: '7px solid  rgb(175, 219, 216)' } : { borderRight: '7px solid  rgb(252, 205, 202)' }}
         date={key.dzien}
         iconStyle={(key.temat!=="komunikat") ? { background: 'rgb(175, 219, 216)', color: '#333' }: { background: 'rgb(252, 205, 202)', color: '#333' }}
         icon={<IoMdPerson />}
       >
        {(key.temat!=="komunikat") ? 
        (<div>
         <h3 className="vertical-timeline-element-title">{key.kurs}
  
         <span className="float-right"><i className="fas fa-users runChat" data-destination="group" data-id={key.kurs} data-toggle="tooltip" title="Czatuj z grupą!" onClick={this.runChat}></i></span>
         </h3>
         <h4 className="vertical-timeline-element-subtitle">prowadzony przez: {key.lektor}
         <span className="float-right"><i className="far fa-comments runChat" data-destination="personal" data-id={key.lektor} data-toggle="tooltip" title="Czatuj z lektorem" onClick={this.runChat}></i></span>
         </h4>
         
           <div>Uczestnik:  {key.dziecko}</div>
           <div>temat:  {key.temat}</div>
           <div>Obecność: {(key.obecnosc) ? "Obecny" :"Nieobecność"} </div>
           Wyniki:
           <div>Mówienie: {(key.obecnosc) ? <IoMdStar className="myStar color1" /> : <IoMdStarOutline />}</div>
           <div>Odrobione zadanie domowe: {(key.zadanie) ? <IoMdStar className="myStar color2" /> : <IoMdStarOutline />}</div>
           <div>Dodatkowa aktywność: {(key.aktywnosc) ? <IoMdStar className="myStar color3" /> : <IoMdStarOutline />}</div>
           {(key.test==="") ? "": (<div>Wynik testu: {key.test} p.</div>)}
           {(key.procent==="") ? "": (<div>Wynik testu: {key.procent}%</div>)}
           <div>Bierzące zadanie domowe:</div>
           {key.notatka}
           <div>
           {(key.pliki.length===0) ?"" : (<div>Materiały do pobrania:</div>)}
           {key.pliki.map((a,u)=>(<span key={"124578369"+u}><a className="" href={"/upload/" +key.link+"/" + a} >{a}</a></span>))}
           </div>
         </div>):(<div>

          <h3 className="vertical-timeline-element-title">{key.kurs}</h3>
          <div>Uczestnik: {key.dziecko}</div>
          <div>Treść komunikatu:</div>
           {key.notatka}
         </div>)}
       </VerticalTimelineElement>))}
        
        
      </VerticalTimeline>)}
    }
    
 }