import React from 'react';

import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';

 export default class ProgramReadOnly extends React.Component {
    constructor() {  
        super();   
    
        this.state = {loaded:false, program:[],
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }

    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        this.ask('dziennik', 'getProgram', id)
    }
    hideModal(){this.setState({calendarModal:false})}

    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
   
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getProgram':
                
                    self.setState({ program:response.data.data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        
     return(<div className="container">
                <div className="row">
                
                    <div className="col-sm-12">

                        <h5>Panel lektora - Program

                            {/* <span className="float-right">
                            <Link to={"/harmonogram/view/" + this.state.dzienniki[0].kursid } className="btn btn-secondary btn-sm">Harmonogram</Link>&nbsp;
                                <Link to={"/programs/view/" + this.state.dzienniki[0].program } className="btn btn-secondary btn-sm">Program</Link>
                            </span> */}
                        </h5>
                        <div className="prinfo">

                       {this.state.program.map((key)=>(<div key={key.id}>
                       <h5>Unit: {key.unit} lekcja: {key.lesson}</h5>
                       <div>

                           <span dangerouslySetInnerHTML={{__html: key.description}} ></span>
                       </div>
                     
                       </div>))}
                       
                        </div>
                
                    </div> 
                </div>
          
       
     </div>)
    }
    
 }