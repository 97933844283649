import React from 'react';
import {FaTrashAlt, FaEdit, FaRegAddressCard, FaCalendar} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import Cookies from 'js-cookie';
//import finder from './../../functions/finddataset.js';
import {Link} from "react-router-dom";
export default class Places extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",departments:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.getPlaces = this.getPlaces.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.ask = this.ask.bind(this);

    } 
    askNew = () =>{
        this.ask('departments', 'getDepartmentDataNEW', null)
    }
    componentDidMount =() =>{
        this.askNew();
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getDepartmentDataNEW':self.setState({departments:response.data.data}); console.log(response.data.data);break;
                
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);      
            window.toast.error('Błąd serwera')
        }
        }).catch(function (error) {console.log(error);});
    }
    prepareDelete = (ev) => {
        console.log("preparetodelete")
        this.setState({deleteModal:true})
    }
    getInfo(ev)
    {

        console.log("PYTANIE",ev.target.dataset)
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    getPlaces(ev)
    {
        console.log("getPlaces",ev.target.value)
        this.props.superData('defaultDepartment',ev.target.value );
        this.props.ask("departments", "getPlacesByDepartment", ev.target.value)
    }
    deletePlacowka = (ev) => {
        console.log(ev);
    }
    render() {
        
     return(
         <div className="container">
         <div>
         <h3>Placówki
         <span className="float-right"><Link className="btn btn-secondary" to="/places/new">Dodaj</Link></span>
         </h3>
         </div>

         
        <br></br>
        {/* <button onClick={this.askNew}>DEBUG</button>
        <label>Wybierz, by zobaczyć placówki oddziału</label>
        <select className="myInput" onChange={this.getPlaces}>
            <option>--- wybierz ---</option>
            {this.props.departments.map((key)=>(
                <option key={key.id} value={key.id}>{key.nazwa}</option>
            ))}
        </select>
        <hr></hr> */}
        {this.state.departments.map((key,j)=>(<div key={j}>
           <div><h4>{key.oddzial} <small>(manager oddziału: {key.manager})</small></h4></div>


           <div className="table-responsive">  
            <table key={key.id} className="table table-striped table-sm">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Nazwa</td>
                        <td>Adres</td>
                        <td>Telefon</td>
                        <td>Osoba kontaktowa</td>
                        <td>Opcje</td>
                    </tr>
                </thead>
                <tbody >
               { key.items.length>0 && key.items.map((kex,t)=>(
                        <tr key={t}>
                            <td>{kex.id}</td>
                            <td>{kex.nazwa}</td>
                            <td> {kex.ulica} {kex.nr}, {kex.kod} {kex.miejscowosc}</td>
                            <td>{kex.telefon}</td>
                            <td>{kex.kontakt}</td>
                            <td> 
                            <Link className="btn btn-sm" style={{color:"#333"}}  to={"/places/edit/" + kex.id}> <FaEdit /> Edycja</Link>
                            <Link className="btn btn-sm" style={{color:"#220073"}}  to={"/places/calendar/" + kex.id}> <FaCalendar /> Kalendarz</Link>
                            
                                <button className="btn btn-sm" data-name={kex.nazwa} data-id={kex.id} onClick={this.prepareDelete}><FaTrashAlt onClick={this.prepareDelete} className="red"/> Usuń</button>

                            </td>
                        </tr>))}
                        </tbody>
            </table>  {key.items.length===0 && (<div className="alert alert-primary">
                    <strong>Uwaga!</strong> Ten oddział nie ma przypisanych placówek. Placówki będą widoczne, gdy zostaną dodane.
                  </div>)}

        </div>
         
               
      



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kasowanie oddziału "{this.state.prepareName}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger"  onClick={this.deletePlacowka}>Usuń</button>
        </Modal.Footer>
      </Modal>


</div>

     ))}</div>)
    }
 }