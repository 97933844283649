import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';
import {FaChalkboardTeacher}  from "react-icons/fa";
import CodesitTable from './../common/codesittable.js';
import { HtmlEditor } from '@aeaton/react-prosemirror'
import { options } from '@aeaton/react-prosemirror-config-default'
import CustomEditor from './../common/customeditor.js';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";


export default class ProgramDetails extends React.Component {
    constructor() {  
        super();   
    
        this.state = {modalDelete:false,
            modalState:false, newModal:false,prepareNew:{unit:"",lesson:"",title:""},
            server:"",id:0,linkToNew:"",
           programList:[{opis:""}],
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editInput = this.editInput.bind(this);
        this.updateOpis = this.updateOpis.bind(this);
        this.saveHeaderData = this.saveHeaderData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.prepareNewLesson = this.prepareNewLesson.bind(this);
        this.hideNewLessonPrepare = this.hideNewLessonPrepare.bind(this);
        this.changeNew = this.changeNew.bind(this);
        this.saveNewLesson = this.saveNewLesson.bind(this);
        this.prepareToDelete = this.prepareToDelete.bind(this);
        this.modalDeleteHide = this.modalDeleteHide.bind(this);
        this.deleteProgram = this.deleteProgram.bind(this);


    } 
    prepareToDelete(){this.setState({modalDelete:true})}
    modalDeleteHide(){this.setState({modalDelete:false})}
    deleteProgram(){this.ask('groups', 'deleteProgram', this.state.id)}
    hideModal(){this.setState({modalState:false})}
    prepareNewLesson()
    {
        //document.getElementById('linkToNew').style.display = "none";
        this.setState({newModal:true, linkToNew:""});
    }
    hideNewLessonPrepare(){this.setState({newModal:false})}
    san(e){if (isNaN(e)){return 0}else{return e}}
    saveNewLesson()
    {
        var data = {unit:this.state.prepareNew.unit,
            lesson:this.state.prepareNew.lesson,
            title:this.state.prepareNew.title,
            id:this.state.id
        }
        if (data.lesson.length!==0 && data.unit.length!==0 &&data.title.length!==0)
        {this.ask('groups', 'saveNewLesson', JSON.stringify(data));}
        else
        {

        }
    }
    changeNew(ev)
    {
        var data = this.state.prepareNew;
        console.log("NEW", ev.target.dataset.id, ev.target.value)
        switch (ev.target.dataset.id)
        {
            case 'title':data.title=ev.target.value;break;
            case 'unit':data.unit=this.san(parseInt(ev.target.value));break;
            case 'lesson':data.lesson=this.san(parseInt(ev.target.value));break;
        }
        console.log(data, ev.target.value);
        this.setState({prepareNew:data});
    }
    updateOpis(ev)
    {
        var data = this.state.programList;
        data[0].opis = ev;
        this.setState({programList:data})
    }
    editInput(ev)
    {
        var data = this.state.programList;
        data[0].nazwa = ev.target.value;
        this.setState({programList:data})
    }
    saveHeaderData()
    {
        var data = JSON.stringify({id:this.state.id, nazwa:this.state.programList[0].nazwa, opis:this.state.programList[0].opis})
        this.ask('groups', 'saveHeaderData', data);
    }
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        this.setState({id:id});
        this.ask('groups', 'getProgramDetails', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getProgramDetails':
                var data = response.data.data;
                if (!data.opis){console.log("UPDATEOPSI", data);data[0].opis+=" ";}
                self.setState({ programList:data, loaded:true});break;
                case 'saveHeaderData':
                self.setState({modalState:true, server:"Zapisano pomyślnie"})
                break;
                case 'saveNewLesson':
                    self.setState({linkToNew:"/programs/editlesson/" +response.data.data.id});
                    document.getElementById('linkToNew').style.display = "block"
                    self.ask('groups', 'getProgramDetails', self.state.id);
                    break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        // ClassicEditor
        // .create( document.querySelector( '#editor' ), {
        //     toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        //     heading: {
        //         options: [
        //             { model: 'paragraph', title: 'Akapit', class: 'ck-heading_paragraph' },
        //             { model: 'heading1', view: 'h1', title: 'Nagłówek 1', class: 'ck-heading_heading1' },
        //             { model: 'heading2', view: 'h2', title: 'Nagłówek 2', class: 'ck-heading_heading2' }
        //         ]
        //     }
        // } )
        // .catch( error => {
        //     console.log( error );
        // } ) 
    var headers=[
        {title:"Dział", data:"unit"},
        {title:"lekcja", data:"lesson"},
        {title:"tytuł", data:"title"},
    ];
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaChalkboardTeacher /> Edycja programu nauczania <span className="float-right">
              
                <Link className="btn btn-primary btn-sm" to={"/programs/pdf/" + this.state.id}>PDF</Link>&nbsp;
                <Link className="btn btn-secondary btn-sm" to={"/programs/index"}>Powrót</Link>&nbsp;
                <button className="btn btn-danger btn-sm" onClick={this.prepareToDelete} >Usuń</button>&nbsp;

                <button className="btn btn-success btn-sm" onClick={this.prepareNewLesson}>Nowa lekcja</button>&nbsp;
                <button className="btn btn-success btn-sm" onClick={this.saveHeaderData}>Zapisz</button>
                </span></h3>
                    <div className="prinfo">
                        <div className="row">
                            <div className="col-sm-6">
                            <label>Nazwa programu</label>
                            <input className="myInput" value={this.state.programList[0].nazwa} onChange={this.editInput}/>
                            </div>
                        </div>     
                        <div className="row">
                            <div className="col-sm-12">
                            <label>Opis programu</label>
                            
                            <CKEditor
                                    editor={ ClassicEditor }
                                    data={this.state.programList[0].opis}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                       // console.log( { event, editor, data } );
                                        this.updateOpis(data);
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            <h5>Lista lekcji</h5>
                            <CodesitTable
                                headers={headers}
                                data={this.state.programList}
                                link="/programs/editlesson/"
                            />
                            </div>
                        </div>
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}><i className="far fa-times-circle"></i> Zamknij</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.modalDelete}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.modalDeleteHide}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Usuwanie programu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           Operacja usunięcia programu jest nieodwracalna. Czy na pewno chcesz trwale usunąć te dane?
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.modalDeleteHide}><i className="far fa-times-circle"></i> Anuluj</button>
            <Link className="btn btn-danger" onClick={this.deleteProgram} to="/programs/index">Usuń</Link>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.newModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideNewLessonPrepare}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Dodawanie nowego programu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <label>Tytuł</label>
           <input type="text" data-id="title" className="myInput" value={this.state.prepareNew.title} onChange={this.changeNew}/>
           <label>Unit</label>
           <input type="text" data-id="unit"  className="myInput" value={this.state.prepareNew.unit} onChange={this.changeNew}/>
           <label>Lesson</label>
           <input type="text" data-id="lesson"  className="myInput" value={this.state.prepareNew.lesson} onChange={this.changeNew}/>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideNewLessonPrepare}><i className="far fa-times-circle"></i> Anuluj</button>
            <button className="btn btn-success" onClick={this.saveNewLesson} >Zapisz</button>
            <Link className="btn btn-primary" to={ this.state.linkToNew} id="linkToNew" style={{display:"none"}}>Przejdź</Link>
        </Modal.Footer>
      </Modal>
         </div>

     );
    }
 }