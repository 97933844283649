import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";
import CodesitTable from './../common/codesittable.js';

export default class ActiveGroupLessonDetails extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",
            grupy:[[{nazwa:"", kursid:0}]], loaded:false,
            groupStatus:[],modalEdycja:false
        };
        this.hideModal = this.hideModal.bind(this);
        this.hideEdycja = this.hideEdycja.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 
    componentDidMount()
    {
        var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        this.setState({id:id});
        
        this.ask('groups', 'getActiveGruplessonDetails', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    hideEdycja(){this.setState({modalEdycja:false});}
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getActiveGruplessonDetails':self.setState({ grupy:response.data.data, loaded:true});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
    var headers=[{title:"Dzień", data:"dzien"},
    {title:"Godzina", data:"godzina"},
    {title:"Temat", data:"temat"},
    {title:"Lektor", data:"prowadzacy"},
    {title:"Należność", data:"naleznosc"},
]
        
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaFolderOpen /> Szczegóły Lekcji
                <span className="float-right">
                    <Link className="btn btn-primary btn-sm" to='/groups/index'>Grupy</Link>&nbsp;
                    <Link className="btn btn-primary btn-sm" to='/groups/active/index'>Aktywne</Link>&nbsp;
                    <Link className="btn btn-primary btn-sm" to={'/groups/active/edit/' + this.state.grupy[0].kursid}>Szczegóły</Link>
                </span>
                </h3>
                    <div className="prinfo">
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Nazwa grupy</label>
                                <div className="myInput">{this.state.grupy[0].nazwa}</div>
                                <label>Prowadzący lekcję</label>
                                <div className="myInput">{this.state.grupy[0].prowadzacy}</div>
                            </div>
                            <div className="col-sm-4">
                                <label></label> 
                            </div>
                        </div>               
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>

         </div>

     );
    }
 }