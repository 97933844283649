import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import passStr from './strengthmeter.js';
import shop from '../../../img/shops.png';

 export default class UserSetup extends React.Component {
    constructor() {
        super();   

        this.state = {
           pass:{oldPass:"", newPass:"",retryPass:""},
           strength:"", licenseKey:"",
           per:{zgoda1: true, zgoda2: true, zgoda3:false}

        };
        this.ask = this.ask.bind(this);
        

    } 
    noPossibile(){
        window.toast.info("Te dane są potrzebne do realizacji usługi, która została zamówiona. Aby wycofać usługę zlikwiduj swoje konto.")
    }

    changePermission = (ev) => {
        console.log(ev.target.name, ev.target.checked)
        let self = this;
        let name = ev.target.name;
        let val = ev.target.checked ? 1 : 0;
        let data = {zgoda:name, value:val};
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Czy masz pewność, że chcesz zmienić ustawienia zgód marketingowych?",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.ask('user', 'changeUserConfirms', JSON.stringify(data))}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }
    componentDidMount = () =>
    {
        this.getLicenseKey();
        this.ask('user', 'getUserConfirms', null);
        
    }
    getLicenseKey = () => {
        this.ask("user", "getLicenseKey", null)
    }
    readPass = (ev) =>{
        let pass = this.state.pass;
        let name = ev.target.name;
        let value = ev.target.value;
        pass[name] = value;
        this.setState({pass});
        let str = passStr.scorePassword(pass.newPass);
        str = passStr.checkPassStrength(Number(str));
        this.setState({strength:str})
    }

    sendChangePass = () =>{
        let pass = this.state.pass;
        console.log(pass)
        if (pass.newPass !== pass.retryPass){window.toast.error("Wprowadzone hasła nie są identyczne");return;}
        if (this.state.strength === ""){window.toast.error("Hasło musi być mocniejsze");return;}
        this.ask('user', 'changePass', JSON.stringify(pass))
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getLicenseKey': 
                var x = response.data.data
                var pattern = /(-?\d+)(\d{3})/;
                while (pattern.test(x))
                    x = x.replace(pattern, "$1-$2");

                self.setState({licenseKey: x});break;
                case 'getUserConfirms':
                    let per = {zgoda1: true, zgoda2: true, zgoda3:false}
                    let data = response.data.data;
                    per.zgoda1 = data.zgoda1==='1';
                    per.zgoda2 = data.zgoda2==='1';
                    per.zgoda3 = data.zgoda3==='1';
                    self.setState({per})
                    console.log("PERMISSION",response.data.data)
                    break;
                case 'changeUserConfirms':
                    self.ask('user', 'getUserConfirms', null);
                    window.toast.success("Dokonano aktualizacji informacji o zgodach marketingowych");
                break;
                case 'changePass': window.toast.success("Twoje hasło zostało zmienione");
                self.setState({pass:{oldPass:"", newPass:"",retryPass:""}});
                default:break;
                
            }
        }
        else
        {
            window.toast.error("Wystąpił nieoczekiwany błąd")
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);window.toast.error("Błąd sieci");});
    }
    wkrotce() {
        window.toast.info("Informajcja o postępach Twojego dziecka i chat z lektorem jest w przygotowaniu. Przeniesiemy Cię do sklepu z aplikacjami, kiedy będzie funkcjonalność dostępna")
    }
    render() {
       const {per} = this.state;
            return(<div className="container">
            <div className="col-sm-12">
            <h3>Zmiana hasła i zgód marketingowych</h3>
            <div className="prinfo">  
        <div className="row">
        <div className="col-sm-4">
            <label>Stare hasło</label>
            <input type="password" className="myInput" name="oldPass" value={this.state.pass.oldPass} onChange={this.readPass}/>
            <label>Nowe hasło  <small>{this.state.strength}</small></label>
            <input type="password" className="myInput" name="newPass" value={this.state.pass.newPass}  onChange={this.readPass}/>
            <label>Powtórz nowe hasło</label>
            <input type="password" className="myInput" name="retryPass" value={this.state.pass.retryPass}  onChange={this.readPass}/>
                <br></br>
                <br></br>
            <button className="btn btn-success btn-sm" onClick={this.sendChangePass}>Zmień</button>
                <br></br>
            <br></br>
            <label>Twój klucz licencyjny do aplikacji modbilnej</label>
            <div className="myInput">{this.state.licenseKey}</div>
            <img src={shop} className="col-sm-6" onClick={this.wkrotce}/>
        </div>
        <div className="col-sm-8">
        <table className="nakedTable">
            <thead></thead>
            <tbody>
            <tr>
                <td><input type="checkbox" className="form-input" checked={per.zgoda1 ? "checked": ""} onClick={this.noPossibile}/></td>
                <td>Zgoda na przetwarzanie danych osobowych dziecka - Wyrażam zgodę na przetwarzanie danych osobowych dziecka, którego jestem rodzicem (opiekunem) w celu zawarcia oraz realizowania umowy zawartej pomiędzy mną firmami: KM Consulting Marek Stępień prowadzącym jednoosobową działalność gospodarczą pod marką Centrum Edukacji Planeta Rozwoju, wykonywaną w Książenicach (05-825) ul. Młodości 2a lok.6, NIP:7731008563, REGON: 590378715, jak również w celu informowania mnie o postępach dziecka w nauce osoby. Równocześnie oświadczam, że jestem uprawniona(y) do wyrażenia niniejszej zgody. Oświadczam ponadto, że zapoznałam(em) się z klauzula informacyjną dotyczącą przetwarzania danych osobowych przez KM Consulting Marek Stępień.
</td>
            </tr>
            <tr>
            <td><input type="checkbox" className="form-input"  name={"zgoda2"} checked={per.zgoda2 ? "checked": ""} onClick={this.changePermission}/></td>
                <td>Zgoda na przesyłanie informacji handlowej i marketing bezpośredni - Oświadczam, że dobrowolnie wskazałem swój adres e-mail oraz numer telefonu w celu otrzymywania informacji handlowej od KM Consulting Marek Stępień prowadzącego jednoosobową działalność gospodarczą pod marką Centrum Edukacji Planeta Rozwoju, wykonywaną w Książenicach (05-825) ul. Młodości 2a lok.6, NIP:7731008563, REGON: 590378715. Równocześnie wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego przez w/w podmiot. Jestem świadomy, że udzielone zgody mogę wycofać w każdym czasie.
</td>
            </tr>
            <tr>
            <td><input type="checkbox" className="form-input" name={"zgoda3"} checked={per.zgoda3 ? "checked": ""} onClick={this.changePermission}/></td>
                <td>Chcę być informowanym o rabatach, nowych kursach, zajęciach i chcę otrzymywać informacje handlowe związane z działalnością Centrum Edukacji Planeta Rozwoju. (opcjonalne)
</td>
            </tr>

            </tbody>
        </table>
            

        </div>
    </div></div>
            </div>
    
            </div>)
    

     
    }
 }