import React from 'react';
import{Link} from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal'
import {TiShoppingCart, TiCalendar, TiMap, TiGroupOutline, } from "react-icons/ti";
import {IoIosChatboxes, IoIosChatbubbles, IoIosCloudDownload, IoIosCalendar } from "react-icons/io";
import {FaChalkboardTeacher, FaUsers} from "react-icons/fa";
 export default class ShopProductList extends React.Component {
    constructor() {  
        super();   
    
        this.state = {kursy:[], loaded:false,
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }

    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        this.ask('administration', 'getGroups', null)
    }
    hideModal(){this.setState({calendarModal:false})}

    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
   
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGroups':
                
                    self.setState({ kursy:response.data.data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        
     return(<div className="container">
                <div className="row">
                
                    <div className="col-sm-12">

                        <h3><TiShoppingCart /> Sklep z kursami

                            {/* <span className="float-right">
                            <Link to={"/harmonogram/view/" + this.state.dzienniki[0].kursid } className="btn btn-secondary btn-sm">Harmonogram</Link>&nbsp;
                                <Link to={"/programs/view/" + this.state.dzienniki[0].program } className="btn btn-secondary btn-sm">Program</Link>
                            </span> */}
                        </h3>
                        <div className="prinfo">
                            <div className="row">
                                {this.state.kursy.map((key)=>(<div className="shop product  col-sm-6">
                                    {(key.status===2) ? (<div className="card "><div className="card-header">
                                    <h5><TiGroupOutline /> Projekt kursu {key.name}</h5>
                                    </div>
                                    <div className="card-body">
                                        <div><FaChalkboardTeacher /></div>
                                        <div><small>Kurs jest w fazie projektowania. Przejdź do szczegółów, aby zobaczyć w które dni i o której godzinie będzie się odbywać. Miej aktywny wpływ na to, jak będzie on ostatecznie wyglądać.</small></div>
                                        <div className="bold"><TiCalendar /> {key.start} - {key.end}</div>
                                        <div><small>Staramy się, by miejsce realizacji było jak najbliżej miejsca, gdzie dziecko uczęszcza do szkoły. Bezpieczeństwo podopiecznych traktujemy bardzo poważnie.</small></div>
                                        <div><TiMap /> Miejsce realizacji: {key.nazwa}</div>
                                        <div><small>.</small></div>
                                        <div>Prowadzący: {key.lektor}</div>
                                    </div></div>): (<div className="card "><div className="card-header">
                                        <h5><TiGroupOutline />Trwający kurs {key.name}</h5>

                                        </div>
                                    <div className="card-body">
                                    <div className="benefity"> 
                                        <FaChalkboardTeacher data-toggle="tooltip" title="Profesjonalny wykładowca" />&nbsp;
                                        <FaUsers data-toggle="tooltip" title="Mała grupa, indywidualne podejście" />&nbsp;
                                        <IoIosChatboxes data-toggle="tooltip" title="Chat z grupą" />&nbsp;
                                        <IoIosChatbubbles data-toggle="tooltip" title="Chat z lektorem" />&nbsp;
                                        <IoIosCloudDownload data-toggle="tooltip" title="Profesjonalne materiały do pobrania" />&nbsp;
                                        <IoIosCalendar data-toggle="tooltip" title="Precyzyjny harmonogram" />
                                    </div>
                                        <div><small>Kurs jest w fazie projektowania. Przejdź do szczegółów, aby zobaczyć w które dni i o której godzinie będzie się odbywać. Miej aktywny wpływ na to, jak będzie on ostatecznie wyglądać.</small></div>
                                    <div><TiCalendar /> {key.start} - {key.end}</div>
                                    <div><small>Staramy się, by miejsce realizacji było jak najbliżej miejsca, gdzie dziecko uczęszcza do szkoły. Bezpieczeństwo podopiecznych traktujemy bardzo poważnie.</small></div>
                                    <div><TiMap /> Miejsce realizacji: {key.nazwa}</div>
                                    <div><small>Chcemy się podzielić informacją, kto będzie prowadził dany kurs. BYć może usłyszałeś od kogoś o jakości jego nauczania, lub masz dobre doświadczenia z poprzedniego roku.</small></div>
                                    <div>Prowadzący: {key.lektor}</div>
                                    <div><Link to={"/shop/course/"+key.id} className="btn btn-primary btn-sm">Szczegóły</Link></div>
                                    </div></div>)}
                                </div>))}
                            </div>
                       
                        </div>
                
                    </div> 
                </div>
          
       
     </div>)
    }
    
 }