function Easter(Y) {
    var C = Math.floor(Y/100);
    var N = Y - 19*Math.floor(Y/19);
    var K = Math.floor((C - 17)/25);
    var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
    I = I - 30*Math.floor((I/30));
    I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
    var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
    J = J - 7*Math.floor(J/7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40)/44);
    var D = L + 28 - 31*Math.floor(M/4);

    return padout(D) + '.' + padout(M)  + '.' +Y;
}

function CorpusCristi(Y)
{
    var easter = Easter(Y);
    var easterTime=new Date(easter).getTime();
    return new Date(easterTime + (59 * 86400000)).toLocaleDateString();
}
function padout(number) { return (number < 10) ? '0' + number : number; }

function generateCalendar(start, end, day)
{
    var startTime = new Date(start);
    var endTime = new Date(end);
    var days = JSON.parse(day);
    console.log(startTime.getTime(), endTime.getTime());
    var day = startTime;
    var odp=[];
    var counter = 1;
    var exclude = [Easter(startTime.getFullYear()), Easter(endTime.getFullYear()),
        CorpusCristi(startTime.getFullYear()),CorpusCristi(endTime.getFullYear()),
        '1.01.'+startTime.getFullYear(), '1.01.' + endTime.getFullYear(),
        '6.01.'+startTime.getFullYear(), '6.01.' + endTime.getFullYear(),
        '1.05.'+startTime.getFullYear(), '1.05.' + endTime.getFullYear(),
        '3.05.'+startTime.getFullYear(), '3.05.' + endTime.getFullYear(),
        '15.08.'+startTime.getFullYear(), '15.08.' + endTime.getFullYear(),
        '11.11.'+startTime.getFullYear(), '11.11.' + endTime.getFullYear(),
        '23.12.'+startTime.getFullYear(), '23.12.' + endTime.getFullYear(),
        '24.12.'+startTime.getFullYear(), '24.12.' + endTime.getFullYear(),
        '25.12.'+startTime.getFullYear(), '25.12.' + endTime.getFullYear(),
        '26.12.'+startTime.getFullYear(), '26.12.' + endTime.getFullYear(),
        '31.12.'+startTime.getFullYear(), '31.12.' + endTime.getFullYear()
    ]
    console.log("EXCLUDED",exclude)
    while (day.getTime() <endTime.getTime())
    {
        if (days[day.getDay()].length>0)
        {
            if (!exclude.includes(day.toLocaleDateString()))
            { 
                odp.push({count:counter,data:day.toLocaleDateString(), weekDay:day.getDay(),hour:days[day.getDay()], desc:""})
                counter++;
            }
            else
            {
                odp.push({count:"-",data:day.toLocaleDateString(), weekDay:day.getDay(),hour:"", desc:"Dzień wyłączony z harmonogramu"})
            }
        }

        day = new Date(day.getTime()+86400000);
    }
    console.log(odp)
    return odp;
}

function markAsReaded(data,chat, userId)
{
    // debugger;
    console.log("INPUT",data, userId)
    var temo = JSON.parse(data)
    var chatName = temo.chat + userId;
    console.log("CHAT", chat)
    var localStorage = window.localStorage.getItem(chatName);
    var items=[];
    if (localStorage===null){localStorage="";}
    if (localStorage.length >0)
    {
        items = JSON.parse(localStorage);
    }
    console.log("TYPE", typeof items[data])
    var result = Object.keys(items).map(function(key) {
        return [Number(key), items[key]];
      });
    if (typeof items[data] === 'undefined')
    {
        Object.assign(result, {data:chat[chat.length-1].id})
        console.log("ITEMS", items)
    }
    else
    {
        result[data]=chat[chat.length-1].id;
    }
    
    var toSave = JSON.stringify(result);
    
    window.localStorage.setItem(chatName, toSave);
    console.log("TOSAVE", toSave, chat[chat.length-1].id);
}
function chatSumBadges(data, userId)
{
    //debugger;
    //console.log("chat",data, data.rooms.length);
    var localStorage = window.localStorage.getItem('chats' + userId);
    if (localStorage===null){localStorage="";}
    var items = [];
    var totalMessages = 0;
    var len = data.rooms.length;
    //console.log("LS",typeof localStorage);
    if (localStorage.length >0)
    {
        items = JSON.parse(localStorage);
    }
    for (var a =0; a<data.rooms.length; a++)
    {
        var key = parseInt(data.rooms[a].room);
        totalMessages += parseInt(data.rooms[a].messages);
        if (!isNaN(parseInt(items[key])))
        { 
            totalMessages -= totalMessages -parseInt(items[key]);
        }
        else
        {
        }
        //console.log ("ITEMS",parseInt(items[key]), totalMessages);
    }
    return totalMessages;
}

function calculateDiff(start, end)
{
    if (typeof end==="undefined"){return "Brak danych"}
    var a = new Date(start)
    var b = new Date(end)
    var diff = a-b;
    diff = Math.round(diff/1000/60);
    if (diff<1){return "przed chwilą";}
    if (diff<2){return "minutę temu";}
    if (diff>=2 && diff<5){return diff+" minuty temu";}
    if (diff>=5 && diff<15){return diff+" minut temu";}
    diff = Math.round(diff/15);
    if (diff<2){return "Kwadrans temu"}
    if (diff===2){return "Pół godziny temu"}
    if (diff===3){return "Trzy kwadranse temu"}
    if (diff===4){return "Godzinę temu"}
    diff = Math.round(diff/4);
    if (diff>=1 && diff <5){return diff+" godziny temu"}
    if (diff>=5 && diff <24){return diff+" godzin temu"}
    diff = Math.round(diff/24);
    if (diff===1){return "Wczoraj"}
    if (diff>1 && diff<15){return diff + " dni temu"}
    diff = Math.round(diff/7);
    if (diff>1 && diff<5){return diff + " tygodnie temu"}
    diff = Math.round(diff/4);
    if (diff>1 && diff<5){return diff + " miesiące temu"}
    if (diff>=5 && diff<12){return diff + " miesięcy temu"}
    return "Ponad rok temu";
}

function calculatePercent(a,b)
{
    var start = new Date(a);
    var end = new Date(b);
    var now = new Date();
    var isStarted = (start<now);
    var isEnded = (end<now)
    var percent = 0;
    if (isStarted && !isEnded)
    {
        var period = end-start;
        var consumed = now-start;
        percent = Math.round((consumed/period)*100);
    }
    else if (isEnded)
    {
        percent = 100;
    }
    return {isStarted: isStarted, isEnded:isEnded, percet:percent}
}

function week()
{
    return ("Niedziela","Poniedziałek", "Wtorek", "Środa", "czwartek", "Piątek","Sobota")
}
module.exports ={
   calculateDiff ,
   calculatePercent,
   week,
   chatSumBadges,
   markAsReaded,
   generateCalendar,
}