import React from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import CodesitTable from './../common/codesittable.js';
import {FaUsers} from "react-icons/fa";
import { Link } from 'react-router-dom';


 export default class ParentChildren extends React.Component {
    constructor() {
        super();   

        this.state = {
           childrenList:[],
        };
        this.ask = this.ask.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.addNewChildren = this.addNewChildren.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        

    } 
    addNewChildren()
    {
        this.ask("user", "AddChildren", null);
    }
    componentDidMount()
    {
        this.ask("user", "getParentChldren", null);
        
    }
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getParentChldren': self.setState({ childrenList:response.data.data, mode:0});break;
                case 'AddChildren':  self.ask("user", "getParentChldren", null);break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }

    updateForm(data)
    {
        var self = this;
        switch (data.target.dataset.content)
        {
            case "mail": self.setState({mail: data.target.value}); break;
            case "pass": self.setState({pass: data.target.value}); break;
            case 'newpass': self.setState({newpass: data.target.value}); break;
            default:break;
        }
        
    }

    render() {
        var header=[{title:"Imię", data:"imie"},
        {title:"Nazwisko", data:"nazwisko"},
        {title:"Klasa", data:"klasa"},
        {title:"Data urodzenia", data:"urodzony"}
    ];
     return (<div className="container">
        <div className="row">
            <div className="col-sm-12">
            <h3><FaUsers /> Podopieczni
                <span className="float-right">
                    <button  className="btn btn-success btn-sm" onClick={this.addNewChildren}>Dodaj</button>
                </span>
            </h3>
                <div className="prinfo">
                    <CodesitTable data={this.state.childrenList} headers={header} link="/parent/children/edit/"/>
                </div>
                
            </div>
        </div>
     </div>)
    }
 }