import React from 'react';

export class WeekDays extends React.Component{
    constructor() {  
        super();   
        this.state = {}
    }
    
    render(){

    
        if (typeof this.props.days!== 'undefined')
        {
            
            var data = JSON.parse(this.props.days);
            var isEmpty = true;

            for (var a = 0; a<data.length; a++){
                if (data[a].length>0){isEmpty = false;}
            }
            if (data.length===0){isEmpty=false}
            return (<div className="weekDays">
            {(isEmpty) ? (<div className="myInfo">Nie wybrano jeszcze dni tygodnia w których mają odbywać się zajęcia</div>):""}
            {(data[1].length>3) ? (<div className="day">Poniedziałek <span className="float-right">{data[1]}</span></div>) : ""}
            {(data[2].length>3) ? (<div className="day">Wtorek <span className="float-right">{data[2]}</span></div>) : ""}
            {(data[3].length>3) ? (<div className="day">Środa <span className="float-right">{data[3]}</span></div>) : ""}
            {(data[4].length>3) ? (<div className="day">Czwartek <span className="float-right">{data[4]}</span></div>) : ""}
            {(data[5].length>3) ? (<div className="day">Piątek <span className="float-right">{data[5]}</span></div>) : ""}
            {(data[6].length>3) ? (<div className="day">Sobota <span className="float-right">{data[6]}</span></div>) : ""}
            {(data[7].length>3) ? (<div className="day">Niedziela <span className="float-right">{data[7]}</span></div>) : ""}
          </div>)
        }
        else
        {
            return (<></>)
        }
        
    }
}


