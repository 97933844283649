import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from "react-router-dom";
export default class Courses extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",
        };
        this.hideModal = this.hideModal.bind(this);
        

    } 

    searchInTable(ev)
    {
      // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  input = ev.target.value;
  filter = input.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
   
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    
    
  }
    }
    getInfo(ev)
    {

        console.log("PYTANIE",ev.target.dataset)
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    
    render() {
        
     return(
         <div className="container">
         <div>
         <h3>Przeglądaj kursy
         <span className="float-right"><Link className="btn btn-secondary btn-sm" to="/places/new">Dodaj</Link></span>
         </h3>
         </div>
<br></br>
<input className="myInput search col-sm-4" placeholder="Szukaj" onChange={this.searchInTable}/>
        <div className="table-responsive">  
         <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Kurs</td>
                        <td>Miejsce</td>
                        <td>Prowadzący</td>
                        <td>Rozpoczęcie</td>
                        <td>Zakończenie</td>
                        <td>Status</td>
                        <td>Opcje</td>
                    </tr>
                </thead>
                <tbody id="myTable">
                   {this.props.courses.map((key)=>(
                     <tr key={key.id}>
                        <td>{key.id}</td>
                        <td>{key.kurs}</td>
                        <td>{key.nazwa}</td>
                        <td>{key.name}</td>
                        <td>{key.start}</td>
                        <td>{key.end}</td>
                        
                        <td>{(key.status==null) ? "---" : key.status}</td>
                        <td><Link className="btn btn-secondary btn-sm" to={"/courses/edit/" + key.id}>Więcej</Link></td>
                     </tr>
                   ))} 
                  
                   </tbody>  
            </table>
               
         </div>
        {JSON.stringify(this.props)}


         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kasowanie oddziału "{this.state.prepareName}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }