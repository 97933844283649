import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';

export default class EditCourses extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",kurs:{}, loaded:false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 
    componentDidMount()
    {
        var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        this.setState({id:id});
        this.ask('info', 'getCourseDetails', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getCourseDetails':self.setState({ kurs:response.data.data, loaded:true});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        var kurs={};
        var style = {width:"0%"}
        if (this.state.loaded)
        {
            kurs=this.state.kurs.KURS;
            var temp = time.calculatePercent(this.state.kurs.KURS.start, this.state.kurs.KURS.end);
            style = {width:temp.percet + "%"}       
        }
        
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3>Szczegóły kursu</h3>
                    <div className="prinfo">
                        <div>Stopień zaawansowania kursu</div>
                        <div className="progress">
                            <div className="progress-bar" style={style}></div>
                        </div> 

                        <div className="col-sm-4">
                            <label>Nazwa kursu</label>
                            <input className="myInput" value={kurs.nazwa}  data-id="nazwa" onChange={this.editCurse}/>
                        </div>
                        <div className="col-sm-4">
                            <label>Utworzony</label>
                            <input className="myInput" value={kurs.created}  data-id="created" onChange={this.editCurse}/>
                        </div>
                        <div className="col-sm-4">
                            <label>Zmodyfikowany</label>
                            <input className="myInput" value={kurs.updated}  data-id="udpated" onChange={this.editCurse}/>
                        </div>
                        <div className="col-sm-4">
                            <label>Rozpoczęcie </label>
                            <input type="date" className="myInput" value={kurs.start}  data-id="start" onChange={this.editCurse}/>
                        </div>
                        <div className="col-sm-4">
                            <label>Zakończenie</label>
                            <input type="date" className="myInput" value={kurs.end}  data-id="end" onChange={this.editCurse}/>
                        </div>
                    </div>
                
                </div>
                
            </div>
            {JSON.stringify(this.state.kurs)}
<br></br>


         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kasowanie oddziału "{this.state.prepareName}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }