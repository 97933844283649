import React from 'react';
import planeta from './../functions/mainpage.js';
import logo from './../../img/logo.png';
import './../../index.css'
import {Link} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';


 export default class ResetPass extends React.Component {
    constructor() {
        super();   

        this.state = {
           mail:"", pass:"", action:"login", mode:0, securityToken:"", newpass:"",style:{display:"none"},
           status:"Trwa weryfikacja zgłoszenia..."
        };
        this.ask = this.ask.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.recovery = this.recovery.bind(this);
        this.ancorano = this.ancorano.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

    } 

    componentDidMount()
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var mail = urlParams.get('mail')
        var token = urlParams.get('token')
        if (planeta.isMail(mail))
        {
            console.log(mail, token);this.setState({mail:mail, securityToken:token})
            this.ask("user","verifyTokens", JSON.stringify({mail:mail,token,token}));
        }
        else
        {
            this.setState({status:"Link zawiera błędy formalne"})
        }
        
    }
    resetPassword()
    {
        console.log("RP");
        if (this.state.pass===this.state.newpass)
        {
            console.log("ADK")
            this.ask("user", "changePSWD", JSON.stringify({mail:this.state.mail, pass:this.state.newpass, token:this.state.securityToken}))
        }
        else
        {
            console.log("HAJKS")
            this.setState({response:"Hasła nie są identyczne"});
        }

    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'verifyTokens':
                    if (response.data.data==="TOKENOK")
                    {
                        self.setState({ mode:1,status:""});
                    }
                    else
                    {
                        self.setState({ status:response.data.data, mode:0});

                    }
                    //self.ask('groups', 'getParentData', response.data.data[0].zaproszeni);
                    break;
                case 'changePSWD':self.setState({ status:response.data.data, mode:0, style:{display:"block"}});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    ancorano()
    {
        this.setState({mode:0})
    }
    checkIt(ev)
    {
        // var x = planeta.verifyLoginData(ev.target.dataset.content, ev.target.value);
        // if (x){ev.target.parentElement.children[2].innerHTML = x.response;}
        // document.getElementById("login").disabled = planeta.isCorrect("loginForm",document.getElementsByClassName("validation"))
        
    }
    updateForm(data)
    {
        var self = this;
        switch (data.target.dataset.content)
        {
            case "mail": self.setState({mail: data.target.value}); break;
            case "pass": self.setState({pass: data.target.value}); break;
            case 'newpass': self.setState({newpass: data.target.value}); break;
            default:break;
        }
        
    }
    recovery()
    {
        var checkMail = false;
        //if (!planeta.isMail(this.state.login)){return;}
        this.setState({mode:1})
    }
    render() {
        if (this.state.mode===0)
        {
            return(<>
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
    <div className="loginform">  
    <div className="row">
    <div className="col-sm-6"><img src={logo} alt="logo"/></div>
    <div className="col-sm-6"><h3><br></br>Planeta Rozwoju</h3> </div>
    <div className="col-sm-12">{this.state.status}</div>
    <div className="col-sm-12"><Link className="btn btn-primary" style={this.state.style} to={"/"} >Panel logowania</Link></div>
    </div></div>
            </>)
        }
        if (this.state.mode===1)
        {
     return(
         <>
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
    <div className="loginform">  
    <div className="row">
    <div className="col-sm-6"><img src={logo} alt="logo"/></div>
    <div className="col-sm-6"><h3><br></br>Planeta Rozwoju</h3> </div>
      
    <div> </div>
   
    </div>  
       
        {/* <div>
            <label>mail</label>
            <input type="mail" data-content="mail" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm} onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div>  */}
         <div>
            <label>Hasło</label>
            <input type="password" data-content="pass" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm}  onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div>
         <div>
            <label>Nowe hasło</label>
            <input type="password" data-content="newpass" className="form-control chceckit" autoComplete="on" value={this.props.login} onChange={this.updateForm}  onBlur={this.checkIt}/>
            <small className="validation red"></small>
         </div>
         <div><small className="red">{this.props.response}</small></div>
         <div>
             <button id="login" className="btn btn-success"  onClick={this.resetPassword}>Zmień hasło</button>
         </div>
       
        
    </div>
        </>
     );}
     

     
    }
 }