import React from 'react';

import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import {FaUsers} from "react-icons/fa";
import {Link} from 'react-router-dom'
import Passwordcmp from './../common/passwordcmp.js';

 export default class LectorSetup extends React.Component {
    constructor() {  
        super();   
    
        this.state = {loaded:false, program:[],grupy:[],
            oldpassword:null,newpassword:null, newnewpassword:null
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getRozliczenie = this.getRozliczenie.bind(this)
        this.onChangePass = this.onChangePass.bind(this);
    }

    onChangePass(ev)
    {
        var name = ev.target.name;
        //console.log("NAME:", ev.target, name)
        this.setState({[name]:ev.target.value})
    }
    getRozliczenie()
    {
        var miesiac = document.getElementById('miesiac').value;
        var rok = document.getElementById('rok').value
        var data = JSON.stringify({miesiac:miesiac, rok:rok});
        this.ask("dziennik", "getRozliczenie", data);
    }

    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        //this.ask('dziennik', 'lectorGroupsDetails', id)
    }
    hideModal(){this.setState({calendarModal:false})}

    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
   
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getRozliczenie':
                
                    self.setState({ grupy:response.data.data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        var status = ["Nieznany", "Projektowany", "Ankietowany" , "W toku ", "Zamknięty"]
     return(<div className="container">
                <div className="row">
                   
                    <div className="col-sm-12">

                        <h3><FaUsers /> Ustawienia konta i moduł rozliczeń

                           
                        </h3>
                        <div className="prinfo">
                        <div className="row">
                            <div className="col-sm-12"><h5><i className="fa fa-cogs"></i> Ustawienia konta</h5></div>
                            <div className="col-sm-3">
                                <Passwordcmp 
                                    label="Stare hasło"
                                    className="form-control"
                                    name="oldpassword"
                                    value={this.state.oldpassword}
                                    onChange={this.onChangePass}
                                    minlength={8}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Passwordcmp 
                                    label="Stare hasło"
                                    className="form-control"
                                    name="newpassword"
                                    value={this.state.newpassword}
                                    onChange={this.onChangePass}
                                    minlength={8}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Passwordcmp 
                                    label="Stare hasło"
                                    className="form-control"
                                    name="newnewpassword"
                                    value={this.state.newnewpassword}
                                    onChange={this.onChangePass}
                                    minlength={8}
                                />
                            </div>
                            <div className="col-sm-12"><h5><i className="fa fa-cogs"></i> Rozliczenia</h5></div>
                        <div className="col-sm-3">
                    <label>Miesiąc</label>
                    <select className="form-control"  id="miesiac">
                        <option value="0">-- Wybierz --</option>
                        <option value="01">Styczeń</option>
                        <option value="02">Luty</option>
                        <option value="03">Marzec</option>
                        <option value="04">Kwiecień</option>
                        <option value="05">Maj</option>
                        <option value="06">Czerwiec</option>
                        <option value="07">Lipiec</option>
                        <option value="08">Sierpień</option>
                        <option value="09">Wrzesień</option>
                        <option value="10">Październik</option>
                        <option value="11">Listopad</option>
                        <option value="12">Grudzień</option>
                       

                    </select>
                    </div>

                    <div className="col-sm-3">
                    <label>Rok</label>
                    <select className="form-control"  id="rok">
                        <option value="0">-- Wybierz --</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>

                    </select>
                    </div>
                    <div className="col-sm-3"><br></br>
                    <button className="btn btn-secondary" onClick={this.getRozliczenie}>Pobierz</button>
                    </div>
                        </div>
                        <br></br>
                        <br></br>
                        <table className="table">
                    
                <thead>
                    <tr>
                    <td>Kurs</td>
                    <td>Data zajęć</td>
                    <td>Kwota</td>
                    </tr>
                </thead>
                <tbody>
                {this.state.grupy.map((key)=>(<tr key={key.id}>
                    <td>{key.nazwa}</td>
                    <td>{key.dzien}</td>
                    <td>{key.naleznosc}</td>
                 
                </tr>))}
                </tbody>
                </table>
                       
                        </div>
                
                    </div> 
                </div>
          
       
     </div>)
    }
    
 }