import React from 'react';
import {Link} from 'react-router-dom';

export default class CodesitTable extends React.Component{
    constructor() {  
        super();   
    
        this.state = {searchBy:1}
        this.componentDidMount = this.componentDidMount.bind(this);
        this.searchInTable = this.searchInTable.bind(this);
        this.selectToSearch = this.selectToSearch.bind(this);
        };
        
     
    selectToSearch(ev)
    {
        console.log(ev.target.cellIndex)
        var he = document.getElementsByClassName('selectable')
        for (var a = 0; a<he.length; a++){he[a].classList.remove('active');}
        he[ev.target.cellIndex].classList.add('active');
        this.setState({searchBy:ev.target.cellIndex})
    }
    componentDidMount()
    {
        
        // var col = document.getElementsByClassName('selectable');
        // col.forEach(e => {
        //     e.classList.remove('active');
        // });
        // document.getElementsByClassName('selectable')[this.state.searchBy].classList.add('selected');


    }


    searchInTable(ev)
    {
      // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  input = ev.target.value;
  filter = input.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[this.state.searchBy];
   
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
}
    render(){  
        if (typeof this.props.headers == 'undefined')
        {
            return (<div>Ładowanie....</div>)
        }
        else
        {
            if (typeof this.props.data[0] !=='undefined')
           { if (this.props.data[0].id>0)
        {return (<div> 
            <input type="text" className="myInput col-sm-4" placeholder="Szukaj..." onChange={this.searchInTable}/>
            <br></br> <br></br> 
            <table className="table">
                <thead><tr>
                    {this.props.headers.map((key, i)=>(<th key={i} onClick={this.selectToSearch} className="selectable">{key.title}</th>))}
                    <th>Edycja</th>
                    </tr></thead>
                <tbody id="myTable">
                    {this.props.data.map((key, i)=>(<tr key={i}>
                        {this.props.headers.map((head, u)=>(<td key={u}>{key[head.data]}</td>))}
                        <td key={i}><Link className="btn btn-secondary btn-sm" to={this.props.link + key.id}>Edycja</Link></td>
                    </tr>))}
                </tbody>
                
            </table>
        </div>)}
        else
        {
           return (<div className="alert alert-info">W tej tabeli jeszcze nie ma danych</div>)
        }}
        else
        {
           return (<div></div>)
        }
        }
    }
}


