import React from 'react';
import AdminNav from './roles/adminnav.js';
import ParentNav from './roles/parentnav.js';
import LectorNav from './roles/lectornav.js';

export default class NavController extends React.Component {
  //other logic
    render() {
       switch (this.props.rights)
       {
         case "5":return(<LectorNav />); break;
          case "6":return(<ParentNav />); break;
          default: return (<AdminNav />);break;
       }
    }
 }