import React from 'react';
import{Link} from 'react-router-dom';
 export default class ParentNav extends React.Component {
  //other logic
    render() {
     return(
      <nav className="navbar navbar-light navbar-expand-sm">
      <Link className="navLink" style={{color:"#333"}} to="/">Planeta Rozwoju</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">

              <ul className="navbar-nav">
                  <li className="nav-item">
                      <Link className="nav-link"  to="/parent/children/index">Dzieci</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/parent/index">Grupy</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/parent/payment/">Płatności</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/parent/setup/">Ustawienia</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/shop/index">Kursy</Link>
                  </li>
                  
                  
                  
              </ul>
              </div>
          </nav>

     );
    }
 }