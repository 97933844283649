import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';

export default class SendMail extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            temat:"",tresc:"", id:0,message:"",
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.type = this.type.bind(this);
        this.send = this.send.bind(this);

    } 
    send()
    {
        this.setState({message:"Wysyłam..."});
        var temat = this.state.temat;
        if (temat.length<5){this.setState({message:"Temat jest zbyt krótki"});return;}
        var tresc = this.state.tresc.replace(/\r\n/g,"\n").split("\n");
        if (tresc.length<10){this.setState({message:"Treść jest zbyt krótki" + tresc.length});return;}
        for (var a =0; a<tresc.length;a++)
        {
            tresc[a] = "<div>"+tresc[a]+"</div>";
        }
        tresc = tresc.join('')
        this.ask("dziennik", "sendMailToGroup", JSON.stringify({id:this.state.id,temat:this.state.temat, tresc:tresc}));
        console.log(temat, tresc)
    }
    type(ev)
    {
        switch (ev.target.dataset.id)
        {
            case 'temat':this.setState({temat:ev.target.value});break;
            case 'tresc':this.setState({tresc:ev.target.value});break;

        }
    }
    componentDidMount()
    {
        var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        this.setState({id:id});
        //this.ask('info', 'getCourseDetails', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'sendMailToGroup':
                self.setState({ message:response.data.data, tresc:"",temat:""});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
       
     return(<div className="container">
            <h5>Wyślij wiadomość do grupy</h5>
            <label>Temat wiadomości</label>
            <input type="text" className="myInput" data-id="temat" placeholder="temat" value={this.state.temat} onChange={this.type} />
            <label>Treść</label>
            <textarea className="form-control" data-id="tresc" rows="15" value={this.state.tresc} onChange={this.type}></textarea>
            <hr></hr>
            <div>
                <button className="btn btn-secondary" onClick={this.send}>Wyślij</button>
                <span className="float-right">{this.state.message}</span>
                
            </div>
            
         </div>

     );
    }
 }