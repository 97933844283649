import React from 'react';
// import {FaTrashAlt, FaEdit, FaLink, FaRegAddressCard} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
// import finder from './../../functions/finddataset.js';
// import {Link} from "react-router-dom";
// import Reactable from 'reactable';
// import ParentNavigation from './parentnavigation.js';
import planeta from './../../functions/mainpage.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";
import {ToHaveInfo, TimeDiff} from './../common/utylity.js';
import time from './../../functions/timedifference.js';

export default class ParentInfo extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
   

    } 
   
  
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    showModal()
    {
        this.setState({deleteModal:true})
    }
    componentDidMount()
    {
        var id = planeta.getIdFromLink();
        this.props.ask('students', 'getParentInfo', id);
    }
    render() {
       
        var parent = this.props.parent;
        if (typeof parent[0]!=='undefined'){parent = parent[0]}

     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3><FaFolderOpen /> Informacje szczegółowe</h3>
                    <div className="prinfo parentInfo">
                    <br></br>
                        <div className="row">

                        <div className="col-sm-4">
                            <label>Imię</label>
                            <input className="myInput" data-id="imie" value={parent.imie} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>Nazwisko</label>
                            <input className="myInput" data-id="nazwisko" value={parent.nazwisko} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>PESEL</label>
                            <input className="myInput" data-id="pesel" value={parent.pesel} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>Kod</label>
                            <input className="myInput" data-id="kod" value={parent.kod} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>Miejscowość</label>
                            <input className="myInput" data-id="miejscowosc" value={parent.miejscowosc} onChange={this.props.changeParent} />
                        </div>

                        <div className="col-sm-4">
                            <label>Ulica</label>
                            <input className="myInput" data-id="ulica" value={parent.ulica} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>Numer</label>
                            <input className="myInput" data-id="numer" value={parent.numer} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>lokal</label>
                            <input className="myInput" data-id="lokal" value={parent.lokal} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>Telefon</label>
                            <input className="myInput" data-id="telefon" value={parent.telefon} onChange={this.props.changeParent} />
                        </div>
                        <div className="col-sm-4">
                            <label>mail</label>
                            <input className="myInput" data-id="mail" value={parent.mail} onChange={this.props.changeParent} />
                        </div>
                        
                        <div className="col-sm-12">
                            <hr></hr>
                            <button className="btn btn-success btn-sm disabled">Zapisz</button>
                        </div>
                        </div>
                    </div>

                </div>
            </div>    
        

         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Wskazanie osoby uprawnionej
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>
                    Osoba uprawniona to osoba, która może otrzymywać informację o postępie w nauce, zapisach
                    na liście obecności a w przypadku małoletnich odbieranych przez opiekuna tylko osoba z listy
                    ma prawo zabrać dziecko z placówki.
                    </p>
                    <label>Imię i nazwisko osoby uprawnionej</label>
                    <input type="text" className="myInput" id="osoba" />
                    
                
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
                    <button className="btn btn-success" onClick={this.addPerson}>Dodaj</button>
                </Modal.Footer>
            </Modal>


         </div>

     );
    }
 }
