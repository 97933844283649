import React from 'react';

import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import {FaUsers} from "react-icons/fa";
import {Link} from 'react-router-dom'

 export default class LectorGroupsDetails extends React.Component {
    constructor() {  
        super();   
    
        this.state = {loaded:false, program:[],grupy:[]
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }

    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        this.ask('dziennik', 'lectorGroupsDetails', id)
    }
    hideModal(){this.setState({calendarModal:false})}

    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
   
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'lectorGroupsDetails':
                
                    self.setState({ grupy:response.data.data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        var status = ["Nieznany", "Projektowany", "Ankietowany" , "W toku ", "Zamknięty"]
     return(<div className="container">
                <div className="row">
                
                    <div className="col-sm-12">

                        <h3><FaUsers /> Szczegóły Twojej grupy

                           
                        </h3>
                        <div className="prinfo">
                        <table className="table">
                <thead>
                    <th>Imię dziecka</th>
                    <th>Szkoła</th>
                    <th>Klasa</th>
                    <th>Rodzic</th>
                    <th>Telfon</th>
                    <th>mail</th>
                </thead>
                <tbody>
                {this.state.grupy.map((key)=>(<tr key={key.id}>
                    <td>{key.student}</td>
                    <td>{key.nazwa}</td>
                    <td>{key.klasa}</td>
                    <td>{key.parent}</td>
                    <td>{key.telefon}</td>
                    <td>{key.mail}</td>
                </tr>))}
                </tbody>
                </table>
                       
                        </div>
                
                    </div> 
                </div>
          
       
     </div>)
    }
    
 }