import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";
import CodesitTable from './../common/codesittable.js';

export default class ActiveGroup extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",
            grupy:[], loaded:false,
            groupStatus:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 
    componentDidMount()
    {
        this.ask('groups', 'getActiveGrupList', this.props.user);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getActiveGrupList':self.setState({ grupy:response.data.data, loaded:true});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
    var headers=[{title:"Grupa", data:"name"},
    {title:"Rozpoczęcie", data:"start"},
    {title:"Zakończenie", data:"end"},
    {title:"Lektor", data:"nazwisko"},
    {title:"Placówka", data:"nazwa"},
]
        
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaFolderOpen /> Lista aktywnych grup
                <span className="float-right">
                    <Link className="btn btn-primary btn-sm" to='/groups/index'>Grupy</Link>&nbsp;
               </span>
                </h3>
                    <div className="prinfo">
                        <CodesitTable 
                             headers={headers}
                             data={this.state.grupy} 
                             link={"/groups/active/edit/"}
                        />                
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }