import React , { useState, useEffect  } from 'react';

function CodesitSwitch(props) {
    
    const [isValid, SetValid] = useState(true);
    useEffect(() =>{
      const {form, name} = props;
      if (form[name])
      {
        document.getElementById(name).classList.add("active")
      }
      else
      {
        document.getElementById(name).classList.remove('active')
      }
    })
    const changeValue = () =>
    {
      const {form, name, onChange} = props;
      form[name] =!form[name];
      onChange({form})
    }
    const {labelClassName, label, inputClassName, messaageClassName, inputType, message, name, style, form, placeholder, icon, 
        textIcon, strength, required, validator, validationPositiveMessage, validationNegativeMessage, hint, tooltip, tooltipText, showValidation} = props;
    return (
        
      <div className={inputClassName ? inputClassName : "codesitForm"}>
            <label className={labelClassName}>{label} {required ? (<span style={{color:'red'}}>*</span>):''} 
            {tooltip? (<div className="tooltip">&nbsp;?&nbsp;
  <span className="tooltiptext">{tooltipText}</span>
</div>):""}</label>
            <div className="codesitCheck"  >
                <div className="codesitSwitch" id={name} onClick={changeValue}></div>
            </div>
    {showValidation ? (<div className="validatorPlace">
        {isValid ? (<span style={{color:"green"}}>{validationPositiveMessage}</span>):
        (<span style={{color:"red"}}>{validationNegativeMessage}</span>)}</div>):""}

            <div className={messaageClassName ? messaageClassName:"smallMessage"}>{message}</div>
      </div>
    );
  }

  export default CodesitSwitch
