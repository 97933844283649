import React from 'react';
import Modal from 'react-bootstrap/Modal'
import planeta from './../../functions/mainpage.js';
import {Link} from 'react-router-dom';
import CodesitTable from '../common/codesittable.js';
import CodeSitSwitch from '../common/codesitswitch.jsx';

export default class LectorIndex extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",genereList:false,
            form:{superadmin:false,admin:true, manager:true, sekretariat:true, lektor:false }
        };
        this.hideModal = this.hideModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        

    } 
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
        var sa = planeta.getStudentIdFromTable(ev);
        if ((sa.tag==="TD" || sa.tag==="TR") && sa.child>2)
        {        
           window.location.replace(window.location.protocol+"//"+window.location.host+ '/lectors/lectorinfo/' +sa.id )
        }    
    }
    getParentInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
        var sa = planeta.getStudentIdFromTable(ev);
        if ((sa.tag==="TD" || sa.tag==="TR") && sa.child>2)
        {        
           window.location.replace(window.location.protocol+"//"+window.location.host+ '/parents/parentinfo/' +sa.id )
        }
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    checkUsers=() =>
    {
        let users = JSON.stringify(this.state.form)
        this.props.ask('administration', 'getUsersByFunctions', users);
    }
    componentDidMount = () =>
    {
       this.checkUsers();
    }
    ChangeSwitch = (ev) =>{
        var form = this.state.form
        form=ev.form;
        this.setState({form:form})
        this.checkUsers();
    }
    render() {
        var headers=[
            {title:"Imię i nazwisko", data:"name"},
            {title:"adres email", data:"mail"},
            {title:"Telefon", data:"telefon"},
            {title:"Stanowisko",data:'special'},
            {title:"Rola",data:'nazwa'}
        ];
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Pracownicy i Kadra zarządzająca
                    <span className="float-right btn-group">
                        <Link className="btn btn-info btn-sm" to='/lectors/lectorinfo/new'>Nowy</Link>

                        </span></h3>
                    <div className="prinfo lectorInfo"> 
                        {/* <div className="codesitForm flex">
                        <i className="fas fa-filter" style={{color:"#777", fontSize:"2.5em"}}></i>
                        </div> */}
                        <CodeSitSwitch
                            form={this.state.form}
                            name="superadmin"
                            label="SAdmin"
                            inputClassName={"codesitForm flex"}
                            onChange={this.ChangeSwitch}
                        />
                         <CodeSitSwitch
                            form={this.state.form}
                            name="admin"
                            label="Admin"
                            inputClassName={"codesitForm flex"}
                            onChange={this.ChangeSwitch}
                        />
                         <CodeSitSwitch
                            form={this.state.form}
                            name="manager"
                            label="Menadżer"
                            inputClassName={"codesitForm flex"}
                            onChange={this.ChangeSwitch}
                        />
                         <CodeSitSwitch
                            form={this.state.form}
                            name="sekretariat"
                            label="Sekretariat"
                            inputClassName={"codesitForm flex"}
                            onChange={this.ChangeSwitch}
                        />
                        <CodeSitSwitch
                            form={this.state.form}
                            name="lektor"
                            label="Lektor"
                            inputClassName={"codesitForm flex"}
                            onChange={this.ChangeSwitch}
                        />
                    
                         <CodesitTable
                            headers={headers}
                            data={this.props.lectorsInfo}
                            link="/lectors/lectorinfo/"
                        /> 
                    
                    </div>
                        
                    
                </div>    
            </div>


         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Title
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }

class PRTable extends React.Component{render(){return (<thead><tr><td>ID</td><td>Nazwa</td><td>Opcje</td></tr></thead>)}}
class PRRow extends React.Component{render(){return (<tr><td>{this.props.id}</td><td>{this.props.nazwa}</td><td>{this.props.opcje}</td></tr>)}}