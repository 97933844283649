import React from 'react';

import planeta from './../../functions/mainpage.js';
import {FaFolderOpen} from "react-icons/fa";

import CodesitForm from './../common/codesitform/codesitform';
import CdesitSwitch from '../common/codesitswitch'; 

import axios from 'axios';
import Cookies from 'js-cookie';
import rightEngine from '../common/rightengine.js';
export default class LectorInfo extends React.Component {
    constructor() {   
        super();   
    
        this.state = {
            deleteModal:false,id:0,administration:[{}],departments:[{}],
            matrix:{},
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.saveLector = this.saveLector.bind(this);
   

    } 
    chageAccessType = (ev) =>{
        console.log(ev.target.dataset)
        let d = ev.target.dataset
        let matrix = this.state.matrix;
        matrix["item" + d.dep][d.dzial][d.operacja] = !matrix["item" + d.dep][d.dzial][d.operacja]
        console.log(matrix["item" + d.dep][d.dzial][d.operacja])
        this.setState({matrix:matrix})
    }


    componentDidUpdate = () =>
    {
        console.log("UPDATED")
    }
    changeAccountStatus = (ev) =>{
        let self = this;
        let newStatus = ev.target.value;
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Zmiana roli użytkownika wymaga weryfikacji uprawnień.",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {
                    var administration = this.state.administration;
                    administration[0].roles = Number(newStatus)
                    self.setState({administration:administration})
                }
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }
    saveLector()
    {
        var user = this.state.administration[0];
        user.right = JSON.stringify(this.state.matrix);
        this.ask('user', 'saveLectorInfo', JSON.stringify(user))
    }
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
    }
    getMatrixData = (id, dzial, operacja) =>
    {
        console.log("MATRIXDATA", id, dzial, operacja)
        var matrix = this.state.matrix;
        for (var x = 0; x<matrix.length; x++)
        {
            try {
                if (matrix[x].id === id )
            {
                return matrix[x][dzial][operacja];
                // console.log(matrix[x][dzial][operacja]);
                // if (matrix[x][dzial][operacja])
                // {
                //     return "Dozwolone";
                // }
                // else
                // {
                //     return "Zabronione"
                // }
            }
            } catch (error) {
                return false
            }
            
        }
    }
    componentDidMount()
    {
        var id = planeta.getIdFromLink();
        this.setState({id:id})
        this.ask('departments', 'getSimpleDepartament', null);
        if (id!=="new"){this.ask('user', 'getLectorInfo', id);}
        else
        {
            console.log("NOWY")
            this.props.changelector({target:{value:"new", dataset:{id:"id"}}});
            this.props.changelector({target:{value:"5", dataset:{id:"roles"}}});
        }
    }
    chengeValue = (form) =>
    {
        var ff = this.state.administration;
        ff[0] = form.form
        this.setState({administration:ff});
    }
    ask = (section, command, data = null) =>
    { 

        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params)
        .then(function (response) {
        
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getProgram':
                
                    self.setState({ program:response.data.data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                case 'getLectorInfo':
                    //rightEngine.check()
                    var matrix = [];
                    try {
                        matrix = JSON.parse(response.data.data[0].right);
                        self.setState({matrix:matrix})
                    } catch (error) {
                        
                    }
                    self.setState({administration:response.data.data});
                    console.log(response.data.data);break;
                case 'getSimpleDepartament':
                self.setState({departments:response.data.data});
                var matrix = self.state.matrix;
                for (var aa = 0; aa<response.data.data.length; aa++)
                {
                    var data = rightEngine.cells;
                    data.id = response.data.data[aa].id;
                    if (typeof matrix['item' + data.id] === 'undefined') {matrix['item' + data.id] = (data);}
                }
                console.log("MATRIX",matrix)
                self.setState({matrix:matrix})
                break;
                case 'saveLectorInfo':
                    window.toast.success("Zmiany zostały zapisane")
                break;
                default:break;
                
            }
        }
        else
        {
            window.toast.error("Wystąpił błąd serwera")
        }
    })
}
    render() {
       
        var lector = this.props.lector;
        if (typeof lector[0]!=='undefined'){lector = lector[0]}
     

     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3><FaFolderOpen /> Informacje szczegółowe<span className="float-right">
                        {/* <button className="btn btn-success btn-sm addToMyChat" data-id={"cośtam"} >Czatuj</button>&nbsp; */}
                        <button className="btn btn-success btn-sm" onClick={this.saveLector}>Zapisz</button>

                        </span></h3>
                    <div className="prinfo lectorInfo">
                        <div className="row">
                        
                            <div className="col-sm-4">
                                {this.state.administration && <CodesitForm
                                    form={this.state.administration[0]}
                                    name="name"
                                    label="Imię i Nazwisko"
                                    onChange={this.chengeValue}
                                    required

                                />}

                                {this.state.administration && <CodesitForm
                                    form={this.state.administration[0]}
                                    name="mail"
                                    label="Adres mail (Login)"
                                    onChange={this.chengeValue}
                                    required
                                    validator="mail"
                                    validationNegativeMessage={'Adres mail nie jest poprawny'}

                                />}

                                {this.state.administration && <CodesitForm
                                    form={this.state.administration[0]}
                                    name="telefon"
                                    label="Telefon"
                                    onChange={this.chengeValue}
                                    required
                                    filter={"number"}
                                    validator={"phone"}


                                />}

                                
                                {this.state.administration && <CodesitForm
                                    form={this.state.administration[0]}
                                    name="konto"
                                    label="Numer konta"
                                    onChange={this.chengeValue}
                                    filter={"iban"}
                                    validator={"iban"}
                                    required
                                    validationPositiveMessage={'Numer rachunku bankowego jest poprawny'}
                                    validationNegativeMessage={'Numer rachunku nie jest poprawny'}
                                    showValidation



                                />}

                                {this.state.administration && <CodesitForm
                                    form={this.state.administration[0]}
                                    name="special"
                                    label="Stanowisko"
                                    onChange={this.chengeValue}
                                    required
                                   
                                    />}
                                {this.state.administration &&  (<><label>Rodzaj konta <span style={{color:"red"}}>*</span></label><select className="codesitInput" value={this.state.administration[0].roles} onChange={this.changeAccountStatus}>
                                <option value="1">Super Administrator</option>
                                <option value="2">Administrator</option>
                                <option value="3">Manager</option>
                                <option value="4">Sekretariat</option>
                                <option value="5">Lektor</option>
                                </select></>)}
                            </div>
                            <div className="col-sm-8">
                                    Matryca dostępu
                                    {Number(this.state.administration[0].roles)===1 && (<div>
                                        <div className="alert alert-info">
                                            <strong>Konto Super Administratora</strong> Konto tego rodzaju nie posiada uprawnień, które można edytować przyznać.
                                        </div>
                                    </div>)}

                                    {Number(this.state.administration[0].roles)>1 && (<div>
                                        {this.state.departments.map((key, i)=>(<div key={i}>
                                        <h6>Ustawienia dla oddziału {key.nazwa}</h6>
                                        <table  className="table">
                                            <thead>
                                                <tr>
                                                    <td>funkcjonalność</td>
                                                    <td>Przeglądanie</td>
                                                    <td>Edycja</td>
                                                    <td>Kasowanie</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Filie</td>
                                                    <td>{this.state.matrix["item" + key.id].filie.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="filie" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="filie" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].filie.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="filie" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="filie" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].filie.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="filie" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="filie" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                    
                                                    
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Placówki</td>
                                                    <td>{this.state.matrix["item" + key.id].placowki.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="placowki" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="placowki" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].placowki.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="placowki" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="placowki" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].placowki.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="placowki" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="placowki" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Grupy</td>
                                                    <td>{this.state.matrix["item" + key.id].grupy.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="grupy" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="grupy" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].grupy.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="grupy" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="grupy" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].grupy.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="grupy" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="grupy" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Programy nauczania</td>
                                                    <td>{this.state.matrix["item" + key.id].programy.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="programy" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="programy" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].programy.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="programy" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="programy" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].programy.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="programy" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="programy" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Pracownicy</td>
                                                    <td>{this.state.matrix["item" + key.id].pracownicy.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].pracownicy.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].pracownicy.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="pracownicy" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Rodzice i uczniowie</td>
                                                    <td>{this.state.matrix["item" + key.id].rodzice.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].rodzice.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].rodzice.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rodzice" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Administracja</td>
                                                    <td>{this.state.matrix["item" + key.id].administracja.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="administracja" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="administracja" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].administracja.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="administracja" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="administracja" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].administracja.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="administracja" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="administracja" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                                <tr>
                                                {typeof this.state.matrix["item" + key.id] !== 'undefined' && (<><td>Rozliczenia</td>
                                                    <td>{this.state.matrix["item" + key.id].rozliczenia.show ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="show" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="show" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].rozliczenia.edit ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="edit" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="edit" onClick={this.chageAccessType}>Zabronione </span>)}</td>
                                                    <td>{this.state.matrix["item" + key.id].rozliczenia.delete ? (<span className="badge badge-pill badge-success clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="delete" onClick={this.chageAccessType}>Dozwolone </span>):(<span className="badge badge-pill badge-danger clickable" data-dep={key.id} data-dzial="rozliczenia" data-operacja="delete" onClick={this.chageAccessType}>Zabronione </span>)}</td></>)}
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                     
                                        </div>))}

                                    </div>)}
                            </div>
                        </div>
                    
                    </div>

                    <span className="float-right">
                        {/* <button className="btn btn-success btn-sm addToMyChat" data-id={"cośtam"} >Czatuj</button>&nbsp; */}
                        <button className="btn btn-success btn-sm" onClick={this.saveLector}>Zapisz</button>

                        </span>     
                </div>
            </div>    
        

   

        </div>

     );
    }
 }
