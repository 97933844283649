import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
//import ankieta from '../../functions/ankieta.js';
import {FaChalkboardTeacher}  from "react-icons/fa";
import CodesitTable from '../common/codesittable.js';
import { FaUserAlt }  from "react-icons/fa";
import {Link} from "react-router-dom";
export default class PrepareCourse extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",newName:"",parents:[],id:0,
           programList:[{zaproszeni:{},odpwoiedzi:{}}],createModal:false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.createProgram = this.createProgram.bind(this);
        this.sendToCreate =this.sendToCreate.bind(this);
        this.createName =this.createName.bind(this);
        this.hideCreateModal =this.hideCreateModal.bind(this);

    } 
    createProgram(){this.setState({createModal:true})}
    hideCreateModal(){this.setState({createModal:false})}
    sendToCreate()
    {
        this.ask('groups', 'createDziennik', this.state.id);
        this.hideCreateModal();
    }
    createName(ev){this.setState({newName:ev.target.value})}
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        this.setState({id:id});
        this.ask('groups', 'getProgramListToEdit', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getProgramListToEdit':
                    self.setState({ programList:response.data.data, loaded:true});
                    //self.ask('groups', 'getParentData', response.data.data[0].zaproszeni);
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    isJson(str) {try {JSON.parse(str);} catch (e) { return false;}return true;}
    mergeResponse(zaproszeni, odpowiedzi)
    {   
        console.log("INPUT", zaproszeni, odpowiedzi)
    var odp =[]
    if (zaproszeni.length>0){odp=zaproszeni;}
    for (var a = 0; a<zaproszeni.length;a++)
    {
        odp[a]={tel:zaproszeni[a],dec:odpowiedzi[zaproszeni[a]]};
    }
    console.log("ZAPROSZENI",odp);
        return odp;
    }


    render() {
    
    var headers=[
        {title:"Nazwa programu", data:"name"},
        {title:"Rozpoczęcie", data:"start"},
        {title:"Zakończenie", data:"end"}];
    var zaproszeni = [];
    if (this.isJson(this.state.programList[0].zaproszeni))
    {
        zaproszeni = JSON.parse(this.state.programList[0].zaproszeni);
    }
    var odpowiedzi=[];
    if (this.isJson(this.state.programList[0].odpowiedzi))
    {
        odpowiedzi = JSON.parse(this.state.programList[0].odpowiedzi);
    }
    var wyniki = this.mergeResponse(zaproszeni, odpowiedzi)
    var grupa={};
    if (typeof this.state.programList!=="undefined"){
        grupa = this.state.programList[0];
    }
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaChalkboardTeacher /> Wyniki ankiety i publikacja kursu
                <span className="float-right">
                    <button className="btn btn-success btn-sm" onClick={this.createProgram}>Utwórz</button>
                </span>
                </h3>
                    <div className="prinfo">
                    <h5>1. Parametry kursu</h5> 
                    <table className="table">
                        <thead></thead>
                        <tbody>
                            <tr><td>Nazwa grupy</td><td>{grupa.name}</td></tr>
                            <tr><td>Data rozpoczęcia</td><td>{grupa.start}</td></tr>
                            <tr><td>Data zakończenia</td><td>{grupa.end}</td></tr>
                        </tbody>
                    </table> 

                    <h5>2. Wyniki ankiety</h5> 
                    {zaproszeni.map((key,i)=>(<div key={i} className="row responseAnkieta">
                    <div className="col-sm-12">
                        <span className="float-right">
                        <div className="btn-group">
                            <button className="btn btn-warning btn-sm">Wyślij ponownie SMS</button>
                            <button className="btn btn-warning btn-sm">Wyślij mail</button>
                            <button className="btn btn-warning btn-sm">Wypełnij ankietę za klienta</button>
                            <button className="btn btn-primary btn-sm">Wyślij umowę na mail</button>
                        </div>
                        </span>
                    </div>
                    <div className="col-sm-2">
                        <FaUserAlt className="person big gray"/><b>Nr.{i+1} </b>{key.tel}
                    </div>
                    <div className="col-sm-10">
                        <table className="table">
                           <tbody>
                                <tr>
                                    <td>Odpowiedź</td><td>{(typeof odpowiedzi[key.tel]!=="undefined") ? odpowiedzi[key.tel].button: "Osoba jeszcze nie udzieliła odpowiedzi"}</td>
                                </tr>
                                <tr>
                                    <td>Ankieta</td><td>{(typeof odpowiedzi[key.tel]!=="undefined") ? <Link to={odpowiedzi[key.tel].text} className="btn btn-sm btn-secondary" target="_blank">Link do ankiety</Link>: "Osoba jeszcze nie udzieliła odpowiedzi"}</td>
                                </tr>
                                <tr>
                                    <td>Komentarz</td><td><div className="myForms">{(typeof odpowiedzi[key.tel]!=="undefined") ? odpowiedzi[key.tel].data: "Osoba jeszcze nie udzieliła odpowiedzi"}</div></td>
                                </tr>
                            </tbody>
                        </table>  
                            
                        </div> </div>))}
                    
                   
                    <h5>3. Publikacja kursu</h5>  
                    <p>Kurs z bierzącymi paramterami zostanie rozpoczęty dla tych osób, kóre zaakceptowały umowę i w sposób lelektroniczny ją podpisały.</p>      
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}><i className="far fa-times-circle"></i> Zamknij</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.createModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideCreateModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Uruchamianie kursu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
             Uwaga. Ta operacja jest nieodwracalna. Po naciśnięciu przycisku "Utwórz" zostanie wygenerowany dziennik zajęć
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideCreateModal}><i className="far fa-times-circle"></i> Zamknij</button>
            <Link to="/groups/prepare/index" className="btn btn-success" onClick={this.sendToCreate}><i className="far fa-times-circle"></i> Utwórz</Link>

        </Modal.Footer>
      </Modal>

         </div>

     );
    }
 }