import React from 'react';

export default class Passwordcmp extends React.Component{
    constructor() {  
        super();    
        this.state = {preview:"mouseup"}
        this.strengthmeter = this.strengthmeter.bind(this);
        this.preview = this.preview.bind(this);
        };
        
    strengthmeter(pass)
    {
        if (pass===null || typeof pass ==="undefined"){return 0;}
        var passlength = pass.length;
        var passmin = parseFloat(passlength/this.props.minlength);
        if (passmin>1){passmin=1}
        if (isNaN(passmin)){passmin=8;}
        var matchedCase = new Array();
        matchedCase.push("[$@$!%*#?&]"); // Special Charector
        matchedCase.push("[A-Z]");      // Uppercase Alpabates
        matchedCase.push("[0-9]");      // Numbers
        matchedCase.push("[a-z]");     // Lowercase Alphabates
        var ctr = 0;
        for (var i = 0; i < matchedCase.length; i++) {
            if (new RegExp(matchedCase[i]).test(pass)) {
                ctr++;
            }
        }
        return (ctr*25*passmin);
    }
    preview(ev)
    {
        console.log(ev.type)
        this.setState({preview:ev.type})
    }
    render(){  
        var {label, type, className, value, onChange, name} = this.props;
        var percent = this.strengthmeter(value);
        var style={background:"none",border:"none" , display:"inline", display:"inline-block", width:"75%"}
        var internaltype = type
        if (typeof type==="undefined"){internaltype="password";}
        if (this.state.preview==="mousedown"){internaltype="text"}
        if (typeof value==="undefined" || typeof value==="null"){value="";}

        var percentColor=["#f54242", "ora#ffb829nge", "#fae039",  "#c8fa73","#1aab00"];
        var color = percentColor[parseInt(percent/25)]
        
        //var color = "red"
        console.log("PASS", value, percent, color,parseInt(percent/25))
       return (<div>
                {typeof type==="undefined" ? (<></>):(<div><label>{label}</label></div>)}
                <div className={typeof className==="undefined" ? "":className} >
                <input 
                    type={internaltype} 
                    value={value}
                    onChange={onChange}
                    name={name}
                    style={style}
                />
                <div  style={{ display:"inline-block", width:"25%", textAlign:"right"}}>
               <i className="fa fa-eye-slash" style={{display:"inline-block", cursor:"pointer"}} onMouseDown={this.preview} onMouseUp={this.preview}></i>
                </div>
               
                </div>
                <div style={{width:"100%"}}><div style={{backgroundColor:color, height:"2px",  width:percent+"%"}}>a</div></div>
           </div>
       )
    }
}


