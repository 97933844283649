import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from '../../functions/timedifference.js';
import {FaChalkboardTeacher}  from "react-icons/fa";
import CodesitTable from '../common/codesittable.js';

export default class GenerateCourse extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",newName:"",
           programList:[],createModal:false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.createProgram = this.createProgram.bind(this);
        this.sendToCreate =this.sendToCreate.bind(this);
        this.createName =this.createName.bind(this);
        this.hideCreateModal =this.hideCreateModal.bind(this);

    } 
    createProgram(){this.setState({createModal:true})}
    hideCreateModal(){this.setState({createModal:false})}
    sendToCreate()
    {
        if(this.state.newName.length>3)
        {
            this.ask('groups', 'createNewProgram', this.state.newName);
            this.hideCreateModal();
        }
    }
    createName(ev){this.setState({newName:ev.target.value})}
    componentDidMount()
    {
        this.ask('groups', 'getProgramListToGenerate', this.props.user);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getProgramListToGenerate':self.setState({ programList:response.data.data, loaded:true});break;
                case 'createNewProgram':self.ask('groups', 'getProgramList', self.props.user);break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
    
    var headers=[
        {title:"Nazwa programu", data:"name"},
        {title:"Rozpoczęcie", data:"start"},
        {title:"Zakończenie", data:"end"}];
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaChalkboardTeacher /> Edycja grup ankietowanych 
                {/* <span className="float-right">
                <button className="btn btn-success btn-sm" onClick={this.createProgram}>Nowy</button>
                </span> */}
                </h3>
                    <div className="prinfo">
                    <CodesitTable 
                            headers={headers}
                            data={this.state.programList} 
                            link={"/groups/prepare/edit/"}
                        />         
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}><i className="far fa-times-circle"></i> Zamknij</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.createModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideCreateModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Tworzenie nowego programu nauczania
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
              <label>Nazwa</label>
            <input type="text" className="myInput" onChange={this.createName} value={this.state.newName}/>
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideCreateModal}><i className="far fa-times-circle"></i> Zamknij</button>
            <button className="btn btn-success" onClick={this.sendToCreate}><i className="far fa-times-circle"></i> Utwórz</button>

        </Modal.Footer>
      </Modal>

         </div>

     );
    }
 }