import React from 'react';
// import {FaTrashAlt, FaEdit, FaLink, FaRegAddressCard} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
// import finder from './../../functions/finddataset.js';
import {Link} from "react-router-dom";
// import Reactable from 'reactable';
// import ParentNavigation from './parentnavigation.js';
import planeta from './../../functions/mainpage.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";
import {ToHaveInfo, TimeDiff} from './../common/utylity.js';
import {TiShoppingCart} from "react-icons/ti"
import time from './../../functions/timedifference.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';

export default class ChildrenEdit extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, uprawnieni:["Anna Pohanke (Ciotka)", "Tadeusz Drozda (dziadek)", "Iwona Ropa (Sąsiadka)"],student:{user:[],parent:[],courses:[]},
            szkoly:[],getActiveCourses:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addPerson = this.addPerson.bind(this);
        this.savePerson = this.savePerson.bind(this);
        this.ask = this.ask.bind(this);
        this.changeStudentInfo = this.changeStudentInfo.bind(this);
        this.getActiveCourses = this.getActiveCourses.bind(this);
        this.getRegulamin = this.getRegulamin.bind(this);

    } 

    getRegulamin()
    {
        var doc = new jsPDF()
        doc.setFontSize(8);
       
        doc.text('Regulamin', 10, 10)
        doc.save('Regulamin.pdf')
    }
    getActiveCourses()
    {
        this.ask("students", "getActiveCourses", null);
    }
    changeStudentInfo(ev)
    {
        var student = this.state.student
        console.log(ev.target.dataset.id)
        switch (ev.target.dataset.id)
        {
            case 'imie':student.user.imie = ev.target.value;break;
            case 'nazwisko':student.user.nazwisko = ev.target.value;break
            case 'klasa':student.user.klasa = ev.target.value;break
            case 'szkola':student.user.szkola = ev.target.value;break
            case 'notes':student.user.notes = ev.target.value;break
            default:break;

        }
        this.setState({student:student});
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
           // var modules = self.state.modules;
            switch(command)
            {    
                case 'getStudentInfo':
                var student = response.data.data;
                if(student.user.osoba===null){student.user.osoba=[]}
                self.setState({student:student});break;
                case 'getShools':self.setState({szkoly:response.data.data});break;
                case 'saveStudentInfo':alert("Zapisano pomyślnie");break;
                case 'getActiveCourses':
                var data = response.data.data
                for (var a = 0; a <data.length;a++)
                {
                    data[a].days = JSON.parse(data[a].days)
                }
                self.setState({getActiveCourses:data});break;
                default:break;
            }
            //self.setState({modules:modules});
        }
        else
        {
            console.log(response.data);
        }
        }).catch(function (error) {console.log(error);});
    }
    deleteItem(ev)
    {
        console.log(ev.target)
        var toDel = ev.target.dataset.id;
        var up =[];
        if (planeta.isJson(this.props.studentInfo.user.osoba)){up = JSON.parse(this.props.studentInfo.user.osoba)}
        var newUp = [];
        for (var a = 0; a <up.length; a++)
        {
            if (up[a]!==toDel){newUp.push(up[a])}
        }
        this.props.changeStudentInfo({target:{dataset:{id:"osoba"}, value:JSON.stringify(newUp)}})
    }
    addPerson()
    {
        var person = document.getElementById('osoba').value;
        
        if (person.length>0)
        {
            var up =[];
            if (planeta.isJson(this.props.studentInfo.user.osoba)){up = JSON.parse(this.props.studentInfo.user.osoba)}
            up.push(person);
            this.props.changeStudentInfo({target:{dataset:{id:"osoba"}, value:JSON.stringify(up)}})
            this.hideModal();
        }
    }
    savePerson()
    {
        this.ask("students", "saveStudentInfo", JSON.stringify(this.state.student.user));
    }
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    showModal()
    {
        this.setState({deleteModal:true})
    }
    componentDidMount()
    {
        var id = planeta.getIdFromLink();
        this.ask('students', 'getStudentInfo', id);
        this.ask('students', 'getShools', null);
        this.ask("students", "getActiveCourses", null);
        
        }
    render() {
        var user = this.state.student.user;
        var parent =  this.state.student.parent;
        var courses =  this.state.student.courses;
        var uprawnieni = [];  
        if (user.osoba!=="" && user.osoba!==null && user.osoba!=="[]" && planeta.isJson(user.osoba)){uprawnieni = JSON.parse(user.osoba)}
        if (uprawnieni===null){uprawnieni = []}
        
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3><FaFolderOpen /> Informacje szczegółowe</h3>
                    <div className="prinfo parentInfo">
                    <br></br>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Imię i nazwisko</label>
                            <div type="text" className="myInput disabled" data-id="name">{parent.name} </div>
                        </div>
                        <div className="col-sm-4">
                            <label>Mail</label>
                            <div type="text" className="myInput disabled" data-id="mail">{parent.mail} </div>

                        </div>
                        <div className="col-sm-4">
                            <label>telefon</label>
                            <div type="text" className="myInput disabled" data-id="telefon">{parent.telefon}</div>

                        </div>
                    
                    </div>
                    </div>

                    <div className="prinfo studentInfo">
                    <br></br>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Imię</label>
                            <input type="text" className="myInput" data-id="imie" value={user.imie} onChange={this.changeStudentInfo} />
                            <label>Nazwisko</label>
                            <input type="text" className="myInput" data-id="nazwisko" value={user.nazwisko} onChange={this.changeStudentInfo}/>
                            {/* <div className="AddInfo"><label>Uprawnieni do informacji</label><span onClick={this.showModal} className="float-right asAddButton"></span></div>
                            <div>

                                {uprawnieni.map((key, i)=>(<ToHaveInfo key={i} user={key} data-id={key} deleteItem={this.deleteItem} />))}
                              
                            </div> */}
                        </div>
                        <div className="col-sm-4">
                            <label>Szkoła</label>
                            <select className="myInput"  value={user.szkola} data-id="szkola" onChange={this.changeStudentInfo}>
                                <option value="0">--- wybierz ---</option>
                               {this.state.szkoly.map((key,i)=>(<option key={key.id} value={key.id}>{key.nazwa}</option>))}
                            </select>
                            <label>Kalsa</label>
                            <input type="text" className="myInput" value={user.klasa} data-id="klasa" onChange={this.changeStudentInfo}/>
                        </div>
                        <div className="col-sm-4">
                            {/* <label>Status</label>
                            <input type="text" className="myInput" data-id="status" value={user.status}  onChange={this.changeStudentInfo} /> */}
                            <label>Dołączono</label>
                            <div className="myInput">{user.created} <span className="float-right timediff small">{time.calculateDiff(new Date(), user.created)}</span></div>
                            <label>Ostatnia modyfikacja</label>
                            <div className="myInput">{user.updated} <span className="float-right timediff small">{time.calculateDiff(new Date(), user.updated)}</span> </div>
                        </div>
                        <div className="col-sm-12">
                        <label>Notatki</label>
                            <textarea data-id="notes" onChange={this.changeStudentInfo} className="myInput" value={user.notes}></textarea>

                        </div>
                        <div className="col-sm-12"><button className="btn btn-success btn-sm" onClick={this.savePerson}>Zapisz</button></div>
                    </div>
                    </div>
                    <div className="prinfo courseInfo">
                    <br></br>
                    <div className="row">
                    
                    <div className="col-sm-6"><div className="card">
                                    <div className="card-header"><b>Aplikacja z kursami</b></div>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <TiShoppingCart className="lessoncart" />
                                        </div>
                                        <div className="col-sm-9">
                                            Możesz w każdym momencie dołączyć do dowolnego kursu,
                                            nawet już trwającego. Wystarczy, że odwiedzisz nasz sklep do nabędziesz jakiś kurs
                                            w naszej aplikcaji.
                                        </div>
                                    </div>
                                       

                                    </div>
                                    <div className="card-footer">
                                        <Link to={"/shop/index"}   className="btn btn-secondary btn-sm">Przejdź do sklepu</Link>&nbsp;
                                        <button   className="btn btn-secondary btn-sm" onClick={this.getRegulamin}>Regulamin</button>
                                    </div>
                                    </div></div>
                  
                    {courses.map((key)=>(<div key={key.id} className="col-sm-6"><div className="card" key={key.id}>
                                    <div className="card-header"><b>Kurs: {key.name}</b></div>
                                    <div className="card-body">
                                        <table className="table">
                                            <tbody>
                                            <tr><td>data rozpoczęcia</td><td><div className="myInput">{key.start} <TimeDiff text={time.calculateDiff(new Date(), key.accesDate)} /></div></td></tr>
                                            <tr><td>data zakończenia</td><td><div className="myInput">{key.end} </div></td></tr>
                                            
                                            {/* <tr><td>Sposób płatności</td><td className="alert-info"><FaInfoCircle /> {key.paymentMethod} </td></tr>
                                            <tr><td>Status płatności</td><td className="alert-info"><FaInfoCircle /> {key.paymentStatus}</td></tr> */}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="card-footer">Footer</div>
                                    </div></div>))}

                    {/* {this.state.getActiveCourses.map((key)=>(<div key={key.id} className="alert alert-default">
                        <div>Kurs {(key.status===2) ? "planowany":"trwający"}: <strong>{key.name}</strong></div>
                        <div> {key.start} :{key.end} </div>
                        <button className="btn btn-success btn-sm">Dołącz</button>
                        <div>{typeof key.days !=='undefiend' && key.days.map((wax,i)=(<div key={i}>{wax[1]}</div>))}</div>
                    </div>))} */}
                    </div>
                    </div>
                </div>    
            </div>

         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Wskazanie osoby uprawnionej
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Osoba uprawniona to osoba, która może otrzymywać informację o postępie w nauce, zapisach
            na liście obecności a w przypadku małoletnich odbieranych przez opiekuna tylko osoba z listy
            ma prawo zabrać dziecko z placówki.
            </p>
            <label>Imię i nazwisko osoby uprawnionej</label>
            <input type="text" className="myInput" id="osoba" />
            
         
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-success" onClick={this.addPerson}>Dodaj</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }
