function isJson(str) { try { JSON.parse(str); } catch (e) { return false; } return true;}

function isTel(str)
{
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(str);  
}
function isMail(str){
    return /^(?:[\w-]+\.?)*[\w-]+@(?:[\w-]+\.)+[\w]{2,3}$/.test(str);    
}
function isNip(nip) {
    var nipWithoutDashes = nip.replace(/-/g,"");
    var reg = /^[0-9]{10}$/;
    if(reg.test(nipWithoutDashes) === false) {
        return false;}
    else
    {
        var digits = (""+nipWithoutDashes).split("");
        var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
         
        return (parseInt(digits[9])===checksum);
    }
}

function isRegon(regon) {
    var reg = /^[0-9]{9}$/;
    if(!reg.test(regon)) 
        return false;
    else
    {
        var digits = (""+regon).split("");
        var checksum = (8*parseInt(digits[0]) + 9*parseInt(digits[1]) + 2*parseInt(digits[2]) + 3*parseInt(digits[3]) + 4*parseInt(digits[4]) + 5*parseInt(digits[5]) + 6*parseInt(digits[6]) + 7*parseInt(digits[7]))%11;
        if(checksum === 10) 
            checksum = 0;
         
        return (parseInt(digits[8])===checksum);
    }
}
function isPesel(pesel) {
    var reg = /^[0-9]{11}$/;
    if(reg.test(pesel) === false) 
        return false;
    else
    {
        var digits = (""+pesel).split("");
        if ((parseInt(pesel.substring( 4, 6)) > 31)||(parseInt(pesel.substring( 2, 4)) > 12))
            return false;
         
        var checksum = (1*parseInt(digits[0]) + 3*parseInt(digits[1]) + 7*parseInt(digits[2]) + 9*parseInt(digits[3]) + 1*parseInt(digits[4]) + 3*parseInt(digits[5]) + 7*parseInt(digits[6]) + 9*parseInt(digits[7]) + 1*parseInt(digits[8]) + 3*parseInt(digits[9]))%10;
        if(checksum===0) checksum = 10;
            checksum = 10 - checksum;
 
        return (parseInt(digits[10])===checksum);
    }
}
function isUrl(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

function isPostCode(code) {
    return /(^\d{2}-\d{3}$)/.test(code);}
function noMessage(){return {response:""}}
function verifyLoginData(name, value)
{
    switch (name)
    {
        case "mail": if (!isMail(value)){return {response:"To nie jest poprawny adres mail"}}else {return noMessage();}
        case "pass": if (value.length <5) {return {response:"Hasło zbyt krótkie"}} else {return noMessage()}
        default: return "No method found";
    }
}
function isCorrect(zakres, dane){
    if (zakres === "loginForm")
    {
        var flag = false;
        for (var a = 0; a<dane.length; a++)
        {
            if (dane[a].innerHTML !== ""){flag=true;}
        }
        return flag;
    }
}
function preparedata(data)
{
    var odp = {command:"", section:"",data:""};
    var d = JSON.parse(data);
    if (d.action==="login")
    {
        odp.command = d.action;
        odp.section = "user";
        odp.data = JSON.stringify({mail:d.mail, pass:d.pass});
    }
    return (odp);
}
function sanitizeCalendarData(data)
{
    var d = data;
    for (var a=0; a<d.length; a++)
    {
        d[a].start = new Date(d[a].start);
        d[a].end = new Date(d[a].end);
        d[a].allDay = (parseInt(d[a].allDay)===1)
    }
    console.log("SANIOTIZED", d)
    return d;
}

function exist(e)
{
    return (typeof e !== 'undefined' )
}

function markChecked(value, ev)
{
    if (value){ev.target.classList.remove("incorrect");}else{ev.target.classList.add("incorrect");}
}
function checkForm(ev)
{
    console.log("EV", ev.target.dataset.id, ev.target.value, (ev.target.required));
    switch (ev.target.dataset.id)
    {
        case 'mail': markChecked(isMail(ev.target.value), ev);break;
        case "www" : markChecked(isUrl(ev.target.value), ev);break;
        case "nazwa": markChecked((ev.target.value.length>5), ev);break;
        case "kod": markChecked(isPostCode(ev.target.value), ev);break;
        case "nip": markChecked(isNip(ev.target.value), ev);break;
        case "pesel": markChecked(isPesel(ev.target.value), ev);break;
        case "regon": markChecked(isRegon(ev.target.value), ev);break;
        case "tel":markChecked(isTel(ev.target.value), ev);break;
        default:if (ev.target.required){markChecked((ev.target.value.length>0),ev)}break;;
    }
}

function getStudentIdFromTable(ev)
{
    
    console.log(ev.target, ev.target.parentElement.children[0].innerHTML, ev.target.parentElement.children.length);
    return {tag:ev.target.tagName,
        child:ev.target.parentElement.children.length ,
        id:parseInt(ev.target.parentElement.children[0].innerHTML)};
}
function getIdFromLink()
{
    var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        return id;
}
function mergeArrays(a,b) {
    var uniqueArray = a;
    for (var a = 0; a<b.length; a++)
    {
        if (!uniqueArray.includes(b[a])){uniqueArray.push(b[a])}
    }
    return uniqueArray
}

function calculateCosts(data, cennik)
{
    console.log("CALC",data, cennik)
    var ceny =cennik.body;
    console.log(ceny)
    var dzieci = JSON.parse(data.selected).length;
    var sala = parseInt(data.kosztsali);
    var dni = data.harmonogram[data.harmonogram.length-1].count;

    console.log(ceny, sala, dni, dzieci)
    //Znajdz odpowedni cennik
    var odpowiedniCennik ={};
    for (var a = 0; a<ceny.length; a++)
    {
        if (ceny[a][0]===dzieci){odpowiedniCennik = ceny[a]}
    }
    //liczymy miesiące
    var start = new Date(data.start ).getTime()
    var end = new Date(data.end).getTime()
    var DiffMes = Math.round((end - start)/1000/60/60/24/30)
    console.log(odpowiedniCennik)
    var odp =[]
    odp.push({name:"Ilość uczestników", val:dzieci})
    odp.push({name:"Czas trwania kursu w miesiącach", val:DiffMes})
    odp.push({name:"Całkowita ilośc zajęć", val: dni})
    odp.push({name:"Jednostkowy Koszt Lektora za zajęcia",val:numberWithCommas(odpowiedniCennik[2])+" zł"})
    odp.push({name:"Cena za zajęcia dla uczestnika", val: numberWithCommas(odpowiedniCennik[1])+" zł"})
    odp.push({name:"Całkowity koszt kursu dla rodzica", val:numberWithCommas(odpowiedniCennik[1]*dni)+" zł"})
    odp.push({name:"Rata opłaty za kurs przy dwóch ratach", val:numberWithCommas(odpowiedniCennik[1]*dni/2)+" zł"})
    odp.push({name:"Miesięczna rata za kurs", val:numberWithCommas(odpowiedniCennik[1]*dni/DiffMes)+" zł"})

    odp.push({name:"Całkowity koszt Lektora",val:numberWithCommas(odpowiedniCennik[2]*dni)+" zł"})
    odp.push({name:"Całkowity przychód",val:numberWithCommas(odpowiedniCennik[1]*dni*dzieci)+" zł"})
    odp.push({name:"Całkowity koszt najmu sali", val:numberWithCommas(sala*dni)+" zł"})
    odp.push({name:"Przychód po odjęciu kosztów", val:numberWithCommas(odpowiedniCennik[1]*dni*dzieci - sala*dni - odpowiedniCennik[2]*dni)+" zł" })
    

    return odp;
}

function calculateCostsForParents(data, cennik)
{
    console.log("CALC",data, cennik)
    var ceny =cennik.body;
    console.log(ceny)
    var dzieci = JSON.parse(data.selected).length;
    var sala = parseInt(data.kosztsali);
    var dni = data.harmonogram[data.harmonogram.length-1].count;

    console.log(ceny, sala, dni, dzieci)
    //Znajdz odpowedni cennik
    var odpowiedniCennik ={};
    for (var a = 0; a<ceny.length; a++)
    {
        if (ceny[a][0]===dzieci){odpowiedniCennik = ceny[a]}
    }
    //liczymy miesiące
    var start = new Date(data.start ).getTime()
    var end = new Date(data.end).getTime()
    var DiffMes = Math.round((end - start)/1000/60/60/24/30)
    console.log(odpowiedniCennik)
    var odp =[]
    odp.push({name:"Ilość uczestników", val:dzieci})
    odp.push({name:"Czas trwania kursu w miesiącach", val:DiffMes})
    odp.push({name:"Całkowita ilośc zajęć", val: dni})
    // odp.push({name:"Jednostkowy Koszt Lektora za zajęcia",val:numberWithCommas(odpowiedniCennik[2])+" zł"})
    odp.push({name:"Cena za zajęcia dla uczestnika", val: numberWithCommas(odpowiedniCennik[1])+" zł"})
    odp.push({name:"Całkowity koszt kursu dla rodzica", val:numberWithCommas(odpowiedniCennik[1]*dni)+" zł"})
    odp.push({name:"Rata opłaty za kurs przy dwóch ratach", val:numberWithCommas(odpowiedniCennik[1]*dni/2)+" zł"})
    odp.push({name:"Miesięczna rata za kurs", val:numberWithCommas(odpowiedniCennik[1]*dni/DiffMes)+" zł"})

    // odp.push({name:"Całkowity koszt Lektora",val:numberWithCommas(odpowiedniCennik[2]*dni)+" zł"})
    // odp.push({name:"Całkowity przychód",val:numberWithCommas(odpowiedniCennik[1]*dni*dzieci)+" zł"})
    // odp.push({name:"Całkowity koszt najmu sali", val:numberWithCommas(sala*dni)+" zł"})
    // odp.push({name:"Przychód po odjęciu kosztów", val:numberWithCommas(odpowiedniCennik[1]*dni*dzieci - sala*dni - odpowiedniCennik[2]*dni)+" zł" })
    

    return odp;
}

function numberWithCommas(x) {
    return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function week()
{
    return ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota']
}

function addMinutes(key,czas)
{
    var x = new Date('1977-10-11 ' +key+":00")
    var y = new Date(x.getTime()+parseInt(czas)*60*1000)
    return y.getHours()+":"+y.getMinutes();
}
module.exports ={
    isJson,
    isMail,
    isPesel,
    isNip,
    verifyLoginData,
    isCorrect,
    preparedata,
    sanitizeCalendarData,
    exist,
    checkForm,
    getStudentIdFromTable,
    getIdFromLink,
    mergeArrays,
    calculateCosts,
    calculateCostsForParents,
    numberWithCommas,
    week,
    addMinutes
}