import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch  } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NavController from './parts/components/navs/navcontroller.js';
import Ankieta from './parts/components/ankieta/ankieta.js';
import Modal from 'react-bootstrap/Modal'
import Loader from './parts/components/loader.js';
import Login from './parts/components/login.js';
import Resume from './parts/components/admin/resume.js';
import Departments from './parts/components/departments/departments.js';
import EditDepartments from './parts/components/departments/editdepartments.js';
import Departmentcalendar from './parts/components/departments/departamentcalendar.js';
import Places from './parts/components/places/placelist.js';
// import editPlace from './parts/components/places/editplace.js';
 
import Courses from './parts/components/courses/courses.js';

import Administration from './parts/components/administration/administracja.js';
import Planeta from './parts/functions/mainpage.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import Toppanel from './parts/components/toppanel.js';
import {IoMdHand} from "react-icons/io"; 
import EditPlace from './parts/components/places/editplace.js';
import ParentIndex from './parts/components/parents/parentsindex.js';
import StudentInfo from './parts/components/parents/studentinfo.js';
import ParentInfo from './parts/components/parents/parentinfo.js';

import LectorsIndex from './parts/components/lectors/lectorindex.js';
import LectorInfo from './parts/components/lectors/lectorinfo';

import EditCourses from './parts/components/courses/editcourses.js';
import GroupIndex from './parts/components/groups/groupindex.js';
import NewGrop from './parts/components/groups/newgroup.js';
import GroupSelect from './parts/components/groups/groupselect.js';
import GenerateCourse from './parts/components/groups/generatecourse.js';
import PrepareCourse from './parts/components/groups/preparecourse.js';
import ActiveGroup from './parts/components/groups/activegroup.js';
import ActiveGroupDetails from './parts/components/groups/activegroupdetails.js';
import ActiveGroupLessonDetails from './parts/components/groups/activegrouplessondetails.js';

import ProgramList from './parts/components/programs/programlist.js';
import ProgramDetails from './parts/components/programs/programdetails.js';
import EditLesson from './parts/components/programs/editlesson.js';

import ParentChildren from './parts/components/parent/parentchildren.js';
import ChildrenEdit from './parts/components/parent/childrenedit.js';
import ParentPayment from './parts/components/parent/parentpayment.js';
import ParentSetup from './parts/components/parent/parentsetup';
import ParentGroup from './parts/components/parent/parentgroup';

import ParentMainData from './parts/components/parent/parentmaindata.js';
import ResetPass from './parts/components/resetpass.js';
import NewPass from './parts/components/newpass.js';

import MainLector from './parts/components/lector/mainlector.js';
import Lekcja from './parts/components/lector/lekcja.js';
import Harmonogram from './parts/components/lector/harmonogram.js';
import SendMail from './parts/components/common/sendmail.js';
import ProgramReadOnly from './parts/components/lector/program.js';


import ShopProductList from './parts/components/shop/shopproductlist.js';
import ShopProductDetails from './parts/components/shop/shopproductdetails.js';

import EditPriceList from './parts/components/administration/editprocelist.js';
import RenderPdf from './parts/components/common/renderpdf.js';
import plan from './parts/functions/mainpage.js';

import LectorGroups from './parts/components/lector/lectorgroups.js';
import LectorGroupsDetails from './parts/components/lector/lectorgroupsdetails.js';
import LectorSetup from './parts/components/lector/lectorsetup.js';

import EmployersSettlement from './parts/components/administration/employerssettlement.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import UserSetup from './parts/components/common/userpass'

class App extends React.Component {
constructor() {  
    super();   

    this.state = {
        loaded:false, userId:"", isUser: false, userRights:"",serverResponse:"",userRole:0, server:"",modalState:false,
        modules:{oddzialy:false, places:false, resume:false, shools:false, 
            lectors:false, courses:false},
        calendarData:[],
        oddzialy:[], activeDepartment:0,
        places:[],
        resume:{wersja:"0.1", data:"2020-02-09",description:"Tworzenie kursów"},
        administrationdata:{courses:{},},
        studentList:[], parentList:[],placesList:[],studentInfo:{user:[],parent:[],courses:[]},
        allPlaces:[],shools:[],parent:[],
        courses:[],
        lector:[{}],administration:[{}],
        recovery:""
    }; 
    this.componentDidMount = this.componentDidMount.bind(this);
    this.ask = this.ask.bind(this);
    this.askit = this.askit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.superData = this.superData.bind(this);
    this.administration = this.administration.bind(this);
    this.dataLoader = this.dataLoader.bind(this);
    this.default = this.default.bind(this);
    this.departments = this.departments.bind(this);
    this.editDepartments = this.editDepartments.bind(this);
    this.newDepartament = this.newDepartament.bind(this);
    this.renewPermissions = this.renewPermissions.bind(this);
    this.editDepartmentsCalendar = this.editDepartmentsCalendar.bind(this);
    this.placesList = this.placesList.bind(this);
    this.editPlace = this.editPlace.bind(this);
    this.editCourse = this.editCourse.bind(this);
    this.parentIndex = this.parentIndex.bind(this);
    this.studentInfo = this.studentInfo.bind(this);
    this.parentInfo = this.parentInfo.bind(this);
    this.changeStudentInfo = this.changeStudentInfo.bind(this);
    this.changeParentInfo = this.changeParentInfo.bind(this);
    this.lectorsIndex = this.lectorsIndex.bind(this);
    this.lectorInfo = this.lectorInfo.bind(this);
    this.changelector = this.changelector.bind(this);
    this.editCurse = this.editCourse.bind(this);
    this.groupIndex = this.groupIndex.bind(this);
    this.groupCreate = this.groupCreate.bind(this);
    this.groupList = this.groupList.bind(this);
    this.loginForm = this.loginForm.bind(this);
    this.editPrograms = this.editPrograms.bind(this);
    this.editProgramsDetails = this.editProgramsDetails.bind(this);
    this.editLesson = this.editLesson.bind(this);
    this.generateCourse = this.generateCourse.bind(this);
    this.prepareCourse = this.prepareCourse.bind(this);
    this.resetPass = this.resetPass.bind(this);
    this.resPass = this.resPass.bind(this)
    this.newPass = this.newPass.bind(this);
    this.parentChildren = this.parentChildren.bind(this);
    this.parentPayment = this.parentPayment.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
    this.lekcja = this.lekcja.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.wiewHarmonogram = this.wiewHarmonogram.bind(this);
    this.viewProgramForLector = this.viewProgramForLector.bind(this);
    this.editPriceList = this.editPriceList.bind(this);
    this.shopProductList = this.shopProductList.bind(this);
    this.shopProductDetails = this.shopProductDetails.bind(this);

} 
employersSettlement(){return (<EmployersSettlement />)}
lectorSetup(){return (<LectorSetup />)}
lectorGroupDetails(){return (<LectorGroupsDetails />)}
parentGroup(){return (<ParentGroup />)}
parentSetup(){return (<UserSetup />)}
shopProductDetails(){console.log("WTDF");return (<ShopProductDetails />)}
shopProductList(){return(<ShopProductList />)}
editPriceList(){return (<EditPriceList />)}
viewProgramForLector(){return (<ProgramReadOnly />)}
wiewHarmonogram(){return (<Harmonogram />)}
sendMail(){return(<SendMail />)}
lekcja(){return (<Lekcja />)}
parentChildren(){return (<ParentChildren />)}
parentPayment(){return (<ParentPayment />)}
childrenEdit() {return (<ChildrenEdit />)}
activeGroup(){return (<ActiveGroup />)}
activeGroupDetails(){return (<ActiveGroupDetails />)}
activeGroupLessonDetails(){return (<ActiveGroupLessonDetails />)}
renderPDF(){return (<RenderPdf />)}
loginForm()
{
    return(<Login askit={this.askit} response={this.state.serverResponse} reset={this.resetPass} recovery={this.state.recovery}/>)
}
resPass()
{
    return (<ResetPass />)
}
newPass()
{
    return (<NewPass />)
}
resetPass(e)
{
    var mail = "";
    if (plan.isJson(e.target.dataset.content) && e.target.dataset.content!=="")
    {
        var data =JSON.parse(e.target.dataset.content)
        if (plan.isMail(data.mail))
        {
            this.ask("user", "resetPass", data.mail)
        }
        console.log(data);
    }
    else
    {
        console.log(e.target.dataset.content)
    }
    
}
componentDidMount = () =>
{
    window.toast = toast;
    window.confirmAlert = confirmAlert;
    var cookie = Cookies.get("planetarozwoju");
    this.ask("user", "getUserData", cookie);
    if (typeof cookie !=="undefined"){this.ask("user", "getUserData", cookie);}
    else{this.setState({loaded:true})}
}
ankieta(){return  <Ankieta  />}

editPrograms(){return (<ProgramList />);}
editProgramsDetails(){ return <ProgramDetails />}
editLesson(){return <EditLesson />}

superData(ev, data)
{
    var self = this;
    console.log("superData", ev, data)
    switch (ev)
    {
        case 'defaultDepartment':
        var dep = self.state.oddzialy;
        var toWrite = {}
        for (var a = 0; a<dep.length; a++)
        {
            console.log("DEP", a,(parseInt(dep[a].id)===parseInt(data)),((dep[a].id)===(data)), dep[a].id,data)
            if (parseInt(dep[a].id)===parseInt(data)){toWrite = dep[a];}
        }
        self.setState({activeDepartment: toWrite});
        break;
        default:break;
    }
}
hideModal(){this.setState({modalState:false, server:""})}
changeStudentInfo(ev)
{
    var data = ev.target.dataset.id;
    var val = ev.target.value;
    console.log("CHANGESTUDENTINFO:", data, val);
    var status = this.state.studentInfo;
    status.user[data] = val;
    this.setState({studentInfo: status});

}
changeParentInfo(ev)
{
    var data = this.state.parent;
    data[0][ev.target.dataset.id] = ev.target.value;
    console.log("SAVE", ev.target.dataset.id, ev.target.value, data[ev.target.dataset.id] )

    this.setState({parent:data});
}
lectorGroup(){return (<LectorGroups />);}
groupIndex(){return <GroupIndex  user={this.state.userRole}/>}
groupCreate(){return <NewGrop user={this.state.userRole} />}
groupList(){return  <GroupSelect user={this.state.userRole} />}
generateCourse(){return <GenerateCourse user={this.state.userRole}/>}
prepareCourse(){return <PrepareCourse user={this.state.userRole} />}

parentIndex(){return <ParentIndex ask={this.ask} changeParentInfo={this.state.changeParentInfo}  studentList={this.state.studentList} parentList={this.state.parentList}/>}
studentInfo(){
    this.dataLoader('getAllPlaces');
    return <StudentInfo 
        ask={this.ask} changeStudentInfo={this.changeStudentInfo} studentInfo={this.state.studentInfo} shools={this.state.allPlaces} />}
parentInfo(){
    return <ParentInfo 
    ask={this.ask} 
    parent={this.state.parent} 
    changeParent={this.changeParentInfo} />
}
lectorsIndex(){return <LectorsIndex ask={this.ask} lectorsInfo={this.state.administration } changeLectors={this.changeLectors}/>}
lectorInfo(){return <LectorInfo ask={this.ask} lector={this.state.administration} changelector={this.changelector}/>}
changelector(ev){
    var lector = this.state.lector;
    var val = ev.target.value;
    var key = ev.target.dataset.id;
    console.log(key,val)
    lector[0][key]=val;
    this.setState({lector:lector});
}
administration(){return <Administration data={this.state.administrationdata} ask={this.ask}/>}
editCourse(){this.dataLoader('corsureList');return (<Courses ask={this.ask} courses={this.state.courses}/>)}

newDepartament(){return (<EditDepartments tryb="new"  ask={this.ask}/>)}
editDepartmentsCalendar(){ return (<Departmentcalendar calendarData={this.state.calendarData}  ask={this.ask}/>)}
placesList(){
    this.dataLoader('departments');
    return (
        
    <Places departments={this.state.oddzialy} superData={this.superData} default={this.state.defaultDepartment} places={this.state.places}  ask={this.ask}/>)}
editPlace()
{
    this.dataLoader('departments');
    return (<EditPlace departments={this.state.oddzialy}  ask={this.ask}/>)
}

editOnceCourse(){return (<EditCourses />)}
renewPermissions(){}
logout()
{
    Cookies.remove("planetarozwoju");
    window.location.replace(window.location.protocol+"//"+window.location.host)
}
userSetup = () => {
    return (<UserSetup />);
}
askit(ev)
{
    var x = Planeta.preparedata(ev.target.dataset.content);
    this.ask(x.section, x.command, x.data);
}

ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            var modules = self.state.modules;
            switch(command)
            {
                case 'login':
                self.setState({isUser: true, userRights:response.data.data.rights.split(','), userName:response.data.data.userName, userRole:response.data.data.roles});
                Cookies.set('planetarozwoju', response.data.data.session);
                self.ask('user','getUserData', response.data.data.session);
                break;
                case 'getUserData':
                self.setState({isUser: true, loaded:true, userRights:response.data.data.roles, userName:response.data.data.userName, userId:response.data.data.userId});
                if (response.data.data.session!=Cookies.get("planetarozwoju")){console.log("Cookies dovrebbe essere cancellati");}
                break;
                case 'getDepartmentData':var modules = self.state.modules; modules.oddzialy=true;self.setState({oddzialy:response.data.data, modules:modules});break;
                case 'getPlacesByDepartment': modules = self.state.modules; modules.places=true;self.setState({places:response.data.data, modules:modules});break;
                case "getCurrentMonth":self.setState({calendarData: Planeta.sanitizeCalendarData(response.data.data)});break;
                case "saveCalendarData":console.log(data); var i = JSON.parse(data);
                        self.ask("calendar", "getCurrentMonth", 
                        JSON.stringify({departament:i.iddepartaments, dataRange:i.start.substr(0,6)})
                        )
                        break;
                case 'deleteCalendarData':i = JSON.parse(data);
                        self.ask("calendar", "getCurrentMonth", 
                        JSON.stringify({departament:i.iddepartaments, dataRange:i.data.substr(0,6)})
                        )
                        break;
                case 'deleteDepartament':self.ask("departments", "getDepartmentData", null);break;
                case 'getResume':self.setState({resume:response.data.data});break
                case 'getStudentsList':self.setState({studentList: response.data.data});break;
                case 'getParentList':self.setState({parentList: response.data.data});break;
                case 'getStudentInfo':self.setState({studentInfo: response.data.data});break;
                case 'getParentInfo':self.setState({parent:response.data.data});break;
                case 'getAllPlaces':
                var shools = self.state.modules; shools.shools = true;
                self.setState({allPlaces:response.data.data, modules:shools});break
                case 'saveStudentInfo':self.setState({modalState:true, server:"Zapisano poprawnie"});break;
                case 'createPlaceData':self.setState({modalState:true, server:"Dodano poprawnie"});break;
                case 'getLectorIndex':self.setState({lectors:response.data.data});console.log("LECTORS",response.data.data);modules.lectors =true;break
                case 'getLectorInfo':self.setState({lector:response.data.data});break;
                case 'getCourseList':self.setState({courses: response.data.data});modules.courses=true;break;
                case 'resetPass':self.setState({recovery:response.data.data});break;
                case 'getUsersByFunctions':self.setState({administration:response.data.data});console.log(response.data.data);break;
                case 'deleteStudent':window.history.back();break;
                default:break;
                
            }
            self.setState({modules:modules});
        }
        else
        {

            console.log(response.data);
            if (response.data.status==="err")
            {
                switch (command)
                {
                    case 'getUserData': //self.logout(); 
                        Cookies.remove('planetarozwoju');
                    break;
                    case 'deleteStudent':
                        window.toast.error(response.data.data.message);
                    break;
                    default:window.toast.error('Wystąpił wewnętrzny błąd serwera');break;
                }
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
departments(){ this.dataLoader('departments');return (<Departments askit={this.askit} ask={this.ask} renew={this.renewPermissions} departments={this.state.oddzialy} superData={this.superData} permissions={this.state.userRights} />)}
editDepartments(){return (<EditDepartments permissions={this.state.userRights} renew={this.renewPermissions}/>)}
default()
{
    if(this.state.userRights==="1")
    {return (<Resume  resume={this.state.resume}/>)}
    if(this.state.userRights==="5")
    {
        return (<MainLector />)
    }
    if (this.state.userRights==="6")
    {return (<ParentMainData />)}
    else
    {return (<Resume  resume={this.state.resume}/>)}
   
} 
noMatch(){return (<div className="loader"><h1><IoMdHand />404</h1><hr></hr>Zasób jest nieosiągalny. Nie masz uprawnień do przeglądania tego zasobu lub zasób nie istnieje.</div>)}
dataLoader(data)
{
    console.log("DATALOADER", data)
    switch (data)
    {
        case "getAllPlaces":
        if (this.state.shools.length===0 && this.state.modules.shools===false)
            {this.ask("departments", "getAllPlaces", null);}break;
        case "departments":
            if (this.state.modules.oddzialy===false){this.ask("departments", "getDepartmentData", null)}
            break;
        default:break;
        case "resume":
        if (this.state.modules.resume===false){this.ask("info", "getResume", null)};break;
        case "getLectorsIndex":
        if (this.state.modules.lectors===false){this.ask("user", "getLectorIndex", null)};break;
        case "corsureList":console.log("LOADING DATA");
        if (this.state.modules.courses===false){this.ask("user", "getCourseList", null)};break;

    }
}
render(){

  document.title ="Planeta Rozwoju";
  if (this.state.loaded)
  {
      if (this.state.isUser){
  return (<div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
  <Router>
      
      <Toppanel 
        username={this.state.userName} 
        userRole={this.state.userRights}
        userId={this.state.userId}
        userRights={this.state.userRights}
        logout={this.logout} 
        activeDepartment={this.state.activeDepartment}
      />
      <NavController rights={this.state.userRights}/>
                        <Switch>
                          <Route exact path="/"  component={this.default} />
                          
                          <Route path="/departments/index" component={this.departments} />
                          <Route path="/departments/edit/:id" component={this.editDepartments} />
                          <Route path="/departments/calendar/:id" component={this.editDepartmentsCalendar} />
                          <Route path="/departments/new" component={this.newDepartament} />
                          
                          <Route path="/places/calendar/:id" component={this.editDepartmentsCalendar} />
                          <Route path="/places/index" component={this.placesList} />
                          <Route path="/places/edit/:id" component={this.editPlace} />
                          <Route path="/places/new" component={this.editPlace} />
                          
                          <Route path="/programs/index" component={this.editPrograms} />
                          <Route path="/programs/edit/:id" component={this.editProgramsDetails} />
                          <Route path="/programs/view/:id" component={this.editProgramsDetails} />
                          <Route path="/programs/pdf/:id" component={this.renderPDF} />
                          <Route path="/programs/editlesson/:id" component={this.editLesson} />

                          <Route path="/courses/edit/:id" component={this.editOnceCourse} />
                          
                          
                          <Route path="/parents/index" component={this.parentIndex} />
                          <Route path="/parents/studentinfo/:id" component={this.studentInfo} />
                          <Route path="/parents/parentinfo/:id" component={this.parentInfo} />
                          <Route path="/administration" component={this.administration} />
                          <Route path="/lectors/index" component={this.lectorsIndex} />
                          <Route path="/lectors/lectorinfo/:id" component={this.lectorInfo} />
                          <Route path="/groups/index" component={this.groupIndex} />
                          
                          <Route path="/groups/create" component={this.groupCreate} />
                          <Route path="/groups/list" component={this.groupList} />
                          <Route path="/groups/edit/:id" component={this.groupCreate} />
                          <Route path="/groups/prepare/index" component={this.generateCourse} />
                          <Route path="/groups/prepare/edit/:id" component={this.prepareCourse} />
                          
                          <Route path="/groups/active/index" component={this.activeGroup} />
                          <Route path="/groups/active/edit/:id" component={this.activeGroupDetails} />
                          <Route path="/groups/active/lesson/edit/:id" component={this.activeGroupLessonDetails} />

                          <Route path="/parent/children/index" component={this.parentChildren} />
                          <Route path="/parent/children/edit/:id" component={this.childrenEdit} />
                          
                          <Route path="/parent/payment" component={this.parentPayment} />
                          <Route path="/parent/setup" component={this.parentSetup} />
                          <Route path="/lection/:id" component={this.lekcja} />
                          <Route path="/parent/index" component={this.parentGroup} />
                        

                          <Route path="/ankieta/:tel/:kurs/:crc" component={this.ankieta} />
                          <Route path="/upload/:kurs/:plik"  />
                          <Route path="/mail/:lekcja" component={this.sendMail} />
                          <Route path="/harmonogram/view/:id" component={this.wiewHarmonogram} />
                          <Route path="/readprograms/view/:id" component={this.viewProgramForLector} />
                          
                          <Route path="/editpricelist/:id" component={this.editPriceList} />
                          <Route path="/shop/index" component={this.shopProductList} />
                          <Route path="/shop/course/:id" component={this.shopProductDetails} />
                          
                          <Route path="/lectorgroup/index" component={this.lectorGroup} />
                          <Route path="/lector/groutdetails/:id" component={this.lectorGroupDetails} />
                          <Route path="/lector/setup/" component={this.lectorSetup} />
                          
                          
                          <Route path="/settlement/" component={this.employersSettlement} />
                          <Route path="/setup/" component={this.userSetup} />
                          
                          />

                          

                          
                          <Route  component={this.noMatch} />
                        </Switch>
                        <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
                        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Odpowiedź serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.server}
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-info" onClick={this.hideModal}>OK</button>
        </Modal.Footer>
      </Modal>
  </Router>
  </div>)
      }
      else
      {
          return (<><Router>
              <Switch>
                          <Route exact path="/"  component={this.loginForm} />
                          <Route path="/ankieta/:tel/:kurs/:crc" component={this.ankieta} />
                          <Route path="/reset/" component={this.resPass} />
                          <Route path="/invitation/" component={this.newPass} />
                          <Route component={this.noMatch} />

                </Switch>
          
          </Router>
          
          
          </>)
      }
  }
  else
  {
      return (<Loader/>)
  }
}
}


export default (App);