import React from 'react';
import Modal from 'react-bootstrap/Modal'
// import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';

export default class GroupIndex extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",
            grupy:[], loaded:false,
            groupStatus:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 
    componentDidMount()
    {
        this.ask('groups', 'getGrups', this.props.user);
        this.ask('groups', 'getGroupStatus', null);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGrups':self.setState({ grupy:response.data.data, loaded:true});break;
                case 'getGroupStatus':self.setState({groupStatus:response.data.data});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        
        
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3>Zarządzenia grupami</h3>
                    <div className="prinfo">
                        <div className="card-deck">
                            <PlanetaCard title="Nowa Grupa" text="Twórz nową grupę. Konfiguruj czas, członków grupy, miejsce, osobę prowadzącą" link="/groups/create" />
                            <PlanetaCard title="Edycja grup" text="Edytuj grupę. Konfiguruj czas, członków grupy, miejsce, osobę prowadzącą" link="/groups/list/" />
                            <PlanetaCard title="Ankiety" text="Rozsyłanie ankiet SMS, wprowadzanie wyników, zatwierdzanie grup" link="/groups/prepare/index" />
                        </div>  
                        <br></br>
                        <div className="card-deck">
                            <PlanetaCard title="Grupy Aktywne" text="Zarządzanie aktywnymi grupami. Zarządzanie członkami, bierzące zmiany, przeglądanie aktualnych danych grupy" link="/groups/active/index" />
                            <PlanetaCard title="Wyniki grup" text="Przeglądaj statystyki obecności, wyników w nauce, porównaj dane wśród aktywnych grup" link="/groups/statistic/index" />
                            <PlanetaCard title="Dzienniki" text="Przeglądaj dzienniki aktywnych grup" link="/groups/diary/index" />
                        </div>   
                        <div className="card-deck">
                            <PlanetaCard title="Zamykanie grup" text="Zamkmykanie grup, które skończyły się spotykać. Windykacja" link="/sdfds/sdfsfds" />
                            <PlanetaCard title="Archiwizowanie grup" text="Przenoszenie grup zamkniętych do archiwizacji" link="/sdfds/sdfsfds" />
                            <PlanetaCard title="Archiwum" text="Przeglądane danych zarchiwizowanychgit stat" link="/sdfds/sdfsfds" />
                        </div>                  
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }