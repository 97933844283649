import React from 'react';
import {IoMdPerson } from  "react-icons/io";

import axios from 'axios';
import Cookies from 'js-cookie';
import {FaRegCheckSquare, FaRegSquare}  from "react-icons/fa";

 export default class ParentSetup extends React.Component {
    constructor() {
        super();   

        this.state = {
            parentInfo:[{}],
        };
        this.ask = this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    } 
    componentDidMount()
    {
        this.ask("user", "getParentInfo", null)
    }

    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
               
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getParentInfo': self.setState({ parentInfo:response.data.data, mode:0});break;
                   
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                console.log(response.data.data)
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        var p = this.state.parentInfo[0]
     return(<div className="container">
         <h3><IoMdPerson /> Informacje o użytkowniku</h3>
         <div className="prinfo">
            <div className="row">
                <div className="col-sm-6">
                <h5>Informacje osobowe</h5>
                    <label>Imię</label>
                    <div className="myInput">{p.imie}</div>
                    <label>Nazwisko</label>
                    <div className="myInput">{p.nazwisko}</div>
                    <label>Mail</label>
                    <div className="myInput">{p.mail}</div>
                    <label>Telefon</label>
                    <div className="myInput">{p.telefon}</div>
                    <label>Adres</label>
                    <div className="myInput">{p.kod} {p.miejscowosc} <br></br> {p.ulica} {p.numer}</div>
                    <label>Pesel</label>
                    <div className="myInput">{p.pesel}</div>

                </div>
                <div className="col-sm-6">
                <h5>Zmiana hasła</h5>
                <label>Hasło</label>
                 <input type="password" className="myInput" />
                 <label>Nowe Hasło</label>
                 <input type="password" className="myInput" />
                 <label>Powtórz nowe hasło</label>
                 <input type="password" className="myInput" />
                 <br></br>
                 <br></br>
                 <button className="btn btn-secondary btn-sm" >Zmień</button>
                
                </div>
                <div className="col-sm-12">
                <h5>Wyrażone zgody</h5>
                {(p.zgoda1==="1") ? (<FaRegCheckSquare />) :(<FaRegSquare />)}<br></br>
                Zgoda na przetwarzanie danych osobowych dziecka - Wyrażam zgodę na przetwarzanie danych osobowych dziecka, którego jestem rodzicem (opiekunem) w celu zawarcia oraz realizowania umowy zawartej pomiędzy mną firmami: KM Consulting Marek Stępień prowadzącym jednoosobową działalność gospodarczą pod marką Centrum Edukacji Planeta Rozwoju, wykonywaną w Książenicach (05-825) ul. Młodości 2a lok.6, NIP:7731008563, REGON: 590378715, jak również w celu informowania mnie o postępach dziecka w nauce osoby. Równocześnie oświadczam, że jestem uprawniona(y) do wyrażenia niniejszej zgody. Oświadczam ponadto, że zapoznałam(em) się z klauzula informacyjną dotyczącą przetwarzania danych osobowych przez KM Consulting Marek Stępień.
                
                <br></br>{(p.zgoda2==="1") ? (<FaRegCheckSquare />) :(<FaRegSquare />)}<br></br>
                Zgoda na przesyłanie informacji handlowej i marketing bezpośredni - Oświadczam, że dobrowolnie wskazałem swój adres e-mail oraz numer telefonu w celu otrzymywania informacji handlowej od KM Consulting Marek Stępień prowadzącego jednoosobową działalność gospodarczą pod marką Centrum Edukacji Planeta Rozwoju, wykonywaną w Książenicach (05-825) ul. Młodości 2a lok.6, NIP:7731008563, REGON: 590378715. Równocześnie wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego przez w/w podmiot. Jestem świadomy, że udzielone zgody mogę wycofać w każdym czasie.
                <br></br>{(p.zgoda3==="1") ? (<FaRegCheckSquare />) :(<FaRegSquare />)}<br></br>
                Chcę być informowanym o rabatach, nowych kursach, zajęciach i chcę otrzymywać informacje handlowe związane z działalnością Centrum Edukacji Planeta Rozwoju. (opcjonalne)
                </div>
            </div>
         </div>
       

     </div>
     

     );
    }
 }