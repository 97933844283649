import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-bootstrap/Modal'

//react-big-calendar/lib/css/react-big-calendar.css
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
export default class Departmentcalendar extends React.Component
{
    constructor() {
        super();   

        this.state = {
          modalTitle:"Dodawanie",
          currentDepartament:0,
          currentYear:0,
          currentMonth:0,
          modalData:{
            id: 0,
            title: 'Long Event',
            allDay: false,
            start: new Date(2020, 0, 27, 12,0,0),
            end: new Date(2020, 0, 27, 13,0,0),
            property: "GLOBAL",
            iddepartaments: 1
          },
          calendarModal:false,
            myEventsList:[{
              id: 1,
              title: 'All Day Event very long title',
              allDay: false,
              start: new Date(),
              end: new Date(),
              property: "GLOBAL",
              iddepartaments: 1
            },
            {
              id: 2,
              title: 'Long Event',
              allDay: true,
              start: new Date(2020, 0, 27, 12,0,0),
              end: new Date(2020, 0, 27, 13,0,0),
              property: "GLOBAL",
              iddepartaments: 1
            },
          ]
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.createModalData = this.createModalData.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.saveCalendarData = this.saveCalendarData.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.deleteCalendarData = this.deleteCalendarData.bind(this);
        this.updateCalendar = this.updateCalendar.bind(this);
    } 
    componentDidMount()
    {
      var t = window.location.href.split('/');
      var d =new Date();
      this.props.ask("calendar", "getCurrentMonth", 
      JSON.stringify({departament:t[t.length-1], dataRange:d.getFullYear()+"-"+d.getMonth()+1}));
      this.setState({currentDepartament:parseInt(t[t.length-1]), currentYear:d.getFullYear(),
        currentMonth:d.getMonth()+1,})
    }
    updateCalendar(d)
    {
      var year = this.state.currentYear;
      var month = this.state.currentMonth;
      if (d==="PREV"){ }
    }
    deleteCalendarData()
    {
      this.props.ask("calendar", "deleteCalendarData", JSON.stringify({id:this.state.modalData.id, data:this.state.modalData.start, iddepartaments:this.state.modalData.iddepartaments}));
      this.hideModal();
    }
    handleSelect(ev)
    {
      console.log(ev);
    }
    hideModal(){this.setState({calendarModal:false})}
    editEvent(ev)
    {
      console.log("EV", ev.target.dataset.id, ev.target.value);
      var data = this.state.modalData;
      var day = data.start.toISOString().substr(0,10);
      switch (ev.target.dataset.id)
      {
        case 'start': data[ev.target.dataset.id] = new Date(day+"T"+ev.target.value);break;
        case 'end': data[ev.target.dataset.id] = new Date(day+"T"+ev.target.value);break;
        case 'allDay': data[ev.target.dataset.id] = (ev.target.value==="true");break;
        default: data[ev.target.dataset.id] = ev.target.value;
      }
     
      this.setState({modalData:data});
    }
    createModalData(ev)
    {
      console.log(ev);
      var modalData ={
        id: 0,
        title: '',
        allDay: false,
        start: ev.start,
        end: ev.end,
        property: "GLOBAL",
        iddepartaments: this.state.currentDepartament
      };
      this.setState({modalData:modalData, modalTitle:"Dodawanie", calendarModal:true})
    }
    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.props.calendarData.length; a++)
      {
        if (this.props.calendarData[a].id===ev.id){modalData=this.props.calendarData[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
    saveCalendarData()
    {
      this.props.ask("calendar", "saveCalendarData", JSON.stringify(this.state.modalData))
      this.hideModal();
    }
    render(){
        var props = this.props;
        const localizer = momentLocalizer(moment) // or globalizeLocalizer
        const messages = {
          allDay: 'Całodniowe',
          previous: 'Poprzedni',
          next: 'Następny',
          today: 'Dziś',
          month: 'Miesiąc',
          week: 'Tydzień',
          day: 'Dzień',
          agenda: 'Agenda',
          date: 'Data',
          time: 'Czas',
          event: 'Wydarzenie',
          noEventsInRange: "Brak wydarzeń w wybranym zakresie dat",
          showMore: total => `+ Więcej (${total})`
        };

return  (
  <div className="container">
  
    <Calendar
      onClick={event =>alert(event)}
      messages={messages}
      culture={"pl"}
      localizer={localizer}
      events={this.props.calendarData}
      startAccessor="start"
      endAccessor="end"
      selectable={true}
      onSelectEvent={event => this.editModalData(event)}
      onSelectSlot={(slotInfo) => this.createModalData(slotInfo)}
      onView={(view) => {
        console.log('#### view=', view);
        //this.setState({currentView: view});
      }}
      onNavigate={(focusDate, flipUnit, prevOrNext) => {
        this.updateCalendar(prevOrNext)
        console.log("what are the 3 params focusDate, flipUnit, prevOrNext ?", focusDate, flipUnit, prevOrNext);
      }}
      
    />
    <Modal
        show={this.state.calendarModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {this.state.modalTitle} wydarzenia {this.state.modalData.start.toLocaleDateString()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <input type="hidden" className="myInput" value={this.state.modalData.id} />
              <label>Tytuł wydarzenia </label>
              <input type="text" className="myInput" value={this.state.modalData.title} data-id="title" onChange={this.editEvent}/>
            </div>
            <div className="col-sm-6">
              <label>Godzina rozpoczęcia</label>
              <input type="time" className="myInput" value={this.state.modalData.start.toLocaleTimeString()} data-id='start' onChange={this.editEvent}/>
            </div>
            <div className="col-sm-6">
              <label>Godzina zakończenia</label>
              <input type="time" className="myInput" value={this.state.modalData.end.toLocaleTimeString()} data-id="end" onChange={this.editEvent}/>
            </div>
            <div className="col-sm-6">
              <label>Wydarzenie całodniowe</label>
              <select className="myInput" value={this.state.modalData.allDay} onChange={this.editEvent} data-id="allDay">
                <option value={false}>Nie</option>
                <option value={true}>Tak</option>   
              </select>
            </div>
            <div className="col-sm-6">
              <label>Właściciel</label>
              <select className="myInput" value={this.state.modalData.property} onChange={this.editEvent} data-id="property">
              
                <option value="admin">Administrator</option>
                <option value="oddzial">Oddział</option>
                <option value="plcacowka">Placówka</option>
                <option value="GLOBAL">Global</option>
              </select>
            </div>
            

          </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteCalendarData}>Usuń</button>
            <button className="btn btn-success" onClick={this.saveCalendarData}>Zapisz</button>
        </Modal.Footer>
      </Modal>
  </div>
)}
}
