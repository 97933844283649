import React from 'react';
import{Link} from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import CodesitWeek from './../common/codesitweek.js';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-bootstrap/Modal'
 export default class MainLector extends React.Component {
    constructor() {  
        super();   
    
        this.state = {dzienniki:[], loaded:false, ofset:0,calendarModal:false,modalData :{},
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.adjustOfset = this.adjustOfset.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }

    componentDidMount()
    {
        this.ask('dziennik', 'getDziennikByLector', null)
    }
    hideModal(){this.setState({calendarModal:false})}

    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
    adjustOfset(e)
    {
        if (e.target.dataset.id==="back"){this.setState({ofset:this.state.ofset-1}); }
        if (e.target.dataset.id==="forward"){this.setState({ofset:this.state.ofset+1})}
        console.log(e.target.dataset.id)
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getDziennikByLector':
                var data = response.data.data;
                for (var a = 0; a<data.length; a++)
                {
                    data[a].start = new Date(data[a].dzien + " " + data[a].godzina+":00");
                    var end = data[a].start.getTime() + parseInt(data[a].czas) *1000*60
                     data[a].end = new Date(end)
                     data[a].closing = new Date(data[a].closing)
                     data[a].title = data[a].nazwa;
                     if (data[a].temat!==""){ data[a].title = data[a].title +"(" +data[a].temat +")"}
                     var today = new Date();
                     
                     if (data[a].start>today){data[a].status = "nadchodzące"}
                     else if (today<data[a].closing){data[a].status = "aktywne"}
                     else {data[a].status = "zamknięte"}
                     console.log((today>data[a].start), (today>data[a].end), data[a].status)

                }
                    self.setState({ dzienniki:data, loaded:true});
                    
                    break;
                case 'getParentData':console.log(response.data.data);self.setState({ parents:response.data.data, loaded:true});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        var props = this.props;
        const localizer = momentLocalizer(moment) // or globalizeLocalizer
        const messages = {
          allDay: 'Całodniowe',
          previous: 'Poprzedni',
          next: 'Następny',
          today: 'Dziś',
          month: 'Miesiąc',
          week: 'Tydzień',
          day: 'Dzień',
          agenda: 'Agenda',
          date: 'Data',
          time: 'Czas',
          event: 'Wydarzenie',
          noEventsInRange: "Brak wydarzeń w wybranym zakresie dat",
          showMore: total => `+ Więcej (${total})`
        };

     return(<div className="container">
        <div className="row">
            
                <div className="col-sm-12">

                    <h5>Panel lektora  <span className="float-right">
                        {/* <button className="btn btn-secondary btn-sm" data-id="back"  onClick={this.adjustOfset}>(-)  &larr;</button>&nbsp;
                        <button className="btn btn-secondary btn-sm" data-id="forward" onClick={this.adjustOfset}>  &rarr;</button> */}
                    </span></h5>
                    <div className="prinfo">

                    <Calendar
                        onClick={event =>alert(event)}
                        messages={messages}
                        culture={"pl"}
                        localizer={localizer}
                        eventPropGetter={event => ({className: 'category-' + event.status.toLowerCase()})}
                        events={this.state.dzienniki}
                        startAccessor="start"
                        endAccessor="end"
                        selectable={true}
                        onSelectEvent={event => this.editModalData(event)}
                        //onSelectSlot={(slotInfo) => this.createModalData(slotInfo)}
                        onView={(view) => {
                            console.log('#### view=', view);
                            //this.setState({currentView: view});
                        }}
                        onNavigate={(focusDate, flipUnit, prevOrNext) => {
                            console.log("what are the 3 params focusDate, flipUnit, prevOrNext ?", focusDate, flipUnit, prevOrNext);
                        }}
                    />
              
                </div> 
            </div>
        </div>
        <Modal
        show={this.state.calendarModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Dane lekcji {this.state.modalData.nazwa}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12 codesit column">
                    <div className="first">Temat</div>
                    <div className="second">{(this.state.modalData.temat==="") ? "Nie wprowadzono tematu" : this.state.modalData.temat}</div>
                    <div className="first">Wpisy o obecności</div>
                    <div className="second">{(this.state.modalData.obecnosc==="[]") ? "Nie sprawdzono oecności" : "Obecność sprawdzono"}</div>
                    <div className="first">Wyniki</div>
                    <div className="second">{(this.state.modalData.wyniki==="[]") ? "Nie wprowadzono wyników" : "Wyniki wprowadzono"}</div>
                    <div className="first">Status</div>
                    <div className="second">{this.state.modalData.status}</div>
                </div>   
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <Link className="btn btn-success" to={"/mail/" + this.state.modalData.id} onClick={this.saveCalendarData}>Napisz mail do grupy</Link>
            <Link className="btn btn-success" to={"/lection/" + this.state.modalData.id} onClick={this.saveCalendarData}>Przejdź</Link>
        </Modal.Footer>
      </Modal>
     </div>)
    }
    
 }