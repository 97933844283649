import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import {TiShoppingCart } from "react-icons/ti";
import jsPDF from 'jspdf';
import font from './../../fonts/font'
import CodesitFile from './../../components/common/codesitform/codesitfile'


 export default class ShopProductDetails extends React.Component {
    constructor() {  
        super();   
    
        this.state = {kurs:{days:[],}, loaded:false,dzieci:[], dla:0,childName:"",
        rodzic:{nazwisko:"", adres:"", pesel:"", kdr:"", raty:""}, isPesel:false,
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getRegulamin = this.getRegulamin.bind(this);
        this.getTerminarz = this.getTerminarz.bind(this);
        this.getProjekt = this.getProjekt.bind(this);
        this.wybierzDziecko = this.wybierzDziecko.bind(this);
        this.payOnline = this.payOnline.bind(this);
        this.getBlik = this.getBlik.bind(this);
    }
    getBlik(ev){
        var blik = ev.target.value.toString().replace(/\D/,'');
        console.log(blik)
        this.setState({blik:blik})
        if (blik.length === 6){ev.target.classList.remove("incorrect");}else{ev.target.classList.add("incorrect");}
    }
    payOnline()
    {
        var data = {child:this.state.dla, courreId:"12", blik:this.state.blik}
        this.ask('payment', 'pay', JSON.stringify(data))
    }
    getDate()
    {
        var oggi = new Date()
        return "Data wygenerowania: " + oggi.toLocaleDateString() + " " + oggi.toLocaleTimeString()
    
    }
    zamowienie = (ev) =>{
        let name = ev.target.name;
        let value = ev.starget.value;
    }
    wybierzDziecko(ev) {
        var dz = this.state.dzieci;
        var childName = "";
        for (var a = 0; a<dz.length;a++)
        {
            console.log(Number(dz[a].id), Number(ev.target.value), (dz[a].id === Number(ev.target.value)))
            if (Number(dz[a].id) === Number(ev.target.value)){console.log(ev.target.value); 
                childName=dz[a].imie+' '+dz[a].nazwisko }
        }
        console.log(ev.target.value, childName);

        this.setState({dla:ev.target.value, childName:childName});}
    getRegulamin()
    {
        // global.window = {document: {createElementNS: () => {return {}} }};
        // global.navigator = {};
        // global.btoa = () => {};
        var doc = new jsPDF({
            unit: "pt",
            orientation: "p",
            lineHeight: 1.2
          })
        doc.setProperties({
            title: 'Regulamin',
            subject: 'Regulamin szkoły językowej',		
            author: 'Planeta Rozwoju',
            keywords: 'Regulamin, Planeta Rozwoju, Nauka Języków',
            creator: 'MEEE'
        });//"WinAnsiEncoding"
        var html = 'Początkowym i jednym z najistotniejszych elementów umowy jest jej tytuł, zatem ważne jest zastosowanie odpowiadających ekwiwalentów; w języku polskim najczęściej używanym określeniem jest „umowa”, podczas gdy w języku angielskim wyróżnia się m.in. contract („umowa”) oraz agreement („porozumienie”), które może być używane w szerszym kontekście. Według Jopek-Bosiackiej, „kontrakt” ma swoiste asocjacje, zatem słowo to nie powinno być używane w kontekście umów cywilnoprawnych. W preambule mieszczą się informacje takie jak data zawarcia umowy czy określenie jej stron, co więcej, szczególnie w przekładzie ważne jest precyzyjne określenie stron umowy. W przypadku gdy stroną umowy jest osoba prawna należy zachować należytą ostrożność przy tłumaczeniu firmy spółki (nazwy pod którą jest prowadzona), a w szczególności jej formy prawnej. Forma prawna określa typ spółki (np. Sp. z.o.o.), jednak w przypadku przekładu nie należy tłumaczyć formy prawnej na jej odpowiednik w innym języku, gdyż oryginalna forma prawna wskazuje na państwo w jakim spółka została utworzona. Aby zapobiec niejasnościom można dodatkowo w tekście umieścić wyjaśnienie skrótu formy prawnej. Reguły te dotyczą tłumaczeń zarówno z języka polskiego na obcy, jak i z języka obcego na język polski. Również nazwy storn mają stosowne formalne odpowiedniki, które zawarte są w kodeksie cywilnym.';
        var ff = font;
        (function (jsPDFAPI) { var font = ff;
           var callAddFont = function () {
             this.addFileToVFS('Roboto-regular.tff', font);
             this.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
             console.log("FONT ADDED")
           };
           jsPDFAPI.events.push(['addFonts', callAddFont])
           })(jsPDF.API);
        console.log("MAking")
        var doc = new jsPDF({
          //orientation: 'landscape',
        })
        //doc.addFont('Roboto-Regular');
        doc.setFont('Roboto-Regular')
        //doc.text('Hello world! źółć', 10, 100)
        //doc.addHTML(html)
        
        doc.setHeaderFunction(10,true);        
        var splitTitle = doc.splitTextToSize(html , 180);
        doc.text(splitTitle, 10,10)
    
        doc.addPage()
        doc.text(splitTitle, 10,10)
    
        doc.save('Regulamin.pdf')
    } 
    getTerminarz()
    {
        var kurs = this.state.kurs;
        console.log(kurs)
        var doc = new jsPDF({
            unit: "mm",
            orientation: "p",
            lineHeight: 1.2,
            format: 'a4',
            //putOnlyUsedFonts:true,
          })
        doc.setProperties({
            title: 'Regulamin',
            subject: 'Regulamin szkoły językowej',		
            author: 'Planeta Rozwoju',
            keywords: 'Regulamin, Planeta Rozwoju, Nauka Języków',
            creator: 'MEEE'
        });//"WinAnsiEncoding"
        var  ff = font;
        (async function  (jsPDFAPI) 
        { 
            "use strict";
            var  font = await ff;
            console.log(font.length)
           var callAddFont = function () {
             this.addFileToVFS('Roboto-regular.tff', font);
             this.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
             console.log("FONT ADDED")
           };
           jsPDFAPI.events.push(['addFonts', callAddFont])
           })(jsPDF.API);
        //console.log("MAking")
        do {
            doc.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
           
        } while(typeof doc.getFontList()['Roboto-Regular'] ==="undefined")
        doc.setFont('Roboto-Regular');
        
        console.log(doc.getFontList(), typeof doc.getFontList()['Roboto-Regular'])
        doc.setFontSize(8)
        doc.text(this.getDate(), 136, 10);
        doc.setFontSize(16)
           
        doc.setTextColor(0,0,0) 
        
        doc.text("Harmonogram kursu: " + kurs.name.toString('utf-8'), 20, 20);
        
        doc.line(20, 23, 190, 23, "F")
        doc.setFontSize(10)
        doc.text("Miejsce realizacji kursu: " + kurs.nazwa.toString('utf-8'), 20, 30)
        doc.text("Prowadzący: " + kurs.lektor.toString('utf-8'), 20, 35)
        doc.text("Data rozpoczęcia i zakończenia: " + kurs.start.toString('utf-8') + " "+ kurs.end.toString('utf-8'), 20, 40);
        doc.text("czas trwania zajęć: " + kurs.czas.toString('utf-8') +" minut", 20, 45)
        if (typeof kurs.harmonogram==="undefined"){doc.text("Nie wiem o co chodzi", 20, 50)}
        else
        {
            doc.text("LP", 20, 60)
            doc.text("data", 40, 60)
            doc.text("godzina", 60, 60)
            doc.text("Uwagi", 80, 60)
            for (var a = 0; a < kurs.harmonogram.length; a++ )
        {
            var text = kurs.harmonogram[a].data;
            console.log(text);
            doc.text(kurs.harmonogram[a].count.toString(), 20, 65+a*5)
            doc.text(text, 40, 65+a*5)
            doc.text(kurs.harmonogram[a].hour.toString(), 60, 65+a*5)
            doc.text(kurs.harmonogram[a].desc.toString(), 80, 65+a*5)
        }}
        doc.save('Harmonogram.pdf')
    } 
    getProjekt()
    {
        this.ask('administration', 'getDocumentPart', 'umowa.cd')
    }
    produceProjekt = (text) =>
    {
        var today = new Date();
        var rat = this.calculateMesses();
        const { rodzic, childName, kurs } = this.state;

        var pricemethod = ["(nie wybrano)",
        "w całości w kwocie " + parseFloat(kurs.koszt * (kurs.last - kurs.ilosc)).toFixed(2)+"zł",
        "w dwóch równych ratach na początku kursu i na początku drugiego semestru w kwocie " + parseFloat((kurs.koszt * (kurs.last - kurs.ilosc))/2).toFixed(2)+"zł"   ,
        "w "+rat+". równych ratach płatnych zgodnie z harmonogramem wpłat w kwocie " + parseFloat((kurs.koszt * (kurs.last - kurs.ilosc))/rat).toFixed(2)+"zł"   
    ];

        text = text.replace('{%NAME%}', rodzic.nazwisko);
        text = text.replace('{%ADDRESS%}', rodzic.adres);
        text = text.replace('{%PESEL%}', rodzic.pesel);
        text = text.replace('{%CHILD%}', childName);
        text = text.replace('{%COURSE%}', kurs.name);
        text = text.replace('{%DATE%}', today.toLocaleDateString());
        text = text.replace('{%FROM%}', kurs.start );
        text = text.replace('{%TO%}', kurs.end);
        text = text.replace('{%LESSONPRICE%}', kurs.koszt);
        text = text.replace('{%LESSONTIME%}', kurs.czas);
        text = text.replace('{%PRICEMETHOD%}', pricemethod[Number(rodzic.raty)]);

        var doc = new jsPDF({
            unit: "mm",
            orientation: "p",
            lineHeight: 1.2,
            format: 'a4',
            //putOnlyUsedFonts:true,
          })
        doc.setProperties({
            title: 'Umowa',
            subject: 'Umowa ze szkołą językową',		
            author: 'Planeta Rozwoju',
            keywords: 'Umowa, Planeta Rozwoju, Nauka Języków',
            creator: 'MEEE'
        });//"WinAnsiEncoding"
        var  ff = font;
        (async function  (jsPDFAPI) 
        { 
            "use strict";
            var  font = await ff;
            console.log(font.length)
           var callAddFont = function () {
             this.addFileToVFS('Roboto-regular.tff', font);
             this.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
             console.log("FONT ADDED")
           };
           jsPDFAPI.events.push(['addFonts', callAddFont])
           })(jsPDF.API);
        //console.log("MAking")
        do {
            doc.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
           
        } while(typeof doc.getFontList()['Roboto-Regular'] ==="undefined")
        doc.setFont('Roboto-Regular');
        var splitTitle = doc.splitTextToSize(text , 250);
        doc.setFontSize("11");
        var page = 1;
        var y = 20;
        for (var i = 0; i < splitTitle.length; i++) {                
            if (y > 280) {
                y = 25;
                
                doc.addPage();
                page++;
                doc.text(page.toString() , 25, 17);
                doc.line(25, 18, 190, 18, "F")
            }
            doc.text(25, y, splitTitle[i]);
            y = y + 5;
        }
        //doc.text(splitTitle, 10,10)
        //doc.text(text.toString('utf-8'))
        doc.save('Umowa.pdf');
        this.addToPayment();
    }
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        this.ask('administration', 'getGroupDetails', id)
        this.ask("user","getParentChldren", null);
        this.ask("user","getParentData", null);
    }
    hideModal(){this.setState({calendarModal:false})}


    addToPayment = () => {
        let self = this;
        if (this.state.dla===0){
            window.toast.error('Należy wybrać uczestnika'); return;
        }
        
    }
    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
    isValidPesel(pesel) {
        if(typeof pesel !== 'string')
            return false;
    
        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(pesel.substring(10, 11));
    
        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        return (10 - sum) % 10 === controlNumber;
    }

    modyfikacjaPodpisu = (ev) =>{
        var value = ev.target.value;
        var name = ev.target.name;
        var rodzic = this.state.rodzic;
        rodzic[name] = value;
        rodzic.pesel = String(Number(rodzic.pesel));
        if (rodzic.pesel=="NaN" || rodzic.pesel==="0"){rodzic.pesel="";}
        rodzic.pesel = rodzic.pesel.substring(0,11);
        rodzic.kdr = rodzic.kdr.substring(0,17);
        //console.log(rodzic.pesel)
        if (name==="pesel")
        {
           
            if (!this.isValidPesel(rodzic.pesel))
            {
                ev.target.classList.add('incorrect')
                this.setState({isPesel:false})
            }
            else
            {
                ev.target.classList.remove('incorrect') 
                this.setState({isPesel:true})
            }
        }
        if (name==="kdr")
        {
            if (rodzic.kdr.length===17) {ev.target.classList.remove('incorrect') } else {ev.target.classList.add('incorrect') }
        }
        this.setState({rodzic})
    }
    saveFile =(file) =>{
        var self = this;
        var fileToSend = file;
        var rat = this.calculateMesses();
        const {kurs, rodzic, childName } = this.state;
        var pricemethod = ["", parseFloat(kurs.koszt * (kurs.last - kurs.ilosc)),
        parseFloat((kurs.koszt * (kurs.last - kurs.ilosc))/2)   ,
        parseFloat((kurs.koszt * (kurs.last - kurs.ilosc))/rat)   
    ];
        var doZaplaty = {
            student:this.state.dla,
            kurs: kurs.id,
            rat:rat,
            sposob: rodzic.raty,
            cena:pricemethod[Number(rodzic.raty)],
            konto:kurs.konto,
            kontoid:kurs.accountId,
            dziecko: childName,
            nazwakursu: kurs.name
        };
        window.confirmAlert({
            title: 'Informacja',
            message: "Dokumenty zostały złożone w komplecie. Czy chcesz utworzyć harmonogram wpłat?",
            input: 'fsdkd',
            buttons: [
              {
                label: 'Utwórz',
                className:"btn btn-success",
                onClick: () => {self.ask('user', 'saveFile', JSON.stringify({fileToSend:fileToSend, kurs:doZaplaty}))}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
        
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGroups':
                
                    self.setState({ kursy:response.data.data, loaded:true});
                    
                    break;
                case 'getGroupDetails':
                    var data = response.data.data;
                    if (data===false){return;}
                    data = data[0];
                    data.days=JSON.parse(data.days);
                    data.harmonogram = JSON.parse(data.harmonogram);
                    data.data = JSON.parse(data.data);
                    if (data.data===null){data.data=[]}
                    data.selected = JSON.parse(data.selected);
                    var dni = 0;
                    var now = new Date();
                    var last = 0
                    for (var a = 0; a<data.harmonogram.length;a++)
                    {
                        var dt = data.harmonogram[a].data;
                        if (dt.length<10){dt = "0" +dt;}
                        dt = dt.split('.');
                        dt = dt[2]+"-"+dt[1]+"-"+dt[0];
                        var ddt = new Date(dt);
                        if (ddt>now){dni++}
                        last = data.harmonogram[a].count;
                    }
                    for (a=0;a<data.data.body.length;a++)
                    {
                       // console.log(data.data.body[a], data.selected.length+1)
                        if (parseFloat(data.data.body[a][0])===data.selected.length+1){data.koszt =parseFloat(data.data.body[a][1])}
                    }
                    data.ilosc = dni;
                    data.last = last;
                    data.percent = (1-(dni/parseInt(last)))


                    //console.log("XXX",data);

                self.setState({ kurs:data, loaded:true});break;

                case 'getParentChldren':self.setState({dzieci:response.data.data});break;
                case 'getDocumentPart':
                    self.produceProjekt(response.data.data);
                
                self.setState({doc:response.data.data});break;
                case 'getParentData':
                        var rodzic = self.state.rodzic;
                        rodzic.nazwisko = response.data.data.name;
                        self.setState({rodzic})
                        break

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }
    calculateMesses = () =>{
        if (typeof this.state.kurs.end !== 'undefined')
        {
            var end = new Date(this.state.kurs.end);
            var Today = new Date();
            var diff = Math.round((end - Today)/1000/3600/24/30);
            return diff;
        }
        else
        {
            return 1;
        }
    }
    render() {
        var d = this.state.kurs;
        var tydzien = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
        var rat = this.calculateMesses();

     return(<div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3><TiShoppingCart /> Szczegóły kursu "{d.name}"
                            {/* <span className="float-right">
                            <Link to={"/harmonogram/view/" + this.state.dzienniki[0].kursid } className="btn btn-secondary btn-sm">Harmonogram</Link>&nbsp;
                                <Link to={"/programs/view/" + this.state.dzienniki[0].program } className="btn btn-secondary btn-sm">Program</Link>
                            </span> */}
                        </h3>
                        <div className="prinfo">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div>Miejsce realizacji:<span className="float-right">{d.nazwa}</span></div>
                                    <div>Dzień rozpoczęcia kursu:<span className="float-right">{d.start}</span></div>
                                    <div>Dzień zakończenia kursu:<span className="float-right">{d.end}</span></div>
                                    <div>Dni w których odbywają się zajęcia:</div>
                                    {
                                        d.days.map((key, i)=>(<>{(key==="") ? "" : (<div key={i}>{tydzien[i]}: {key}</div>)}</>))
                                    }
                                    <div>Na chwilę obecną zrealizowano {d.ilosc} z {d.last} ({(parseInt(d.percent*100))}%)</div>
                                    <div>Koszt zajęć (1 lekcja): <span className="float-right">{parseFloat(d.koszt).toFixed(2)} zł</span></div>
                                    <div>Łączny koszt podstawowy pozostałej części kursu: <span className="float-right">{parseFloat(d.koszt * (d.last - d.ilosc)).toFixed(2)} zł</span></div>
                                    {this.state.dzieci.length>2 && this.state.rodzic.kdr.length === 17 && (<div>Udzelony rabat za KDR<span className="float-right">5%</span></div>)}
                                    <div className="cTip">
                                        <small>
                                            Wypełnij pola poniżej a plik PDF z umową zmieni nazwę z "Projekt umowy" na "Podpisana umowa". Kliknij, aby ściągnąć umowę, przeczytaj ją, czy wszystkie dane się zgadzają. Jednocześnie wyświetli się przycisk "Dodaj podpisaną umowę". Po przeczytaniu umowy załączenie jej spowoduje wygenerowanie harmonogramu płatności zgodnie z Twoim życzeniem.
                                        </small></div>
                                    <label>Wybierz dziecko</label>
                                    <select className="form-control" value={this.state.dla} onChange={this.wybierzDziecko}>
                                        <option value="0">-- wybierz --</option>
                                        {this.state.dzieci.map((key)=>(<option key={key.id} data-name={key.imie+''+key.nazwisko} value={key.id}>{key.imie} {key.nazwisko}</option>))}
                                    </select>
                                    <div>
                                        <label>Imię i nazwisko (jak w dokumentach)</label>.
                                        <input className="myInput" name="nazwisko" value={this.state.rodzic.nazwisko} onChange={this.modyfikacjaPodpisu} />
                                        <label>Adres zamieszkania</label>  
                                        <input className="myInput" name="adres" value={this.state.rodzic.adres} onChange={this.modyfikacjaPodpisu} />
                                        <label>Numer PESEL</label>
                                        <input className="myInput" name="pesel" value={this.state.rodzic.pesel} onChange={this.modyfikacjaPodpisu} />
                                        {this.state.dzieci.length>2 &&(<><label>Numer Karty Dużej Rodziny ({this.state.dzieci.count})</label>
                                        <input className="myInput" name="kdr" value={this.state.rodzic.kdr} onChange={this.modyfikacjaPodpisu} /></>)}
                                        <label>Wybierz harmonogram płatności</label>
                                        <select className="form-control" name="raty" value={this.state.rodzic.raty} onChange={this.modyfikacjaPodpisu} >
                                            <option value="0">-- wybierz --</option>
                                            <option value="1">Cały kurs (1 rata: {parseFloat(d.koszt * (d.last - d.ilosc)).toFixed(2)})</option>
                                            <option value="2">Co semestr (2 raty: {parseFloat((d.koszt * (d.last - d.ilosc)/2)).toFixed(2)})</option>
                                            <option value="3">Co miesiąc ( {rat} rat: {parseFloat((d.koszt * (d.last - d.ilosc))/2).toFixed(2)/rat})</option>
                                        </select>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div>{(this.state.isPesel && this.state.dla!==0 && this.state.rodzic.nazwisko.length>2 && this.state.rodzic.adres.length>7 && this.state.rodzic.raty!=="0") && (<CodesitFile
                                    id={"plik"}
                                    label={'Dodaj podpisaną umowę'}
                                    labelClass="btn btn-primary btn-sm"
                                    onLoad={this.saveFile}
                                    />)}</div>
                                    
                                </div>
                                <div className="col-sm-6">
                                   
                                    <div>
                                    <div className="card">
                                        <div className="card-header"><h5>Dokumenty </h5></div>
                                        <div className="card-body">
                                        <div className="cTip">
                                        <small >Wprowadź dane do zawieranej umowy. Przez złożenie podpisu rozumie się wpisanie prawidłowego numeru PESEL. I dostarczenie jej do naszej siedziby (osobiście, mailem, przez system teleinformatyczny). Nie jest on przechowywany w bazie danych, ani nie będzie do niej automatycznie dołączony.</small>

                                        </div>

                                        <br></br><br></br>
                                            <div className="shop document" onClick={this.getRegulamin}>
                                            <i className="far fa-file-pdf"></i>
                                            <div>Regulamin</div></div>
                                            <div className="shop document" onClick={this.getTerminarz}>
                                            <i className="far fa-file-pdf"></i>
                                            <div>Harmonogram</div></div>
                                            <div className="shop document" onClick={this.getProjekt}>
                                            <i className="far fa-file-pdf"></i>
                                            <div>{(this.state.isPesel && this.state.dla!==0 && this.state.rodzic.nazwisko.length>2 && this.state.rodzic.adres.length>7 && this.state.rodzic.raty!=="0") ? 'Podpisana umowa' : 'Projekt umowy'}</div></div>
                                        </div>
                                        <div className="card-footer">
                                            <small>Zachęcamy do przeczytania dokumentów i zapoznania się z warunkamiumowy przed zakupem</small>
                                        </div>
                                        
                                        
                                    </div>
                                    <div>
                                    
                                    <br></br>
                                    {/* <button className="btn btn-success btn-bg" onClick={this.addToPayment}>Zamów</button>&nbsp; */}
                                   
                                    </div>
                                    

                                    </div>
                                    <br></br>
                                    
                                </div>
                               
                            </div>
                        </div>   
                    </div> 
                </div>
     </div>)
    }
    
 }