import React , { useState } from 'react';
import codefilter from './codesitfilter.js';
import validatorWorker from './codesitvalidators.js';

function CodesitForm(props) {
    
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [isValid, SetValid] = useState(false);



    const blurFocus = (ev) => {
        const { prettyNumbers, filter, name} = props;
        if (prettyNumbers) {return ev.target.value = ev.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");}
        if (filter==="iban") {return ev.target.value = ev.target.value.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ");}
        var validatorPlace = document.getElementById('codesitValidatorPlace' + name);
        if (validatorPlace){validatorPlace.classList.remove('active')}
    }
    const getFocus = (ev) =>{
        const { prettyNumbers , filter, name} = props;
        if (prettyNumbers || filter==="iban") {ev.target.value = ev.target.value.split(" ").join('')}
        var validatorPlace = document.getElementById('codesitValidatorPlace' + name);
        if (validatorPlace){validatorPlace.classList.add('active')}
    };
    
    const writeForm = (ev) => {
        const { form, filter, strength, max, validator, required, corelator, onChange} = props;
        var value = ev.target.value;
        switch (filter)
        {
            case 'number': value = codefilter.number(value); break;
            case 'float': value = codefilter.number(value); break;
            case 'currency': value = codefilter.currency(value); break;
            case 'zip': value = codefilter.zip(value); break;
            case 'iban': value = codefilter.iban(value); break;
            case 'nip': value = codefilter.nip(value); break;
            default:break;
        }
        //console.log(ev, form, ev.target.name);
        switch (validator)
        {
            case 'iban': SetValid(validatorWorker.iban(value));  break;
            case 'zip': SetValid(validatorWorker.zip(value));  break;
            case 'mail': SetValid(validatorWorker.mail(value));  break;
            case 'pesel': SetValid(validatorWorker.pesel(value));  break;
            default:break;
        }
        if (corelator)
        {

            SetValid(value.length>0 && value === form[corelator])
        }
        if (parseInt(max)>0){value = value.substring(0,parseInt(max))}
        form[ev.target.name] = value;
        document.getElementById(ev.target.name).value=value;
        if (strength){
            setPasswordStrength(validatorWorker.passStrength(value))
        }
        onChange({form})
    }


    const {labelClassName, label, inputClassName, messaageClassName, inputType, message, name, style, form, placeholder, icon, 
        textIcon, strength, required, validator, validationPositiveMessage, validationNegativeMessage, hint, tooltip, tooltipText, showValidation} = props;
    
        var color = "#FF0000";

        // if ( passwordStrength>50){color="orange"}
        // if ( passwordStrength>80){color="green"}

    return (
        
      <div className="codesitForm">
            {label && Number(label.length)>0 && (<label className={labelClassName}>{label} {required ? (<span style={{color:'red'}}>*</span>):''} 
            {tooltip? (<div className="tooltip">&nbsp;?&nbsp;
  <span className="tooltiptext">{tooltipText}</span>
</div>):""}</label>)}
            <div style={{position:"relative"}}>
                <div className="currencyIcon">{textIcon}</div>
                <input 
                    name={name} 
                    id={name}  
                    style={style} 
                    type={inputType ? inputType :"text"} 
                    value={form[name]} 
                    onChange={writeForm} 
                    onFocus={getFocus}
                    onBlur={blurFocus}
                    className={inputClassName ? inputClassName + " " +  icon:"codesitInput" + " " +  icon} 
                    placeholder={placeholder}
                />
            </div>
            {strength ? (<div className="paswordStrengthContainer">
                <div className="PasswordStrength" style={{backgroundColor : color, height:"5px",width:passwordStrength+'%'}}>
                    </div></div>):""}

    {showValidation ? (<div className="validatorPlace" id={"codesitValidatorPlace" + name}>
        {isValid ? (<span style={{color:"green"}}>{validationPositiveMessage}</span>):
        (<span style={{color:"red"}}>{validationNegativeMessage}</span>)}</div>):""}

            <div className={messaageClassName ? messaageClassName:"smallMessage"}>{message}</div>
      </div>
    );
  }

  export default CodesitForm