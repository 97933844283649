import React from 'react';
import Loader from 'react-loader-spinner';
 export default class App extends React.Component {
  //other logic
    render() {
     return(
         <div className="loader">      <Loader
         type="Watch"
         color="#4287f5"
         height={100}
         width={100}
         timeout={10000} //3 secs
        
      /></div>

     );
    }
 }