const filter = {
    number : function(val){
        var response = val.replace(/[^0-9]+/g, '')
        return  (response>0)? response:""
    },
    float : function(val){
        var response = parseFloat(val)
        return  (response>0)? response:""
    },
    currency:function(val){
        var temp = val.split(",");
        var response = temp[0].replace(/[^0-9]+/g, '');
        if (val.indexOf(",")>0){ response += ","+temp[1].replace(/[^0-9]+/g, '').substring(0,2);}
        if (isNaN( parseFloat(response))) return "";
        return  response;
    },
    zip:function(val){
        var response = val.replace(/[^0-9-]+/g, '').split('');
        if (response.length===3){
            var temp = response[3]
            response[2] ="-";
            response.push(temp)}
            if (response.length>3){response[2]="-"}

        return response.join('').substring(0,6);
        
    },
    nip:function(val){return val},
    iban:function(val){
        var coutnryCode = val.substring(0,2).toUpperCase().replace(/[^A-Z]+/g, '')
        var numbersCode = val.substring(2,val.length).replace(/[^0-9]+/g, '');
        if (numbersCode.length>26){numbersCode = numbersCode.substring(0,26)}
        return coutnryCode + numbersCode;
    }
}

export default filter;