import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';

export default class employersSettlement extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",
            generalList:[],cennik:[],lector:[], client:[], shool:[], client:[], getShoolData:[], getLectorData:[], getParentData:[]
        };
        this.hideModal = this.hideModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.NewCennik = this.NewCennik.bind(this);
        this.showAddKursModal = this.showAddKursModal.bind(this);
        this.getData = this.getData.bind(this);
        

    } 
    getData()
    {
        var miesiac = document.getElementById('miesiac').value;
        var rok = document.getElementById('rok').value
        var data = JSON.stringify({miesiac:miesiac, rok:rok});
        
        this.ask('administration', 'getShoolData', data)
        this.ask('administration', 'getLectorData', data)
        this.ask('administration', 'getParentData', data)
    }
    showAddKursModal(){this.setState({deleteModal:true});}
    NewCennik()
    {
        //this.ask('administration', 'createCennik', null)
    }
    componentDidMount()
    {
        // this.ask('administration', "getGeneralCourseList", null)
        // this.ask('administration', "getCennikList", null)
    }
    ChangePanel(ev)
    {
        console.log(ev.target);
        ev.target.class.add("open");

    }
    getInfo(ev)
    {

        console.log("PYTANIE",ev.target.dataset)
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getShoolData':self.setState({shool:response.data.data});break;
                case 'getLectorData':self.setState({lector:response.data.data});break;
                case 'getParentData':self.setState({client:response.data.data});break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Rozliczenia </h3>
                </div>    
            </div>
            
                <div> 
               <div className="row"> 
                        <div className="col-sm-3">
                    <label>Miesiąc</label>
                    <select className="form-control"  id="miesiac">
                        <option value="0">-- Wybierz --</option>
                        <option value="01">Styczeń</option>
                        <option value="02">Luty</option>
                        <option value="03">Marzec</option>
                        <option value="04">Kwiecień</option>
                        <option value="05">Maj</option>
                        <option value="06">Czerwiec</option>
                        <option value="07">Lipiec</option>
                        <option value="08">Sierpień</option>
                        <option value="09">Wrzesień</option>
                        <option value="10">Październik</option>
                        <option value="11">Listopad</option>
                        <option value="12">Grudzień</option>
                       

                    </select>
                    </div>

                    <div className="col-sm-3">
                    <label>Rok</label>
                    <select className="form-control"  id="rok">
                        <option value="0">-- Wybierz --</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>

                    </select>
                    </div>

                    <div className="col-sm-3">
                    <br></br>
                    <button className="btn btn-secondary" onClick={this.getData}>Pobierz</button>
                    </div>
                    </div> 

                    <div className="row">
                    
                    <div className="col-sm-12">
                    <h5>Należności lektorów</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Lektor</td>
                                <td>Kurs</td>
                                <td>data</td>
                                <td>kwota</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.lector.map((key)=>(<tr key={key.id}>
                            <td>{key.prowadzacy}</td>
                            <td>{key.nazwa}</td>
                            <td>{key.dzien}</td>
                            <td>{key.naleznosc}</td>
                            </tr>))}
                        </tbody>
                    </table>
                    </div>


                    <div className="col-sm-12">
                    <h5>Należności placówek</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Tytuł płatności</td>
                                <td>Kwota</td>
                                <td>data</td>
                                <td>kwota</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.shool.map((key)=>(<tr key={key.id}>
                            <td>{key.szkola}</td>
                            <td>{key.nazwa}</td>
                            <td>{key.dzien}</td>
                            <td>{key.kosztsali}</td>
                            </tr>))}
                        </tbody>
                    </table>
                    </div>
                    
                    
                    
                    </div>
                


                 <div className="col-sm-12">
                    <h5>Należności od Klientów</h5>
                    <table className="table">
                        <thead>
                            <tr>
                            <td>Użytkownik</td>
                                <td>Status</td>
                                <td>Kwota</td>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.client.map((key)=>(<tr key={key.id}>
                            <td>{key.name}</td>
                            <td>{key.status}</td>
                            <td>{key.kwota}</td>
                            </tr>))}
                        </tbody>
                    </table>
                    </div>
                    
                    
                    
                    </div>
                 </div>



         

        

     );
    }
 }

