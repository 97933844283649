import React from 'react';
import {FaTrashAlt, FaEdit, FaLink, FaCalendar} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
import finder from './../../functions/finddataset.js';
import {Link} from "react-router-dom";
export default class Departments extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"asd",
        };
        this.hideModal = this.hideModal.bind(this);
        this.prepareDelete = this.prepareDelete.bind(this);
        this.deleteDepartments = this.deleteDepartments.bind(this);
    } 
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    deleteDepartments()
    {
        this.props.ask("departments", "deleteDepartament", JSON.stringify({depId:this.state.prepareDelete}));
        this.hideModal();
    }
    renderLink(ev)
    {
        var link = "/departments/" + ev.target.dataset.id;
        console.log("LINK", link)
        return FaLink;
    }
    prepareDelete(ev)
    {
        var find = finder.find(ev, "name")
        console.log(find)
        console.log(ev.target.dataset)
        this.setState({prepareDelete: ev.target.dataset.id, deleteModal:true, prepareName:ev.target.dataset.name})
    }
    render() {
        
     return(
         <div className="container">
         <div>
         <h3>Oddziały
         <span className="float-right"><Link className="btn btn-secondary" to="/departments/new">Dodaj</Link></span>
         </h3>
         </div>
        <br></br>
         <div className="table-responsive">  
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Nazwa</td>
                        <td>Adres</td>
                        <td>Telefon</td>
                        <td>Manager</td>
                        <td>Opcje</td>
                    </tr>
                </thead>
                <tbody>
                    {this.props.departments.map((key)=>(
                        <tr key={key.id}>
                            <td>{key.id}</td>
                            <td>{key.nazwa}</td>
                            <td> {key.ulica} {key.nr}, {key.kod} {key.miejscowosc}</td>
                            <td>{key.telefon}</td>
                            <td>{key.name}</td>
                            <td> 
                            <Link className="btn  btn-sm" style={{color:"#333"}}  to={"/departments/edit/" + key.id}> <FaEdit />Edycja</Link>
                            <Link className="btn  btn-sm" style={{color:"#220073"}}  to={"/departments/calendar/" + key.id}> <FaCalendar /> Kalendarz</Link>

                                <button className="btn btn-sm" data-name={key.nazwa} data-id={key.id} onClick={this.prepareDelete}><FaTrashAlt onClick={this.prepareDelete} className="red"/> Usuń</button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
         </div>



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kasowanie oddziału "{this.state.prepareName}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ta operacja jest nieodwracalna i 
            spowoduje trwałe zmiany w bazie danych. 
            Czy na pewno chcesz usunąć oddział?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-danger" onClick={this.deleteDepartments}>Usuń</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }