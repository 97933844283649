import React from 'react';
import Modal from 'react-bootstrap/Modal'
//import finder from './../../functions/finddataset.js';
import axios from "axios";
import Cookies from 'js-cookie';
import Loader from './../loader.js';
import {FaRegSave, FaNetworkWired, } from "react-icons/fa";
import {IoIosCalendar}  from "react-icons/io";
import {Link} from 'react-router-dom'
import planeta from './../../functions/mainpage.js';
export default class EditDepartments extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false,departmentdata:[], loaded:false, managers:[], server:"", id:0
        };
        this.hideModal = this.hideModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.ask = this.ask.bind(this);
        this.readChanges = this.readChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);

    } 
    saveChanges()
    {
        var u = document.getElementsByClassName("myInput");
            for (var a = 0; a<u.length; a++)
            {
                planeta.checkForm({target:u[a]});
            }
            var x = document.getElementsByClassName("incorrect");
        if (planeta.exist(this.props.tryb))
        {

            if (x.length>0)
            {
                window.toast.error('Nie wszystkie wymagane pola zostały wypełnione', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                return;
            }
            else
            {
                this.props.ask("departments", "createDepartament", JSON.stringify(this.state.departmentdata));
                window.location="/departments/index";
            }
        }
        else
        {
            if (x.length>0){window.toast.error('Nie wszystkie wymagane pola zostały wypełnione', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                return;}
            else
            {
                this.ask("departments", "saveDepartmentData", JSON.stringify(this.state.departmentdata));
                window.location="/departments/index";
            }
        }
    }
    readChanges(ev)
    {
        var departmentdata = this.state.departmentdata;
        departmentdata[ev.target.dataset.id]=ev.target.value;
       this.setState({departmentdata:departmentdata});
    }
    renderLink(ev)
    {
        return "/departments/" + ev.target.dataset.id;
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    
    componentDidMount()
    {
        var link = window.location.pathname.split("/");
        var id = link[link.length-1];
        console.log("ID",id);
        this.setState({id:id});
         if (id!=="new")
        {
            this.ask("departments", "getDepartmentDataById", id);
        }
        else
        {
            var emptydep = {nazwa:"",mail:"", manager:0,miejscowosc:"", ulica:"",nr:"",telefon:"",www:"", kod:""}
            this.setState({departmentdata:emptydep, loaded:true})
        }
        this.ask("departments", "getAllManagers", null);

    }
    checkData(ev)
    {
        planeta.checkForm(ev);
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getDepartmentDataById':self.setState({departmentdata:response.data.data, loaded:true});break;
                case 'getAllManagers':self.setState({managers:response.data.data});break;
                case 'saveDepartmentData':
                        var komunikat = "Nie zapisano, ponieważ nie wprowadzono żadnych zmian";
                        if (response.data.data===1)    {komunikat ="Zapisano pomyślnie";}
                        self.setState({server:komunikat, deleteModal:true});break;
                default:break;
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){alert(response.data.data.message)}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }


    render() {
        if (this.state.loaded)
        {

   var d = this.state.departmentdata;
     return(
         <div className="container">
            <div>
            <h3>Edycja oddziału             <span className="float-right">
            {(this.state.id!=="new") && (<Link className="btn btn-info" to={"/departments/calendar/" + this.state.id }><IoIosCalendar /> Kalendarz</Link>
)}
            &nbsp;
            <button className="btn btn-secondary" onClick={this.saveChanges} ><FaRegSave /> Zapisz</button></span>
</h3>
            <br></br>
            <table className="table table-striped">
                <thead></thead>
                <tbody>
                    <tr><td>Nazwa oddziału</td><td><input type="text"  data-id="nazwa" onChange={this.readChanges} onBlur={this.checkData} required className="myInput" value={d.nazwa}></input></td></tr>
                    <tr><td>Manager oddziału</td><td>
                        <select className="myInput" data-id="manager" id="manager" onChange={this.readChanges}  required  onBlur={this.checkData}value={d.manager}>
                        <option value="">--- wybierz ---</option>

                        {this.state.managers.map((key)=>(
                            <option value={key.id} key={key.id}>{key.name} ({key.mail}, {key.telefon})</option>
                        ))}
                        </select>
                        
                        
                        </td></tr>
                    <tr><td>Miejcowość</td><td><input type="text"  required  data-id="miejscowosc" onChange={this.readChanges} onBlur={this.checkData}className="myInput" value={d.miejscowosc}></input></td></tr>
                    <tr><td>Kod pocztowy</td><td><input type="text"  required  data-id="kod" onChange={this.readChanges} onBlur={this.checkData}className="myInput" value={d.kod}></input></td></tr>
                    <tr><td>Ulica</td><td><input type="text"  required  className="myInput"  data-id="ulica" onChange={this.readChanges} onBlur={this.checkData}value={d.ulica}></input></td></tr>
                    <tr><td>Numer</td><td><input type="text"  required  className="myInput"  data-id="nr" onChange={this.readChanges} onBlur={this.checkData}value={d.nr}></input></td></tr>
                    <tr><td>Telefon</td><td><input type="text" required  className="myInput"  data-id="telefon" onChange={this.readChanges} onBlur={this.checkData}value={d.telefon}></input></td></tr>
                    <tr><td>Adres poczty elektronicznej</td><td><input type="text"  data-id="mail"  required  onChange={this.readChanges} onBlur={this.checkData}className="myInput" value={d.mail}></input></td></tr>
                    <tr><td>adres strony www</td><td><input type="text" className="myInput"  required   data-id="www" onChange={this.readChanges} onBlur={this.checkData}value={d.www}></input></td></tr>
                   
                </tbody>
            </table>
            </div>
        
     



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           <FaNetworkWired /> Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>OK</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
        }else{return (<Loader />)}
    }
 }