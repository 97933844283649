import React from 'react';
// import {FaTrashAlt, FaEdit, FaLink, FaRegAddressCard} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
// import finder from './../../functions/finddataset.js';
// import {Link} from "react-router-dom";
// import Reactable from 'reactable';
// import ParentNavigation from './parentnavigation.js';
import planeta from './../../functions/mainpage.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";
import {ToHaveInfo, TimeDiff} from './../common/utylity.js';
import time from './../../functions/timedifference.js';

export default class StudentInfo extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            deleteModal:false, uprawnieni:["Anna Pohanke (Ciotka)", "Tadeusz Drozda (dziadek)", "Iwona Ropa (Sąsiadka)"]
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addPerson = this.addPerson.bind(this);
        this.savePerson = this.savePerson.bind(this);

    } 
    deleteItem(ev)
    {
        console.log(ev.target)
        var toDel = ev.target.dataset.id;
        var up =[];
        if (planeta.isJson(this.props.studentInfo.user.osoba)){up = JSON.parse(this.props.studentInfo.user.osoba)}
        var newUp = [];
        for (var a = 0; a <up.length; a++)
        {
            if (up[a]!==toDel){newUp.push(up[a])}
        }
        this.props.changeStudentInfo({target:{dataset:{id:"osoba"}, value:JSON.stringify(newUp)}})
    }
    addPerson()
    {
        var person = document.getElementById('osoba').value;
        
        if (person.length>0)
        {
            var up =[];
            if (planeta.isJson(this.props.studentInfo.user.osoba)){up = JSON.parse(this.props.studentInfo.user.osoba)}
            up.push(person);
            this.props.changeStudentInfo({target:{dataset:{id:"osoba"}, value:JSON.stringify(up)}})
            this.hideModal();
        }
    }
    savePerson()
    {
        this.props.ask("students", "saveStudentInfo", JSON.stringify(this.props.studentInfo.user));
    }
    deletePerson = () =>{
        let self = this;
      
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Operacja spowoduje utratę danych o dziecku. ",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.props.ask('administration', 'deleteStudent', self.props.studentInfo.user.id)}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }
    getInfo(ev)
    {
        console.log("PYTANIE",planeta.getStudentIdFromTable(ev))
    }
    hideModal()
    {
        this.setState({deleteModal:false})
    }
    showModal()
    {
        this.setState({deleteModal:true})
    }
    componentDidMount()
    {
        var id = planeta.getIdFromLink();
        this.props.ask('students', 'getStudentInfo', id);
    }
    render() {
        var user = this.props.studentInfo.user;
        var parent = this.props.studentInfo.parent;
        var courses = this.props.studentInfo.courses;
        var uprawnieni = [];  
        if (user.osoba!=="" && user.osoba!==null && planeta.isJson(user.osoba))
        {uprawnieni = JSON.parse(user.osoba)}
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3><FaFolderOpen /> Informacje szczegółowe</h3>
                    <div className="prinfo parentInfo">
                    <br></br>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Imię i nazwisko</label>
                            <div type="text" className="myInput disabled" data-id="name">{parent.imie} {parent.nazwisko} </div>
                        </div>
                        <div className="col-sm-4">
                            <label>Mail</label>
                            <div type="text" className="myInput disabled" data-id="mail">{parent.mail} </div>

                        </div>
                        <div className="col-sm-4">
                            <label>telefon</label>
                            <div type="text" className="myInput disabled" data-id="telefon">{parent.telefon}</div>

                        </div>
                    
                    </div>
                    </div>

                    <div className="prinfo studentInfo">
                    <br></br>
                    <div className="row">
                        <div className="col-sm-4">
                            <label>Imię</label>
                            <input type="text" className="myInput" data-id="imie" value={user.imie} onChange={this.props.changeStudentInfo} />
                            <label>Nazwisko</label>
                            <input type="text" className="myInput" data-id="nazwisko" value={user.nazwisko} onChange={this.props.changeStudentInfo}/>
                            <div className="AddInfo"><label>Uprawnieni do informacji</label><span onClick={this.showModal} className="float-right asAddButton"></span></div>
                            <div>

                                {uprawnieni.map((key, i)=>(<ToHaveInfo key={i} user={key} data-id={key} deleteItem={this.deleteItem} />))}
                              
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label>Szkoła</label>
                            <select className="myInput"  value={user.szkola} data-id="szkola" onChange={this.props.changeStudentInfo}>
                                <option value="0">--- wybierz ---</option>
                                {this.props.shools.map((key)=>(
                                    <option key={key.id} value={key.id}>{key.nazwa}</option>
                                ))}
                            </select>
                            <label>Kalsa</label>
                            <input type="text" className="myInput" value={user.klasa} data-id="klasa" onChange={this.props.changeStudentInfo}/>
                        </div>
                        <div className="col-sm-4">
                            <label>Status</label>
                            <input type="text" className="myInput" data-id="status" value={user.status}  onChange={this.props.changeStudentInfo} />
                            <label>Dołączono</label>
                            <div className="myInput">{user.created} <span className="float-right timediff small">{time.calculateDiff(new Date(), user.created)}</span></div>
                            <label>Ostatnia modyfikacja</label>
                            <div className="myInput">{user.updated} <span className="float-right timediff small">{time.calculateDiff(new Date(), user.updated)}</span> </div>
                        </div>
                        <div className="col-sm-12">
                        <label>Notatki</label>
                            <textarea data-id="notes" onChange={this.props.changeStudentInfo} className="myInput" value={user.notes}></textarea>
                        </div>
                        <div className="col-sm-12"><button className="btn btn-success btn-sm" onClick={this.savePerson}>Zapisz</button>&nbsp;
                        <button className="btn btn-danger btn-sm" onClick={this.deletePerson}>Usuń</button></div>
                    </div>
                    </div>
                    <div className="prinfo courseInfo">
                    <br></br>
                    <div className="row">
                    
                   
                    {(courses.length===0) ? (<div className="col-sm-12"><div className="alert alert-success">
 Ten użytkownik nie wybrał jeszcze żadnego kursu. Rozważ, czy nie poinformować jego rodzica o dostępnej ofercie.
 </div></div>):""}
                    {courses.map((key)=>(<div className="col-sm-6"><div className="card" key={key.id}>
                                    <div className="card-header"><b>Kurs: {key.nazwa}</b></div>
                                    <div className="card-body">
                                        <table className="table">
                                            <tbody>
                                            <tr><td>data dołączenia</td><td><div className="myInput">{key.accesDate} <TimeDiff text={time.calculateDiff(new Date(), key.accesDate)} /></div></td></tr>
                                            <tr><td>data zakończenia</td><td><div className="myInput">{key.endDate} </div></td></tr>
                                            
                                            <tr><td>Sposób płatności</td><td className="alert-info"><FaInfoCircle /> {key.paymentMethod} </td></tr>
                                            <tr><td>Status płatności</td><td className="alert-info"><FaInfoCircle /> {key.paymentStatus}</td></tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="card-footer">Footer</div>
                                    </div></div>))}
                    </div>
                    </div>
                </div>    
            </div>

         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Wskazanie osoby uprawnionej
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Osoba uprawniona to osoba, która może otrzymywać informację o postępie w nauce, zapisach
            na liście obecności a w przypadku małoletnich odbieranych przez opiekuna tylko osoba z listy
            ma prawo zabrać dziecko z placówki.
            </p>
            <label>Imię i nazwisko osoby uprawnionej</label>
            <input type="text" className="myInput" id="osoba" />
            
         
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-success" onClick={this.addPerson}>Dodaj</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }
