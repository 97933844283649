import React from 'react';
import Modal from 'react-bootstrap/Modal'
import axios, {post} from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';
import {FaChalkboardTeacher}  from "react-icons/fa";
import CodesitTable from './../common/codesittable.js';
import { HtmlEditor } from '@aeaton/react-prosemirror'
import { options } from '@aeaton/react-prosemirror-config-default'
import CustomEditor from './../common/customeditor.js';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BrowserRouter as Router, Route, Link, Switch  } from "react-router-dom";
import {GoFile} from "react-icons/go";



export default class EditLesson extends React.Component {
    constructor() {  
        super();   
    
        this.state = {checkIfToDelete:false,
            modalState:false,file:null,fileList:[],
            server:"",id:0,
           programList:[{opis:""}],
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editInput = this.editInput.bind(this);
        this.updateOpis = this.updateOpis.bind(this);
        this.saveHeaderData = this.saveHeaderData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.prepareToDelete = this.prepareToDelete.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.deletePermamently = this.deletePermamently.bind(this);

    } 
    onUploadFile(e)
    {
        var enabled =["application/pdf", 'image/jpeg', "application/vnd.oasis.opendocument.text",
        'image/png', 'text/plain', 'application/x-zip-compressed', "application/msword",
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/gzip', 'audio/mpeg', 'application/rtf', 'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/zip']
        this.setState({file:e.target.files[0]})
        console.log("LEN",e.target.files[0].size)
        var komunikat = "";
        if (e.target.files[0].size>1024000)
        {
            komunikat +="Plik jest zbyt duży. " 
        }
        if (!enabled.includes(e.target.files[0].type))
        {
            komunikat +="Plik jest w nieobsługiwanym formacie " 
        }
        if (komunikat.length>0)
        {
            this.setState({modalState:true, server:komunikat,file:null});
            document.getElementById('file').value = "";

        }

    }
    prepareToDelete(){this.setState({checkIfToDelete:true})}
    hideDeleteModal(){this.setState({checkIfToDelete:false})}
    deletePermamently()
    {
        this.ask('groups', 'DeleteOneLesson', this.state.id);
    }
    deleteFile(ev)
    {
        this.ask('files', 'deleteFile', JSON.stringify({id:this.state.id,file:ev.target.dataset.id}));

    }
    fileUpload(file){
        const url = window.location.protocol+ '//' + window.location.hostname;
        const formData = new FormData();
        formData.append('file',file)
        formData.append('token', Cookies.get("planetarozwoju"))
        formData.append('section', "files")
        formData.append('command', "SaveFile")
        formData.append('data', this.state.id)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  post(url, formData,config)
      }
    hideModal(){this.setState({modalState:false})}
    onFormSubmit(e)
    {
        var self = this;
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.file).then((response)=>{
        console.log(response.data);
        document.getElementById('file').value = ""
                console.log("SAVED")
                self.ask('files','getFileList', self.state.id)
                self.setState({modalState:true, server:response.data.data})
               
        })
    }
    updateOpis(ev)
    {
        var data = this.state.programList;
        data[0].description = ev;
        this.setState({programList:data})
    }
    editInput(ev)
    {
        var data = this.state.programList;
        switch (ev.target.dataset.id)
        {
            case 'title':data[0].title = ev.target.value;break;
            case 'unit':data[0].unit = parseInt(ev.target.value);break;
            case 'lesson':data[0].lesson = parseInt(ev.target.value);break;
            default:break;
        }
        
        this.setState({programList:data})
    }
    saveHeaderData()
    {
        var data = JSON.stringify({id:this.state.id, 
            unit:this.state.programList[0].unit, 
            lesson:this.state.programList[0].lesson, 
            description:this.state.programList[0].description})
        this.ask('groups', 'saveLessonData', data);
    }
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        this.setState({id:id});
        this.ask('groups', 'getLessonDetails', id);
        this.ask('files', 'getFileList', id);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getLessonDetails':
                var data = response.data.data;
                if (!data.description){console.log("UPDATEOPSI", data);data[0].description+=" ";}
                self.setState({ programList:data, loaded:true});break;
                case 'saveLessonData':
                self.setState({modalState:true, server:"Zapisano pomyślnie"})
                break;
                case 'getFileList':
                var files = [];
                
                for (var a =0; a<response.data.data.length;a++)
                {
                    if (response.data.data[a]!=="." && response.data.data[a]!=="..")
                    {
                        files.push(response.data.data[a])
                    }
                }
                console.log("files",response.data.data, files)
                self.setState({fileList:files});break;
                case 'deleteFile':self.setState({modalState:true, server:response.data.data})
                self.ask('files','getFileList', self.state.id)
                break;
                
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        // ClassicEditor
        // .create( document.querySelector( '#editor' ), {
        //     toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        //     heading: {
        //         options: [
        //             { model: 'paragraph', title: 'Akapit', class: 'ck-heading_paragraph' },
        //             { model: 'heading1', view: 'h1', title: 'Nagłówek 1', class: 'ck-heading_heading1' },
        //             { model: 'heading2', view: 'h2', title: 'Nagłówek 2', class: 'ck-heading_heading2' }
        //         ]
        //     }
        // } )
        // .catch( error => {
        //     console.log( error );
        // } ) 
    var headers=[
        {title:"Dział", data:"unit"},
        {title:"lekcja", data:"lesson"},
        {title:"tytuł", data:"title"},
    ];
        console.log("OPIS",this.state)
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaChalkboardTeacher /> Edycja lekcji do programu "{this.state.programList[0].nazwa}"<span className="float-right">
                <Link className="btn btn-secondary btn-sm" to={"/programs/edit/" + this.state.programList[0].programid}><i className="far fa-arrow-alt-circle-left"></i> Cofnij</Link>&nbsp;
                <button className="btn btn-danger btn-sm" onClick={this.prepareToDelete}><i className="far fa-trash-alt"></i> Usuń</button>&nbsp;
                <button className="btn btn-success btn-sm" onClick={this.saveHeaderData}><i className="far fa-save"></i> Zapisz</button>
                
                </span></h3>
                    <div className="prinfo">
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Tytuł lekcji</label>
                                <input className="myInput"  data-id="title" value={this.state.programList[0].title} onChange={this.editInput}/>

                            </div>
                               
                            <div className="col-sm-3">
                                <label>Unit</label>
                                <input className="myInput"  data-id="unit" value={this.state.programList[0].unit} onChange={this.editInput}/>
                            </div>
                            <div className="col-sm-3">
                                <label>Lesson</label>
                                <input className="myInput" data-id="lesson" value={this.state.programList[0].lesson} onChange={this.editInput}/>
                            </div>
                        </div>     
                        <div className="row">
                            <div className="col-sm-12">
                            <label>Opis programu</label>
                            <CKEditor
                                    editor={ ClassicEditor }
                                    data={this.state.programList[0].description}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                       // console.log( { event, editor, data } );
                                        this.updateOpis(data);
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                                <h3>Zarządzanie plikami</h3>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                            <form onSubmit={this.onFormSubmit}>
                                    <input  id="file" type="file" className="btn btn-secondary btn-sm" onChange={this.onUploadFile} />
                                   &nbsp; <button type="submit" className="btn btn-secondary btn-sm">Wyślij</button>
                                </form>
                            </div>
                           
                            <div className="col-sm-12">
                         {/* <Router>
                            {this.state.fileList.map((key,i)=>(<Link key={i} to={"/upload/"+this.state.id+"/"+key} 
                             target="_blank" >Download {key}</Link>))}
                             </Router> */}

                             {this.state.fileList.map((key,i)=>(<div className="fileList"><GoFile /> {key} 
                             <span className="float-right">
                                <a className="btn btn-info btn-sm" href={window.location.protocol+ '//' + window.location.hostname+"/upload/" + this.state.id+"/"+key} target="_blank">Zobacz</a> &nbsp;
                                <button className="btn btn-danger btn-sm" data-id={key} onClick={this.deleteFile}><i className="far fa-trash-alt"></i> Usuń</button>
                             </span>
                             </div>))}
                            </div>
                        </div>
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}><i className="far fa-times-circle"></i> Zamknij</button>
        </Modal.Footer>
      </Modal>
      
      <Modal
        show={this.state.checkIfToDelete}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideDeleteModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Usiłujesz usunąć lekcję, która może być używana. Czy na pewno chcesz trwale i
            nieodwracalnie usunąć te dane?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideDeleteModal}><i className="far fa-times-circle"></i> Zamknij</button>
            <Link to={"/programs/edit/" + this.state.programList[0].programid} className="btn btn-danger" onClick={this.deletePermamently}><i className="far fa-trash-alt"></i> Usuń</Link>
        </Modal.Footer>
      </Modal>
         </div>

     );
    }
 }