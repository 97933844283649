import React from 'react';
import axios from 'axios';
import Loader from './../loader.js';
import logo from './../../../img/logo.png';
import planeta from './../../functions/mainpage.js';

export default class Ankieta extends React.Component {
    constructor() {  
        super();  
        this.state = {
            loaded:false,
            show:false,
            data:{}, cennik:{}, obliczone:[],
            odpowiedz:{button:"",desc:""},
            ankietaIsUsed:false
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.isButton = this.isButton.bind(this);
        this.sendAnkieta = this.sendAnkieta.bind(this)
        this.readFrom = this.readForm.bind(this);
    } 
   
    componentDidMount()
    {   
        this.ask("ankieta", 'readAnkietaData',window.location.href);

    }
    componentDidUpdate()
    {
        var link = window.location.href.split('/')
        var number = link[link.length-4]
        
    }
    readForm(ev)
    {
        var odp  = this.odpowiedz;
        odp.desc = ev.target.value;
        this.setState({odpowiedz:odp});
    }
    sendAnkieta()
    {
        this.ask("ankieta", 'saveAnkietaData',JSON.stringify({button:this.state.odpowiedz.button,text:window.location.href,data:document.getElementById('uzasadnienie').value}));
    }
    isButton(ev)
    {
        var x = this.state.odpowiedz;
        x.button = ev.target.dataset.id;
        this.setState({odpowiedz:x})
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        params.append('token', new Date());
        if (data!==null){params.append('data', data);}
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGrups':self.setState({ grupy:response.data.data, loaded:true});break;
                case 'readAnkietaData':
                    if (response.data.data.status==='FALSE'){self.setState({show:false, loaded:true})}
                    else
                    {
                        var toSave = response.data.data.data;
                        console.log(toSave);
                        toSave[0].harmonogram = JSON.parse(toSave[0].harmonogram);
                        self.setState({show:true, loaded:true, data:response.data.data.data})
                        console.log("CENNIK",response.data.data.data[0].cennik)
                        self.ask("groups", "getCennikData", response.data.data.data[0].cennik);
                        if (planeta.isJson(toSave[0].odpowiedzi))
                        {
                            var u = JSON.parse(toSave.odpowiedzi)
                            var link = (window.location.href).split('/')
                            console.log("DANE LINK",u, link)
                        }
                    }
                    break;
                case 'getCennikData':
                self.setState({show:true, loaded:true, cennik:response.data.data[0].data})
                console.log("CENNIK",response.data.data[0])
                var obliczone = planeta.calculateCostsForParents(self.state.data[0],JSON.parse(response.data.data[0].data));
                self.setState({obliczone:obliczone})
                    break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
      
        if (this.state.loaded)
        {
            if (this.state.show)
            {
                var show = this.state.data[0];
                var harm=show.harmonogram;
                var dni = JSON.parse(show.days);
                var tydzien = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
                return(
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                
                                <div className="prinfo">
                                    <div className="row">
                                        <div className="col-sm-3">
                                        <img src={logo} alt="planetaRozwoju" className="" />
                                        </div>
                                        <div className="col-sm-9">
                                        <h3> Ankieta od Planety Rozwoju</h3>
                                        <h5>Dotyczy kursu "{show.name}"</h5>
                                        {/* {JSON.stringify(this.state.data)} */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                        <p>
                                            Miło jest nam poinformować, że dysponujemy konkretną propozycją organizacyjną kursu, na który chcesz 
                                            zapisać swoje dziecko. Aktualnie kurs jest w fazie przygotowań.
                                            Na tym etapie chcemy zapytać o Twoją opinię dotyczącą czasu i miejsca realizacji kursu.
                                               
                                        </p> 
                                        <p>
                                            Dane z sekcji 2 i 3 są danymi poglądowymi i mogą się zmienić w zależności od finalnej ilości
                                            uczestników, oraz czasu i miejsca realizacji kursu. Wzrost zainteresowania tym właśnie kursem
                                            będzie skutkował obniżeniem finalnej ceny za zajęcia.
                                        </p>  
                                        <p>
                                            Pragniemy dołożyć wszelkich starań, aby nauka była dla uczestników kursu przyjemnością, 
                                            sprawiała radość, pozwalała na pozytywną motywację coraz lepszymi wynikami oraz zachowała
                                            dobre wspomnienia w przyszłości.
                                        </p>                               
                                        </div>
                                        {(this.state.ankietaIsUsed) ? (<div className="alert alert-info">Dziękujemy za wypełnienie ankiety</div>):
                                        (<div className="col-sm-12">
                                        <h4>Ankieta</h4>
                                            <button className="btn btn-success col-sm-12" onClick={this.isButton} data-id="Tak">{(this.state.odpowiedz.button==="Tak") ? (<i className="fas fa-check"></i>):""} Tak, terminy odpowiadają</button>
                                            <br></br><br></br>
                                            <button className="btn btn-warning col-sm-12"  onClick={this.isButton} data-id="Może być">{(this.state.odpowiedz.button==="Może być") ? (<i className="fas fa-check"></i>):""} Jestem w stanie się dostosować</button>
                                            <br></br><br></br>
                                            <button className="btn btn-danger col-sm-12" onClick={this.isButton} data-id="Nie">{(this.state.odpowiedz.button==="Nie") ? (<i className="fas fa-check"></i>):""} Nie, terminy nie odpowiadają</button>
                                            <div className="row">
                                                <div className="col-sm-12" style={(this.state.odpowiedz.button.length>0)?{display:"block"}:{display:"none"}}>
                                                    <p className="desc">Jeśli chcesz przekazać jakąś wiadomość organizatorowi możesz ją umieścić tutaj</p>

                                                    <textarea id="uzasadnienie" className="form-control"></textarea>
                                                    <br></br><br></br>
                                                    <button className="btn btn-primary btn-xs" onClick={this.sendAnkieta}>Wyślij</button>
                                                </div>

                                            </div>
                                        </div>)}
                                        
                                    </div>
                                    <div className="row ankieta">
                                 
                                        
                                   <div className="col-sm-12">
                                        <h4>1. Ramy czasowe i miejsce realizacji kursu</h4>
                                      </div>  
                                        <div className="col-sm-6">
                                            <p className="desc">Planowany czas trwania kursu</p>
                                            <div className="myInput">od {show.start} do {show.end}</div>
                                            <p className="desc">Ilość zaplanowanych zajęć</p>
                                            <div className="myInput">{harm[harm.length-1].count} zajęcia po {show.czas} minut</div>
                                            <p className="desc">Dni i godziny zajęć</p>
                                            {dni.map((key,i)=>(<>
                                                {(key.length>3)?(<div className="" key={i}>{tydzien[i]}: {key} - {planeta.addMinutes(key, show.czas)}</div>):(<></>)}
                                            </>))}
                                        </div>
                                        <div className="col-sm-6">
                                            <p className="desc">Miejsce prowadzenia kursu</p>
                                            <div className="myInput">{show.nazwa}</div>
                                            <p className="desc">Adres placówki</p>
                                            <div className="myInput">{show.ulica} {show.nr}, {show.kod} {show.miejscowosc}</div>
                                            
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                        <h4>2. Dane finansowe dotyczące kursu</h4>
                                            {(this.state.obliczone.length === 0) ? "" : (<table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>Parametr kursu</td>
                                                        <td>Warość</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.obliczone.map((key,i)=>(<tr key={i}>
                                                    <td>{key.name}</td>
                                                    <td>{key.val}</td>
                                                </tr>))}
                                                </tbody>
                                            </table>)}
                                        </div>
                                        <div className="col-sm-12">
                                            <h4>3. Poglądowy harmonogram zajęć</h4>
                                            {(harm.length === 0) ? "" : (<table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>LP</td>
                                                        <td>Data</td>
                                                        <td>Dzień</td>
                                                        <td>Godzina</td>
                                                        <td>Uwagi</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {harm.map((key,i)=>(<tr key={i}>
                                                    <td>{key.count}</td>
                                                    <td>{key.data}</td>
                                                    <td>{tydzien[key.weekDay]}</td>
                                                    <td>{key.hour}</td>
                                                    <td>{key.desc}</td>
                                                </tr>))}
                                                </tbody>
                                            </table>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                </div> ) 
            }
            else
            {
                return (<div>
                    Nie ma takiego kursu
                </div>)
            }
        }
        else
        {
            return(<Loader />)
        }
 

    }
 }
