import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import {PlanetaCard} from './../common/planetacard.js';
import {FaFolderOpen, FaInfoCircle} from "react-icons/fa";

export default class GroupSelect extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",
            grupy:[], loaded:false,
            groupStatus:[],
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    } 
    componentDidMount()
    {
        this.ask('groups', 'getGrupList', this.props.user);
    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGrupList':self.setState({ grupy:response.data.data, loaded:true});break;

                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        
        
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaFolderOpen /> Lista utworzonych grup</h3>
                    <div className="prinfo">
                        <table className="table">
                        <thead>
                            <tr>
                                <th>Nazwa kursu</th>
                                <th>Rozpoczęcie</th>
                                <th>Zakończenie</th>
                                <th>Członków</th>
                                <th>Ostatnia modyfikacja</th>
                                <th>Opcje</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.grupy.map((key)=>(<tr key={key.id}>
                            <td>{key.name}</td>
                            <td>{key.start}</td>
                            <td>{key.end}</td>
                            <td>{JSON.parse(key.selected).length}</td>
                            <td>{key.updated}</td>
                            <td><Link className="btn btn-secondary btn-sm" to={"/groups/edit/"+key.id} >Edycja</Link></td>
                        </tr>))}    
                        </tbody>
                        </table>                 
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>


         </div>

     );
    }
 }