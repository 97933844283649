import React from 'react';

export class ToHaveInfo extends React.Component{
    render(){
        return (<div className="haveInfo">{this.props.user} <span data-id={this.props.user} className="float-right asRemoveButton" onClick={this.props.deleteItem}></span></div>
        )
    }
}

export class TimeDiff extends React.Component{
    render(){
        return (<span className="float-right timediff small">{this.props.text}</span>)
    }
}
