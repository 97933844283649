

import React from 'react'

export default class CodesitFile extends React.Component{

    fileToText = (file) => {
        const { form } = this.props;
        const reader = new FileReader();
        reader.readAsText(file);
        var self=this;
        reader.onload = () => {
            //console.log(reader.result)
            self.props.onLoad({fileName:file.name,fileType:file.type,content: reader.result});
        };
        
    }
    openLoader = () =>{
        this.inputElement.click();
    }
    readThisFile = (ev) => {
        console.log(ev)
        // const { form } = this.props;
         var file = ev.target.files[0];
         const dozwolone =["pdf", 'zip'];
         const maxSize = 1000000;
    
         if (file.size > maxSize){ window.toast.error("Plik jest za duży"); return;}
         if (dozwolone.indexOf(file.name.split('.')[1]) === -1){ window.toast.error("Niedozwolony typ pliku"); return;}
    
         
        // console.log(file)
        // form.setValue("docuemnts.fileName", file.name);
        // form.setValue("docuemnts.contentType", file.type);
        // form.setValue("docuemnts.fileExtension", file.name.split('.')[1]);
        // form.setValue("docuemnts.documentType", "Wezwanie do korekty");
        this.fileToText(file);
        //style={{height:'0px'}}
    }

    render(){
        const {label, id, labelClass } = this.props;
        return (<>
        <div onClick={this.openLoader}>
            <button className={labelClass} onClick={this.openLoader}>{label}</button>
            <input style={{height:'0px'}} ref={input => this.inputElement = input} type="file"  id={id} onChange={this.readThisFile}/>
        </div>
        </>)
    }
}