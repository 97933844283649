import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';

export default class Administration extends React.Component {
    constructor() {  
        super();   
        this.state = {
            deleteModal:false, toDelete:0, prepareName:"",accountList:[],accountModal:false,
            generalList:[],cennik:[],editCourse:false, 
            kurs:{courseName:"", courseKey:"", id:0},
            EditedAccount:{id:"new", nazwa:"", kluczApi:"", numer:"",kluczPodpisu:"", enviromet:"SANDBOX"}
        }
        };
    

    NewCourse = () => {this.setState({deleteModal:true});}
    NewCennik = () =>{this.setState({kurs:{courseName:"", courseKey:"", id:0}});this.ask('administration', 'createCennik', null)}
    componentDidMount = () =>
    {
        this.ask('administration', "getGeneralCourseList", null)
        this.ask('administration', "getCennikList", null)
        this.ask('administration', "getAccountList", null)
    }

    deleteCennik = (ev) =>{
        let self = this;
        let dataset = ev.target.dataset;
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Usunięcie cennika powoduje usunięcie zależności pomiędzy cennikiem a poszczególnymi kursami. Czy jesteś pewien, że chcesz usunąć ten cennik",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.ask('administration', 'deleteCennik', dataset.id)}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }

    deleteAccount = (ev) =>{
        let self = this;
        let dataset = ev.target.dataset;
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Usunięcie numeru konta jest operacją nieodwracalną. Czy masz pewność, że chcesz usunąć numer konta",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.ask('administration', 'deleteAccount', dataset.id)}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }
  
    newAccount = () => {
        
        this.setState({accountModal:true, EditedAccount:{id:"new", nazwa:"", kluczApi:"", numer:"",kluczPodpisu:"", enviromet:"SANDBOX"}})

    }
    editBankAccount = (ev) =>{
        var toEdit = this.state.accountList[ev.target.dataset.count];
        if (toEdit.id === ev.target.dataset.id){console.log("POPRAWNE")}
        this.setState({accountModal:true,EditedAccount:toEdit });
    }
    hideModal = () =>{this.setState({deleteModal:false, accountModal:false})}
    openEditModal = (ev) =>{this.ask('administration', 'getCennikData', ev.target.dataset.id); this.setState({editCourse:true,kurs:{courseName:"", courseKey:"", id:0}})}
    closeEditModal = () =>{this.setState({editCourse:false, kurs:{courseName:"", courseKey:"", id:0}})}
    editCourseData = (ev) =>{
        console.log(ev.target.name)
        let kurs = this.state.kurs;
        kurs[ev.target.name]=ev.target.value;
        this.setState({kurs:kurs});
    }
    updateKurs = () =>{
        this.ask('administration', 'setCennikData', JSON.stringify(this.state.kurs));
        this.setState({editCourse:false, kurs:{courseName:"", courseKey:"", id:0}});
    }
    addKurs=()=>{
        let kurs = this.state.kurs;
        if (kurs.courseName.length<3){window.toast.error('Nieprawidłowa nazwa kursu'); return;}
        if (kurs.courseKey.length<3){window.toast.error('Nieprawidłowy klucz'); return;}
        this.ask('administration', 'insertNewCourse', JSON.stringify(this.state.kurs));
    }
    addAccountData = (ev) => {
        console.log(ev.target.name, ev.target.value)
        var konto = this.state.EditedAccount;
        konto[ev.target.name] = ev.target.value;
        this.setState({EditedAccount:konto});
    }
    addAccount = () =>{
        var toSave = this.state.EditedAccount;
        if (toSave.nazwa==="" || toSave.kluczApi==="" || toSave.numer==="")
        {
            window.toast.error('Wszystkie pola są wymagane');return
        }
        else
        {this.ask("administration", 'addBankAccountData', JSON.stringify(toSave))}
    }
    deleteCourse = (ev) =>{
        let dataset = ev.target.dataset;
        let self = this;
        window.confirmAlert({
            title: 'Ostrzeżenie',
            message: "Jeśli kurs jest niedostępny na macieżystej stronie z zapisami upewnij się, czy nie jest on jeszcze używany. Czy jesteś pewien, że chcesz usunąć ten kurs",
            buttons: [
              {
                label: 'Potwierdź',
                className:"btn btn-danger",
                onClick: () => {self.ask('administration', 'deleteKurs', dataset.id)}
              },
              {
                label: 'Anuluj',
                onClick: () => {}
              }
            ]
          });
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGeneralCourseList':self.setState({generalList:response.data.data});break;
                case 'getCennikList':self.setState({cennik:response.data.data});break;
                case 'createCennik':
                    window.toast.success('Dodano nowy cennik', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                
                        self.ask('administration', "getCennikList", null);
                    break;
                case 'deleteCennik':
                            window.toast.success('Cennik został usunięty')
                            self.ask('administration', "getCennikList", null);break;
                case 'getCennikData':
                    let resp = response.data.data;
                        let kurs = {courseName:resp.nazwa, courseKey:resp.strona, id:resp.id}
                        self.setState({kurs:kurs});
                            break;
                case 'setCennikData':if (Number(response.data.data)>0)
                            {
                                window.toast.success("Zmiany zostały zachowane.");
                                self.ask('administration', "getGeneralCourseList", null)
                            }
                            else
                            {
                                window.toast.info('Wysłano te same dane, które są zapisane');
                            }
                            break;
                case 'insertNewCourse':
                                window.toast.success("Nowy kurs został dodany.");
                                self.setState({deleteModal:false,editCourse:false, kurs:{courseName:"", courseKey:"", id:0}})
                                self.ask('administration', "getGeneralCourseList", null)
                                break;

                case 'deleteKurs':
                            window.toast.success("Kurs na trwałe usunięty.");
                            self.setState({editCourse:false, kurs:{courseName:"", courseKey:"", id:0}})                            
                            self.ask('administration', "getGeneralCourseList", null)
                            break;
                case 'getAccountList':
                            self.setState({accountList:response.data.data});break;
                case 'addBankAccountData':self.ask('administration', 'getAccountList', null );self.setState({accountModal:false});break;
                case 'deleteAccount':self.ask('administration','getAccountList', null);window.toast.success('Konto zostało usunięte');break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            
            window.toast.error('Błąd serwera')
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
        
     return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Administracja</h3>

                </div>    
            </div>
            <div className="row">
                <div className="col-sm-12">
                <hr></hr>
                        <h4>Podstawowe kursy <span className="float-right"><button onClick={this.NewCourse} className="btn btn-success btn-sm">Nowy kurs</button></span></h4>  
                        <div>
                            <hr></hr>
                        {this.state.generalList.map((key)=>(<div key={key.id} className="adminItem">
                            {key.nazwa} <span className="float-right">
                            <button className="btn btn-danger btn-sm" data-id={key.id} onClick={this.deleteCourse}>Usuń</button>&nbsp;

                            <button className="btn btn-secondary btn-sm" data-id={key.id} onClick={this.openEditModal}>Edytuj</button>&nbsp;

                            </span>
                        </div>))}
                        </div>
                        <hr></hr>
                        <h4>Cenniki kursów <span className="float-right"><button onClick={this.NewCennik} className="btn btn-success btn-sm">Nowy cennik</button></span></h4>
                        <hr></hr>
                        {this.state.cennik.map((key)=>(<div key={key.id} className="adminItem">
                            {key.name} (ostatnia modyfikacja {key.updated}) <span className="float-right">
                                <button className="btn btn-danger btn-sm" data-id={key.id} onClick={this.deleteCennik}>Usuń</button>&nbsp;
                                <Link className="btn btn-secondary btn-sm" to={"/editpricelist/" + key.id}>Edytuj</Link>
                            
                            </span>

                           
                        </div>))}


                        <hr></hr>
                        <h4>Konta i klucze płatności <span className="float-right"><button onClick={this.newAccount} className="btn btn-success btn-sm">Nowe konto</button></span></h4>
                        <hr></hr>
                        {this.state.accountList.map((key, i)=>(<div key={key.id} className="adminItem">
                            {key.nazwa} (ostatnia modyfikacja {key.modificated}) <span className="float-right">
                                <button className="btn btn-danger btn-sm" data-id={key.id} onClick={this.deleteAccount}>Usuń</button>&nbsp;
                                <button className="btn btn-secondary btn-sm" data-count={i} data-id={key.id} onClick={this.editBankAccount}>Edytuj</button>
                            
                            </span>

                           
                        </div>))}
                        <div style={{height:"50px"}}></div>
                </div>
               
                
            
            </div>



         <Modal
        show={this.state.deleteModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Dodawanie nowego kursu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div>
                Nowy kurs musi być zarejestrowany na innej stronie. 
                Upewnij się, że Nazwa i klucz, które wprowadzasz, są poprawne.
                </div>
                <label>Nazwa kursu</label>
            <input className="myInput" name="courseName" value={this.state.kurs.courseName} onChange={this.editCourseData} />
            <label>Klucz</label>
            <input className="myInput" name="courseKey" value={this.state.kurs.courseKey} onChange={this.editCourseData} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-sm" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-success btn-sm" onClick={this.addKurs}>Dodaj</button>
        </Modal.Footer>
      </Modal>


{/* Edytuj Kurs */}
      <Modal
        show={this.state.editCourse}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.closeEditModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Edycja istniejącego kursu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div>
                Klucz kursu musi być zarejestrowany na innej stronie. 
                Upewnij się, że Nazwa i klucz, które wprowadzasz, są poprawne.
                </div>
            <label>Nazwa kursu</label>
            <input className="myInput" name="courseName" value={this.state.kurs.courseName} onChange={this.editCourseData} />
            <label>Klucz</label>
            <input className="myInput" name="courseKey" value={this.state.kurs.courseKey} onChange={this.editCourseData} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-sm" onClick={this.closeEditModal}>Anuluj</button>
            <button className="btn btn-success btn-sm" onClick={this.updateKurs}>Zapisz</button>
        </Modal.Footer>
      </Modal>

        {/* Nowe konto ===============================================================*/}
      <Modal
        show={this.state.accountModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Kane konta bankowego
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div>
                    Dane konta bankowego muszą być tożsame z danymi o kluczach uwierzytelniających dostępnymi
                    po zalogowaniu na konto PayNow.
                </div>
                <label>Nazwa konta (widoczna jedynie dla kadry zarządzającej)</label>
                <input type="hidden" name="id" value={this.state.EditedAccount.id} />
                <input className="myInput" name="nazwa" value={this.state.EditedAccount.nazwa} onChange={this.addAccountData} />
                <label>Numer konta</label>
                <input className="myInput" name="numer" value={this.state.EditedAccount.numer} onChange={this.addAccountData} />
                <label>Klucz Api</label>
                <input className="myInput" name="kluczApi" value={this.state.EditedAccount.kluczApi} onChange={this.addAccountData} />
                <label>Klucz podpisu</label>
                <input className="myInput" name="kluczPodpisu" value={this.state.EditedAccount.kluczPodpisu} onChange={this.addAccountData} />
                <label>Środowisko</label>
                <select className="myInput" name="enviromet" onChange={this.addAccountData} value={this.state.EditedAccount.enviromet} >
                    <option value="SANDBOX">Testowe</option>
                    <option value="PRODUCTION">Produkcyjne</option>
                </select>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default btn-sm" onClick={this.hideModal}>Anuluj</button>
            <button className="btn btn-success btn-sm" onClick={this.addAccount}>Dodaj</button>
        </Modal.Footer>
      </Modal>
         </div>

     );
    
 }
}
