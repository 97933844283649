import React from 'react';
import{Link} from 'react-router-dom';
 export default class AdminNav extends React.Component {
  //other logic
    render() {
     return(
      <nav className="navbar navbar-light navbar-expand-sm">
      <Link to="/" style={{color:"#ccc"}}>Planeta Rozwoju</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">

              <ul className="navbar-nav">
                  <li className="nav-item">
                      <Link className="nav-link"  to="/departments/index">Filie</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/places/index">Placówki</Link>
                  </li>
                  <li className="nav-item">
                       <Link className="nav-link"  to="/groups/index">Grupy</Link>
                  </li>
                  <li className="nav-item">
                       <Link className="nav-link"  to="/programs/index">Kursy</Link>
                  </li>
                  <li className="nav-item">
                       <Link className="nav-link"  to="/lectors/index">Pracownicy</Link>
                  </li>
                  <li className="nav-item">
                       <Link className="nav-link"  to="/parents/index">Rodzice i Uczniowie</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/administration/">Administracja</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/settlement/">Rozliczenia</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link"  to="/setup/">Ustawienia</Link>
                  </li>
                  
                  
              </ul>
              </div>
          </nav>

     );
    }
 }