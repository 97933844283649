import React from 'react';
import{Link} from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import {TiStarFullOutline, TiLockOpen, TiLockClosed} from "react-icons/ti";
import Modal from 'react-bootstrap/Modal';
import RunChat from './../common/runchat.js'
 export default class Lekcja extends React.Component {
    constructor() {  
        super();   
    
        this.state = {dziennikToSave:{},dzienniki:[[{program:0, obecnosc:[]}],[{termin:""}]], loaded:false, ofset:0,calendarModal:false,modalData :{},
        tematy:[],students:[],activeStar:"", oceny:[],ready:false, interval:null,files:[],serverModal:false,server:"",
        szczegolyProgramu:"<i>Nie wybrano tematu</i>", messageModal:false,wiadomosc:"",temat:"",
        }
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.editModalData = this.editModalData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getTematy = this.getTematy.bind(this)
        this.getTemat = this.getTemat.bind(this);
        this.changeStarButton = this.changeStarButton.bind(this);
        this.addStarToStudent = this.addStarToStudent.bind(this);
        this.zapiszOceny = this.zapiszOceny.bind(this);
        this.calculateAVG = this.calculateAVG.bind(this);
        this.calculateAVM = this.calculateAVM.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.addChalange = this.addChalange.bind(this);
        this.runTimer = this.runTimer.bind(this);
        this.timerEvent = this.timerEvent.bind(this);
        this.saveLesson = this.saveLesson.bind(this);
        this.notatka = this.notatka.bind(this);
        this.markFile =this.markFile.bind(this);
        this.hideServerModal = this.hideServerModal.bind(this);
        this.getTablica = this.getTablica.bind(this);
        this.editMessage = this.editMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

    }
    sendMessage()
    {
        //INSERT INTO `planetarozwoju`.`dziennik` (`kursid`, `nazwa`, `dzien`, `godzina`, `closing`, `lektor`, `temat`, `obecnosc`) VALUES ('0', 'MNIEJ WAŻNY', '2020-03-22', '10:00', '2020-03-22', '4', 'komunikat', '["44"]');
        var data = {temat:this.state.temat, wiadomosc:this.state.wiadomosc, dzieci:this.state.dzienniki[0].obecnosc}
        this.ask("students", "sendMessageToTablica", JSON.stringify(data));
    }
    editMessage(ev)
    {
        switch (ev.target.dataset.id)
        {
            case 'temat':this.setState({temat:ev.target.value});break;
            case 'wiadomosc':this.setState({wiadomosc:ev.target.value});break;
        }
    }
    
    getTablica(){this.setState({ messageModal:true});}
    hideServerModal()
    {
        this.setState({serverModal:false, messageModal:false});
    }
    markFile(ev)
    {
        //.classList.contains('active')
        // if (ev.target.classList.contains("active"))
        // {
        //     ev.target.classList.remove("active");
        // }
        // else
        // {
        //     ev.target.classList.add("active");
        // }
        var dziennik = this.state.dzienniki;
        var notes = dziennik[0].materialy.pliki;
        var newNotes = [];
        //debugger;
        if (notes.includes(ev.target.innerHTML))
        {
            for (var a =0;a<notes.length; a++)
            {
                if (notes[a]!==ev.target.innerHTML){newNotes.push(notes[a])}
            }
            console.log("NEW NOTES:", newNotes)
        }
        else
        {
            newNotes = notes;
            newNotes.push(ev.target.innerHTML)
        }
        
        dziennik[0].materialy.pliki = newNotes;
        this.setState({dzienniki:dziennik})
    }
    notatka(ev)
    {
        var dziennik = this.state.dzienniki;
        var notes = dziennik[0].materialy;
        if (ev.target.dataset.id==="widoczna"){notes.widoczna=ev.target.value;}
        if (ev.target.dataset.id==="niewidoczna"){notes.niewidoczna=ev.target.value;}
        dziennik[0].materialy = notes;
        this.setState({dzienniki:dziennik})
    }
    saveLesson()
    {
        var dziennik = this.state.dzienniki[0];
        dziennik.obecnosc = JSON.stringify(dziennik.obecnosc);
        dziennik.wyniki = JSON.stringify(dziennik.wyniki)
        dziennik.materialy = JSON.stringify(dziennik.materialy)
        this.ask("groups", "saveOneLesson", JSON.stringify(dziennik))
        dziennik.obecnosc = JSON.parse(dziennik.obecnosc);
        dziennik.wyniki = JSON.parse(dziennik.wyniki)
        dziennik.materialy = JSON.parse(dziennik.materialy)
    }
    timerEvent()
    {
        if (typeof this.state.dzienniki[0]==="undefined"){return}
        if (typeof this.state.dzienniki[0].wyniki==="undefined"){return}
        var dzienniki = this.state.dzienniki
        var wynik = dzienniki[0].wyniki
       for (var a = 0; a<wynik.length; a++)
       {
           var licz = wynik[a];
           if (licz.enabled===true)
           {
               licz.endTime = new Date()
               licz.diff = new Date(licz.endTime - licz.startTime).toTimeString().substr(3,5)
           }
       }
        
       dzienniki[0].wyniki = wynik;
       this.setState({dzienniki:dzienniki});
    }
    runTimer(ev)
    {
        var dzienniki = this.state.dzienniki
        var wynik = dzienniki[0].wyniki
        if (wynik[parseInt(ev.target.dataset.id)].enabled===false)
       { wynik[parseInt(ev.target.dataset.id)].enabled=true;
        wynik[parseInt(ev.target.dataset.id)].startTime=new Date();}
       else
       {wynik[parseInt(ev.target.dataset.id)].enabled=false;
       wynik[parseInt(ev.target.dataset.id)].avaible=false;}
       dzienniki[0].wyniki = wynik;
       this.setState({dzienniki:dzienniki});
    }
    addChalange()
    {
        var dziennik = this.state.dzienniki;
        var nazwa = document.getElementById('nazwaWyzwania').value;
        if (nazwa ===""){nazwa = "Zadanie na czas"}
        var wyniki = dziennik[0].wyniki
        wyniki.push({name:nazwa, avaible:true, diff:"00:00", enabled:false, startTime:new Date(), currentTime:new Date()});
        dziennik[0].wyniki = wyniki;
        document.getElementById('nazwaWyzwania').value ="";
        this.setState({dzienniki:dziennik})

    }

    checkAll(ev)
    {
        var ofset = parseFloat(ev.target.dataset.ofset)
        var dziennik = this.state.dzienniki;
        var data = dziennik[0].obecnosc;
        for (var a = 0; a<data.length; a++)
        {
            data[a][ofset]=true
            console.log(data[a][ofset], ofset, ev.target.dataset)
        }
        dziennik[0].obecnosc = data;
        console.log("CHECKALL", data);
        this.setState({dzienniki:dziennik});
    }
    calculateAVG(e)
    {
        var dziennik = this.state.dzienniki;
        var data = dziennik[0].obecnosc;
        var val = 0;
        for (var a = 0; a<data.length; a++)
        {
           if  (data[a][e]===true){val++}
        }
        return Math.round(val*100/data.length)+"%";
    }

    calculateAVM(e)
    {
        var dziennik = this.state.dzienniki;
        var data = dziennik[0].obecnosc;
        var val = 0;
        for (var a = 0; a<data.length; a++)
        {
            var d = parseFloat(data[a][e])
            if (isNaN(d)){d=0}
           val+=d;
        }
        return Math.round(val*100/data.length)/100+"%";
    }
    zapiszOceny(ev)
    {
        console.log(ev.target.dataset, ev.target.checked, this.state.dzienniki[0].obecnosc)
        var dane = this.state.dzienniki;
        for (var xz = 0; xz <dane[0].obecnosc.length; xz++)
        {
            if (dane[0].obecnosc[xz][1]===ev.target.dataset.id)
            {
                console.log("TROVATO")
                var value = "";
                if (parseInt(ev.target.dataset.index)>5)
                {
                    value = ev.target.value;
                    if (parseFloat(value)>100){value=100}
                
                }
                else{value = ev.target.checked}
                console.log("VAL",value)
                 dane[0].obecnosc[xz][parseInt(ev.target.dataset.index)] = value;
                }
        }
        this.setState({dzienniki:dane})
        
    }
    addStarToStudent(ev)
    {
        var oceny = this.state.oceny
        if (typeof oceny[ev.target.dataset.studentId]==="undefined"){oceny[ev.target.dataset.studentid]=[]}
        oceny[ev.target.dataset.studentid].push({rodzaj:"star",class:this.state.activeStar})
    }
    getTemat(e)
    {
        console.log(e.target.dataset)
        var tem = e.target.dataset.temat;
        var dd = this.state.dzienniki;
        dd[0].temat = tem;
        dd[0].materialy.nrLekcji = e.target.dataset.id;

        this.setState({dzienniki:dd, calendarModal:false,});
        this.ask("groups", "getWymaganiaFromProgram", e.target.dataset.id)
        this.ask("files", "getFileList",  e.target.dataset.id)
    }
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1];
        
        this.ask('dziennik', 'getLessonLector', id)
        var self = this;
        setInterval(function(){self.timerEvent()}, 1000);
    }
    hideModal(){this.setState({calendarModal:false})}
    getTematy()
    {
        this.ask('dziennik', 'getProgramDetails', this.state.dzienniki[0].program)
    }
    editModalData(ev)
    {
      var modalData ={}
      for (var a = 0; a<this.state.dzienniki.length; a++)
      {
        if (this.state.dzienniki[a].id===ev.id){modalData=this.state.dzienniki[a]}
      }
      console.log("modalData", modalData, ev.id)
      this.setState({modalData:modalData, calendarModal:true, modalTitle:"Edycja"})
    }
    changeStarButton(ev){
        // var stars = document.getElementsByClassName('mystar');
        // for (var a =0; a<stars.length; a++)
        // {
        //     stars[a].classList.remove('active')
        // }
        // var activeStar = ev.target.classList.toString()
        // ev.target.classList.add('active');
        // this.setState({activeStar:activeStar})
        // var arrows = document.getElementsByClassName('mystarsarrows');
        // for (var b = 0; b<arrows.length;b++)
        // {
        //     arrows[b].classList.add('actives')
        // }
        
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getLessonLector':
                var data = response.data.data;
                var a =0;
                    data[a].start = new Date(data[a].dzien + " " + data[a].godzina+":00");
                    var end = data[a].start.getTime() + parseInt(data[a].czas) *1000*60
                     data[a].end = new Date(end)
                     data[a].closing = new Date(data[a].closing)
                     data[a].title = data[a].nazwa;
                     if (data[a].temat!==""){ data[a].title = data[a].title +"(" +data[a].temat +")"}
                     var today = new Date();
                     data[a].obecnosc = JSON.parse(data[a].obecnosc)
                     data[a].wyniki = JSON.parse(data[a].wyniki)

                     if (data[a].start>today){data[a].status = "wyniki"}
                     else if (today<data[a].closing){data[a].status = "aktywne"}
                     else {data[a].status = "zamknięte"}
                     if (data[a].materialy=="[]"){data[a].materialy = {widoczna:"",niewidoczna:"",pliki:[], nrLekcji:0}}
                     else {data[a].materialy = JSON.parse(data[a].materialy)}
                     console.log("NUMER LEKCJI", data[a].materialy)
                     if (parseInt(data[a].materialy.nrLekcji)>0)
                     {
                        self.ask("groups", "getWymaganiaFromProgram", data[a].materialy.nrLekcji)
                        self.ask("files", "getFileList",  data[a].materialy.nrLekcji)
                     }
                     
                     console.log((today>data[a].start), (today>data[a].end), data[a].status)
                    console.log("POLICZONE")
                    self.ask("dziennik", "getStudentsForLesson" , data[a].selected)
                    self.setState({ dzienniki:data, loaded:true});
                    
                    break;
                case 'getProgramDetails':self.setState({ tematy:response.data.data, calendarModal:true});break;
                case 'getFileList':self.setState({files:response.data.data});break;
                case 'getStudentsForLesson':
                var data = response.data.data;
                var lekcja = self.state.dzienniki;
                console.log("LEKCJA", lekcja, data)
                if (lekcja[0].obecnosc.length===0)
                {
                   
                    for (var a =0; a<data.length;a++)
                    {
                        self.state.dzienniki[0].obecnosc.push([data[a].imie +" " +data[a].nazwisko, data[a].id,false,false,false,false,"",""])
                    }
                }
                console.log("UZUP", lekcja[0].obecnosc)
                self.setState({students:response.data.data, dzienniki:lekcja, loaded:true});break;
                case 'getWymaganiaFromProgram':self.setState({szczegolyProgramu:response.data.data});break;
                case 'saveOneLesson':self.setState({server:response.data.data, serverModal:true});break;
                case 'sendMessageToTablica':self.setState({server:"Wysłano poprawnie", serverModal:true, messageModal:false });break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
        }).catch(function (error) {console.log(error);});
    }

    render() {
      if  (this.state.loaded){
     return(<div className="container">
                <div className="row">
                
                    <div className="col-sm-12">

                        <h5>
                            {(this.state.dzienniki[1].termin==="EDIT") ? (<TiLockOpen style={{color:"green"}}/>) :<TiLockClosed style={{color:"red"}}/>}
                            Lekcja w kursie "{this.state.dzienniki[0].nazwa}"

                            <span className="float-right">
                            <button className="btn btn-secondary btn-sm" onClick={this.saveLesson}>Zapisz</button>&nbsp;
                            <button className="btn btn-secondary btn-sm" onClick={this.getTablica}>Wiadomość na tablicę</button>&nbsp;
                            <button className="btn btn-secondary btn-sm" onClick={this.getTematy}>Tematy</button>&nbsp;
                            <Link to={"/harmonogram/view/" + this.state.dzienniki[0].kursid } className="btn btn-secondary btn-sm">Harmonogram</Link>&nbsp;
                                <Link to={"/readprograms/view/" + this.state.dzienniki[0].program } className="btn btn-secondary btn-sm">Program</Link>
                            </span>
                        </h5>
                        <div className="prinfo">
                        {(this.state.dzienniki[1].termin==="PREPARE") ? (<div className="alert alert-warning">
  <strong>Uwaga!</strong> Możesz przygotować różne części lekcji z wyjątkiem danych dotyczących ocen i obecności. Wypełnione dane nie zostaną zapisane
</div>):""}
{(this.state.dzienniki[1].termin==="STOP") ? (<div className="alert alert-danger">
  <strong>Uwaga!</strong> Upłynął czas w którym można było edytować dane tej lekcji
</div>):""}
{(this.state.dzienniki[1].termin==="EDIT") ? (<div className="alert alert-success">
Zekcja zaplanowana na {this.state.dzienniki[0].dzien} na godzinę {this.state.dzienniki[0].godzina} czas trwania {this.state.dzienniki[0].czas} minut.
</div>):""}
                        <div><h5>Temat</h5> <div className="myInput">{(this.state.dzienniki[0].temat==="") ? "Brak wybranego tematu" :this.state.dzienniki[0].temat}</div></div>
                        <h5>Obecność i oceny </h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Imię i nazwisko</td>
                                    <td ><div className="myStar color1"><TiStarFullOutline /></div><div className="myStar color1">Obecność</div></td>
                                    <td className="myStar color2"><div><TiStarFullOutline /></div><div>Mówienie</div></td>
                                    <td className="myStar color3"><div><TiStarFullOutline /></div><div>Dodatkowa aktywność</div></td>
                                    <td className="myStar color4"><div><TiStarFullOutline /></div><div>Praca domowa</div></td>
                                    <td className="myStar color5"><div><TiStarFullOutline /></div><div>Testy</div></td>
                                    <td className="myStar color6"><div><TiStarFullOutline /></div><div>Ocena procentowa</div></td>

                                </tr>
                            </thead>
                            <tbody>
                            {this.state.dzienniki[0].obecnosc.map((key)=>(<tr key={key[1]}>
                                <td>{key[0]}</td>
                                <td><input type="checkbox" data-id={key[1]} data-index={2} className="ocena" onChange={this.zapiszOceny} checked={key[2]}/></td>
                                <td><input type="checkbox" data-id={key[1]} data-index={3} className="ocena" onChange={this.zapiszOceny} checked={key[3]}/></td>
                                <td><input type="checkbox" data-id={key[1]} className="ocena" data-index={4} className="ocena" onChange={this.zapiszOceny} checked={key[4]}/></td>
                                <td><input type="checkbox" data-id={key[1]} className="ocena" data-index={5} className="ocena" onChange={this.zapiszOceny} checked={key[5]}/></td>
                                <td><input type="number" data-id={key[1]} className="ocena" step="0.01" min="0" max="100" className="myInput" data-index={6}  onChange={this.zapiszOceny} value={key[6]}/></td>
                                <td><input type="number" data-id={key[1]} className="ocena" step="0.01" min="0" max="100" className="myInput"  data-index={7}  onChange={this.zapiszOceny} value={key[7]}/></td>
                            </tr>))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Średnia</td>
                                    <td>{this.calculateAVG(2)}</td>
                                    <td>{this.calculateAVG(3)}</td>
                                    <td>{this.calculateAVG(4)}</td>
                                    <td>{this.calculateAVG(5)}</td>
                                    <td>{this.calculateAVM(6)}</td>
                                    <td>{this.calculateAVM(7)}</td>

                                </tr>
                                <tr>
                                    <td>Zaznacz wszystkie</td>
                                    <td><button data-ofset={2} onClick={this.checkAll} className="btn btn-secondary btn-sm">✓</button></td>
                                    <td><button data-ofset={3} onClick={this.checkAll} className="btn btn-secondary btn-sm">✓</button></td>
                                    <td><button data-ofset={4} onClick={this.checkAll} className="btn btn-secondary btn-sm">✓</button></td>
                                    <td><button data-ofset={5} onClick={this.checkAll} className="btn btn-secondary btn-sm">✓</button></td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tfoot>
                        </table>
                       
                       
                        <h5>Wyzwania na czas </h5>
                            <div>
                                <input type="text" className="myInput col-sm-6" placeholder="wpisz nazwę wyzwania" id="nazwaWyzwania" />
                                <button className="btn btn-secondary btn-sm" onClick={this.addChalange}>Dodaj wyzwanie</button>&nbsp;
                            </div>
                            {/* wyniki.push({name:"XYZ", avaible:true, enabled:false, startTime:new Date(), currentTime:new Date()}); */}
                                {(this.state.dzienniki[0].wyniki.length===0) ?(<div className="alert alert-secondary">Nie ma żadnych wyzwań na czas</div>):""}
                                {this.state.dzienniki[0].wyniki.map((key,i)=>(<div key={i} className={(key.avaible===true) ? "alert alert-success" : "alert alert-info"}>{key.diff} {key.name}
                                {(key.avaible)? (<span className="float-right">
                                <button className={(key.enabled) ? "btn btn-success btn-sm":"btn btn-secondary btn-sm"} data-id={i} onClick={this.runTimer}>{(key.enabled) ?"Zakończ":"Uruchom"}</button>
                                
                                </span>):""}
                                
                                </div>))}

                            <h5>Zadanie domowe i notatka</h5>
                            <label>Część notatki niewidocznej dla rodzica</label>
                            <textarea className="form-control" onChange={this.notatka} data-id="niewidoczna" value={this.state.dzienniki[0].materialy.niewidoczna} ></textarea>
                            <label>Część notatki widocznej dla rodzica</label>
                            <textarea className="form-control" onChange={this.notatka} data-id="widoczna" value={this.state.dzienniki[0].materialy.widoczna}  ></textarea>
                            <div>Lista plików</div>
                            {this.state.files.map((key,i)=>(<span key={i} >{(key==="." || key==="..") ? "":(<span  className={(this.state.dzienniki[0].materialy.pliki.includes(key)) ? "lessonfile active":"lessonfile"} onClick={this.markFile}>{key}</span>)}</span>))}
                            <h5>Wymagania programowe</h5>
                                
                            <div><span dangerouslySetInnerHTML={{ __html: this.state.szczegolyProgramu }}></span></div>
                        </div>
                        
                    </div> 
                </div>
          
        <Modal
        show={this.state.calendarModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Dane lekcji {this.state.modalData.nazwa}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12 codesit cont">
                    {this.state.tematy.map((key)=>(<div data-temat={key.title} onClick={this.getTemat} data-id={key.id} key={key.id}>{key.title}</div>))}
                </div>   
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Anuluj</button>
            <Link className="btn btn-success" to={"/lection/" + this.state.modalData.id} onClick={this.saveCalendarData}>Przejdź</Link>
        </Modal.Footer>
      </Modal>



      <Modal
        show={this.state.serverModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideServerModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12">{this.state.server}</div>
                
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideServerModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.messageModal}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideServerModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Wiadmość na tablicę rodzica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12">
                <label>Temat</label>
                <input type="text" className="myInput" onChange={this.editMessage} data-id="temat" value={this.state.temat} />
                <label>Wiadomość</label>
                <textarea className="form-control" data-id="wiadomosc" value={this.state.wiadomosc} onChange={this.editMessage} rows='7'></textarea>
                </div>
                
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideServerModal}>Zamknij</button>
            <button className="btn btn-success" onClick={this.sendMessage}>Wyślij</button>
        </Modal.Footer>
      </Modal>
     </div>)
    }else{return(<div>Ładowanie....</div>)}
    
 }

 }