import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import time from './../../functions/timedifference.js';
import planeta from './../../functions/mainpage.js';
import {WeekDays} from './../common/weekDays.js';
import {FaUsers, } from "react-icons/fa";

export default class NewGroup extends React.Component {
    constructor() {  
        super();   
    
        this.state = {
            server:"",title:"Tworzenie nowej grupy",
            grupy:[], loaded:false,
            groupStatus:[],id:"",
            kursy:[], lektorzy:[], cennik:[], placowki:[],konta:[],
            displayDays:false,page:1,
            program:[],
            aspirants:[],selected:[],
            wycena:[],sendModalState:false,
            data:{id:'new',name:'',course:0,czas:0, lector:0,place:0,cennik:0,start:"",end:"",days:"[\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\"]",kosztsali:"",czlonkowie:"", program:0, selected:"", harmonogram:[], konto:0}
        };
        this.hideModal = this.hideModal.bind(this);
        this.ask=this.ask.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.readValue = this.readValue.bind(this);
        this.readDayWeeks= this.readDayWeeks.bind(this);
        this.toggleDayEdition = this.toggleDayEdition.bind(this);
        this.togglePills = this.togglePills.bind(this);
        this.addCandidate = this.addCandidate.bind(this); 
        this.showInCandidates = this.showInCandidates.bind(this);  
        this.showInSelected = this.showInSelected.bind(this);
        this.removeCandidate = this.removeCandidate.bind(this);
        this.filtr = this.filtr.bind(this);
        this.saveCourse = this.saveCourse.bind(this);
        this.generateCalendar = this.generateCalendar.bind(this);
        this.createHarmonogram = this.createHarmonogram.bind(this);
        this.changeStatusToProjected = this.changeStatusToProjected.bind(this);
        this.generateCosts = this.generateCosts.bind(this);
        this.hideSendModal = this.hideSendModal.bind(this);
        this.sendChangeStatusToProjected =  this.sendChangeStatusToProjected.bind(this);

    } 
    hideSendModal(){this.setState({sendModalState:false})}
    generateCosts()
    {
        this.ask("groups", "getCennikData", this.state.data.cennik);
        //planeta.calculateCosts()
    }
    changeStatusToProjected()
    {
        this.setState({sendModalState:true})
    }
    sendChangeStatusToProjected()
    {
        this.setState({sendModalState:false});
        this.ask("groups", "changeStatusToProjected", this.state.id);
    }
    createHarmonogram()
    {
        var start = this.state.data.start;
        var end = this.state.data.end;
        var days = this.state.data.days;
        var harm = time.generateCalendar(start,end,days);
        var data = this.state.data;
        data.harmonogram=harm;
        this.setState({data:data});
    }
    generateCalendar()
    {
        this.ask('groups', 'generateCalendarForCourse', JSON.stringify({szkola:this.state.data.place, dni:this.state.data.days, start:this.state.data.start, end:this.state.data.end}));
    }
    toggleDayEdition()
    {
        this.setState({displayDays:!this.state.displayDays})
    }
    togglePills(ev)
    {
        var items = document.getElementsByClassName("nav-item pr")
        for (var a = 0; a<items.length;a++)
        {
            items[a].classList.remove("active")
        }
        ev.target.classList.add("active");
        this.setState({page:ev.target.dataset.id});
    }
    addCandidate(ev)
    {
        var selected = this.state.selected;
        selected.push(ev.target.dataset.id);
        this.setState({select:selected});
    }
    removeCandidate(ev)
    {
        var selected = this.state.selected;
        //console.log("TOREMOVE", ev.target.dataset.id)
        var tosave=[]
        {
            for (var a =0; a<selected.length; a++)
            {
                if (selected[a] !==ev.target.dataset.id) {tosave.push(selected[a])}
            }
        }
        this.setState({selected:tosave});
    }
    filtr(ev) 
    {
        console.log("FILTR", ev)

       var dane = document.getElementsByClassName("kandydat");
       var rodzaj = document.getElementById("rodzaj").value;
       if (rodzaj === "x")
       {
            for (var a = 0; a<dane.length;a++)
            {
                if (dane[a].innerText.includes(document.getElementById("filtr").value)){dane[a].classList.remove("invisibile")}
                else {dane[a].classList.add("invisibile")}
            }
        }
        if (rodzaj ==="i")
        {
            var klucze = document.getElementById("filtr").value.split(",");
            for (var a = 0; a<dane.length;a++)
            {
                var flag = true;
                for (var b=0; b<klucze.length; b++)
                {
                    if (!dane[a].innerText.includes(klucze[b].trim())){flag = false;}
                }
                if (flag){dane[a].classList.remove("invisibile")}
                else {dane[a].classList.add("invisibile")}
            }
        }
        if (rodzaj ==="lub")
        {
            var klucze = document.getElementById("filtr").value.split(",");
            for (var a = 0; a<dane.length;a++)
            {
                var flag = false;
                for (var b=0; b<klucze.length; b++)
                {
                    if (dane[a].innerText.includes(klucze[b].trim())){flag = true;}
                }
                if (flag){dane[a].classList.remove("invisibile")}
                else {dane[a].classList.add("invisibile")}
            }
        }
    }
    readValue(ev){
        var name= ev.target.dataset.id;
        var value = ev.target.value;
        console.log("READ", name, value);
        var data = this.state.data;
        data[name] = value;
        this.setState({data:data});
        if (name==="course"){this.ask('groups', 'getActiveAspirants', JSON.stringify({user:this.props.user, kurs:value}));}
    }
    showInCandidates()
    {
        var aspirants = this.state.aspirants;
        var selected = this.state.selected;
        for (var a =0; a>aspirants.length; a++)
        {
            if (selected.includes(aspirants[a].id)) {return {display:"block"}}
        }
        return {display:"none"};
    }
    showInSelected()
    {
        var aspirants = this.state.aspirants;
        var selected = this.state.selected;
        for (var a =0; a>aspirants.length; a++)
        {
            if (selected.includes(aspirants[a].id)) {return {display:"block"}}
        }
        return {display:"none"};
    }
    saveCourse()
    {
        var data = this.state.data;
        data.selected = JSON.stringify(this.state.selected);
        data.harmonogram = JSON.stringify(data.harmonogram);
        this.ask('groups', 'saveCourse', JSON.stringify(data));
        data.harmonogram = JSON.parse(data.harmonogram);
    }
    componentDidMount()
    {
        var link = window.location.href.split('/');
        var id = link[link.length-1]
        var tryb = link[link.length-2]
        console.log("SETUP", tryb, id)
            this.ask('groups', 'getCourseNames', null);
            this.ask('groups', 'getLectorsNames', null);
            this.ask('groups', 'getPlacesList', this.props.user);
            this.ask('groups', 'getCennik', this.props.user);
            this.ask('groups', 'getAcounts', null);
            //this.ask('groups', 'getActiveAspirants', this.props.user);
            this.ask('groups', 'getProgram', null);
        if (tryb === "groups")
        {
            this.setState({id:"NEW",title:"Tworzenie nowej grupy"})
        }
        if (tryb === "edit")
        {
            this.ask("groups", 'getGroupToEdit', id);

            this.setState({id:id,title:"Edycja grupy"})
        }
        

    }
    editCurse(ev){console.log(ev.target.dataset)}
    hideModal(){this.setState({modalState:false});}
    readDayWeeks(ev){
        var data = this.state.data;
        var days = JSON.parse(data.days)
        days[ev.target.dataset.id]= ev.target.value;
        data.days = JSON.stringify(days);
        this.setState({data:data});
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getGrups':self.setState({ grupy:response.data.data, loaded:true});break;
                case 'getGroupStatus':self.setState({groupStatus:response.data.data});break;
                case 'getCourseNames':self.setState({kursy:response.data.data});break;
                case 'getLectorsNames':self.setState({lektorzy:response.data.data});break;
                case 'getCennik':self.setState({cennik:response.data.data});break;
                case 'getPlacesList':self.setState({placowki:response.data.data});break;
                case 'getActiveAspirants':self.setState({aspirants:response.data.data});break;
                case 'getProgram':self.setState({program:response.data.data});break;
                case 'getGroupToEdit':
                    var dx = response.data.data[0];
                    dx.harmonogram = JSON.parse(dx.harmonogram);
                    console.log("DX", dx)
                    setTimeout(function(){if (dx.harmonogram.length>0){self.generateCosts()}},500)
                    //if (dx.harmonogram.length>0){self.generateCosts()}
                    self.setState({data:dx});
                    self.ask("groups", "getSavedCandidates",response.data.data[0].selected );
                    break;
                case 'getSavedCandidates':
                    var candidates = self.state.aspirants;
                    // candidates =candidates.concat(response.data.data);
                    // candidates = candidates.union(candidates,  response.data.data);
                    candidates = planeta.mergeArrays(candidates, response.data.data);
                    self.setState({aspirants:candidates, select:JSON.parse(data),selected:JSON.parse(data)});
                case 'generateCalendarForCourse': console.log(response.data);break;
                case 'getCennikData':
                       var temp = planeta.calculateCosts(self.state.data,JSON.parse(response.data.data[0].data));
                       self.setState({wycena:temp})
                    break;

                case 'getAcounts' :self.setState({konta:response.data.data});break;
                    default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    render() {
        var editDay = {display:"none"};
        var showDays = {display:"block"};
        if (this.state.displayDays)
        {
            showDays = {display:"none"};
            editDay = {display:"block"};
        }
        var week=['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
        var harmonogram = this.state.data.harmonogram;
        if (typeof harmonogram === null){harmonogram=[];}
     return(
         <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <h3><FaUsers /> {this.state.title} <span className="float-right">
                {(this.state.id==="NEW") ? (<Link className="btn btn-success btn-sm"  to="/groups/list/" onClick={this.saveCourse}>Zapisz nowy</Link>) : (<div>
                                    <button className="btn btn-success btn-sm" onClick={this.saveCourse}>Zapisz <i className="far fa-save"></i></button>&nbsp;
                                    <button className="btn btn-success btn-sm" onClick={this.changeStatusToProjected}>Zaprojektowano <i className="far fa-paper-plane"></i></button>

                                    </div>)}
                </span></h3>
                    <div className="prinfo">
                        <div className="row">
                            <div className="col-sm-4">
                                
                                <h5>Konfiguracja grupy</h5>
                                <label>Indywidualna nazwa kursu</label>
                                <input className="myInput" data-id="name"  value={this.state.data.name} onChange={this.readValue} />
                                <label>Kurs</label>
                                <select className="myInput" data-id="course" value={this.state.data.course} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    {this.state.kursy.map((key)=>(
                                        <option key={key.id} value={key.id} >{key.nazwa}</option>
                                    ))}
                                </select>
                                <label>Czas trwania zajęć</label>
                                <select className="myInput" data-id="czas" value={this.state.data.czas} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    <option value="30">30 minut</option>
                                    <option value="45">45 minut</option>
                                    <option value="60">60 minut</option>
                                    <option value="75">75 minut</option>
                                    <option value="90">90 minut</option>
                                    <option value="120">120 minut</option>
                                
                                </select>
                                <label>Lektor</label>
                                <select className="myInput" data-id="lector" value={this.state.data.lector} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    {this.state.lektorzy.map((key)=>(
                                        <option key={key.id} value={key.id} >{key.name}</option>
                                    ))}
                                </select>
                                <label>Placówka</label>
                                <select className="myInput" data-id="place" value={this.state.data.place} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    {this.state.placowki.map((key)=>(
                                        <option key={key.id} value={key.id} >{key.nazwa}</option>
                                    ))}
                                </select>
                                <label>Cennik</label>
                                <select className="myInput" data-id="cennik" value={this.state.data.cennik} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    {this.state.cennik.map((key)=>(
                                        <option key={key.id} value={key.id} >{key.name}</option>
                                    ))}
                                
                                </select>
                                <label>Konto do wpłat</label>
                                <select className="myInput" data-id="konto" value={this.state.data.konto} onChange={this.readValue}>
                                <option value="0">Wybierz</option>
                                {this.state.konta.map((key)=>(
                                    <option key={key.id} value={key.id} >{key.nazwa}</option>
                                ))}
                                </select>
                                <label>Program nauczania</label>
                                <select className="myInput" data-id="program" value={this.state.data.program} onChange={this.readValue}>
                                    <option value="0">Wybierz</option>
                                    {this.state.program.map((key)=>(
                                        <option key={key.id} value={key.id} >{key.nazwa}</option>
                                    ))}
                                </select>
                                <label>Przewidywana data rozpoczęcia</label>
                                <input type="date" className="myInput" data-id="start" value={this.state.data.start} onChange={this.readValue} />
                                <label>Przewidywana data zakończenia</label>
                                <input type="date" className="myInput" data-id="end" value={this.state.data.end} onChange={this.readValue} />
                                <div>
                                    <br></br>
                                        <button className="btn btn-secondary btn-sm" onClick={this.toggleDayEdition} >{(this.state.displayDays) ? "Zamknij":"Edytuj dni"}</button>
                                    <div style={showDays}>
                                        <WeekDays days={this.state.data.days} />
                                    </div>
                                    <div className="weekDays" style={editDay}>
                                        <div className="day">Poniedziałek<span className="float-right"><input data-id="1" type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day">Wtorek<span className="float-right"><input  data-id="2" type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day">Środa<span className="float-right"><input  data-id="3" type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day">Czwartek<span className="float-right"><input  data-id="4"type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day">Piątek<span className="float-right"><input  data-id="5" type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day libero">Sobota<span className="float-right"><input  data-id="6" type="time" onChange={this.readDayWeeks}/></span></div>
                                        <div className="day libero festivo">Niedziela<span className="float-right"><input  data-id="7" type="time" onChange={this.readDayWeeks}/></span></div>
                                    </div>
                                </div>
                                <label>Koszt pomieszczenia</label>
                                <input type="text" className="myInput" data-id="kosztsali" value={this.state.data.kosztsali} data-type="number" onChange={this.readValue} />
                                <br></br><br></br>
                                
                                <br></br>
                            </div>
                            <div className="col-sm-8">
                                <ul className="nav">
                                    <li className="nav-item pr active" data-id="1"  onClick={this.togglePills}>Uczestnicy</li>
                                    <li className="nav-item pr" data-id="2"  onClick={this.togglePills}>Kalendarz zajęć</li>
                                    <li className="nav-item pr" data-id="3" onClick={this.togglePills}>Podsumowanie finansowe</li>
                                </ul> 
                                <div className="navContainer">
                                        {parseInt(this.state.page)===1? (<div>
                                            <h6>Zarządzaj kandydatami</h6>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                <label>Rodzaj filtra</label>
                                                <select className="myInput" id="rodzaj" onChange={this.filtr} >
                                                    <option value="x">Filtr tekstowy</option>

                                                    <option value="i">[i] Wszystkie elementy muszą występować</option>
                                                    <option value="lub">[lub] Wysępike przynajmniej jeden element z listy</option>
                                                </select>
                                                <label>Treść filtra (po przecinku)</label>
                                                <input type="text" className="myInput" data-id="filtr" id="filtr" onChange={this.filtr} />
                                                {this.state.aspirants.map((key)=>(
                                                <div key={key.id} style={(this.state.selected.includes(key.id) ) ? this.showInCandidates():{display:"block"}}>
                                                <div  className="kandydat">
                                                    <div className="top">Wybrany kurs: {key.kurs} 
                                                    <span className="float-right"><button data-id={key.id} className="btn btn-success btn-sm" onClick={this.addCandidate}>Dołącz</button></span>
                                                    </div>
                                                    <div className="content">{key.imie} {key.nazwisko} </div>
                                                    <div>{(key.notes!==null && key.notes!=="") ? (<div className="newSchool">{key.notes}</div>):""}</div>
                                                    <div className="bottom"><small>{key.nazwa} {key.klasa}</small></div>
                                                </div>
                                                </div>
                                                ))}
                                                </div>
                                                <div className="col-sm-6">
                                                    <h6>Wybrani kandydaci: {this.state.selected.length}</h6>
                                                    {JSON.stringify(this.state.selected)}
                                                    {this.state.aspirants.map((key)=>(
                                                <div key={key.id} style={(!this.state.selected.includes(key.id)) ? this.showInCandidates():{display:"block"}}>
                                                <div  className="kandydat">
                                                    <div className="top">Wybrany kurs: {key.kurs}
                                                    <span className="float-right"><button data-id={key.id} className="btn btn-danger btn-sm" onClick={this.removeCandidate}>Usuń</button></span>
                                                    </div>
                                                    <div className="content">{key.imie} {key.nazwisko} </div>
                                                    <div>{(key.notes!==null && key.notes!=="") ? (<div className="newSchool">{key.notes}</div>):""}</div>
                                                    <div className="bottom"><small>{key.nazwa} {key.klasa}</small></div>
                                                </div>
                                                </div>
                                                ))}
                                                </div>
                                            </div>
                                        </div>):""}
                                        {parseInt(this.state.page)===2 ? (<div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                {(window.location.href.includes('groups/create')) ? (<div>Opcja niedostępna dla niezapisanych formularzy</div>):(
                                                    <div>
                                                        {(this.state.data.start.length<5 || this.state.data.start.length<5) ? 
                                                            (<div className="myInfo">Nie wybrano wszystich istotnych parametrów kursu</div>):
                                                            (<div>
                                                                <button className="btn btn-secondary btn-sm" onClick={this.createHarmonogram}>Sprawdź harmonogram</button>
                                                                </div>)}
                                                       { (harmonogram.length>0)?
                                                        (<table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <td>LP</td>
                                                                    <td>Data</td>
                                                                    <td>Dzień tygodnia</td>
                                                                    <td>Godzina</td>
                                                                    <td>Uwagi</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {harmonogram.map((key,i)=>(<tr key={i}>
                                                                    <td>{key.count}</td>
                                                                    <td>{key.data}</td>
                                                                    <td>{week[key.weekDay]}</td>
                                                                    <td>{key.hour}</td>
                                                                    <td>{key.desc}</td>
                                                            </tr>))}
                                                            </tbody>
                                                        
                                                        
                                                        </table>):"Dane zostaną zaprezentowane po wygenerowaniu"
                                                        }
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                        </div>):""}
                                        {parseInt(this.state.page)===3 ? (<div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                {(window.location.href.includes('groups/create')) ? (<div>Opcja niedostępna dla niezapisanych formularzy</div>):(
                                                    <div>
                                                        {(this.state.data.start.length<5 || this.state.data.start.length<5) ? 
                                                            (<div className="myInfo">Statystyka finansowa będzie dostępna wówczas, gdy będzie wygenerowany harmonogram. Do wygenerowania harmonogramu należy uzupełnić wszystkie parametry kursu.</div>):
                                                            (<div>Statystyka finansowa wygenerowana na podstawie wszystkich parametrów kursu. 
                                                            </div>)}
                                                            <div>
                                                               {(this.state.wycena.length>0) ? (<table className="table table-striped">
                                                                   <thead>
                                                                       <tr>
                                                                           <td>Parametr</td>
                                                                           <td>Wartość</td>
                                                                       </tr>
                                                                   </thead>
                                                                   <tbody>
                                                                       {this.state.wycena.map((key,i)=>(<tr key={i}>
                                                                           <td>{key.name}</td>
                                                                           <td>{key.val}</td>
                                                                       </tr>))}
                                                                   </tbody>
                                                               </table>):(<div>Wygeneruj dane, to je zobaczysz :)</div>)} 
                                                            </div>
                                                    </div>
                                                    
                                                )}
                                                </div>
                                            </div>
                                        </div>):""}
                                </div>
                            </div>
                            
                        </div>                  
                        
                    </div>
                
                </div>
                
            </div>
<br></br>


         <Modal
        show={this.state.modalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {this.state.server}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideModal}>Zamknij</button>
        </Modal.Footer>
      </Modal>



      <Modal
        show={this.state.sendModalState}
       
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.hideSendModal}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Komunikat serwera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
              Naciśnięcie przycisku publikuj spowoduje rozesłanie ankiet za pomocą SMS
              do wszytskich rodziców i uruchomienie ankety.
              Czy na pewno chcesz opublikować ten kurs?
          </p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={this.hideSendModal}>Zamknij</button>
            <button className="btn btn-success" onClick={this.sendChangeStatusToProjected}>Publikuj</button>
        </Modal.Footer>
      </Modal>
         </div>

     );
    }
 }