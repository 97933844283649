import React from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
// import CodesitTable from './../common/codesittable.js';
// import PaymentTable from './../common/paymenttable.js';
import {FaCreditCard }  from "react-icons/fa";

 export default class ParentPayment extends React.Component {
    constructor() {
        super();   

        this.state = {
           paymentList:[],
        };
        this.ask = this.ask.bind(this);
        this.updateForm = this.updateForm.bind(this);
        
        this.componentDidMount = this.componentDidMount.bind(this);
        

    } 

    componentDidMount()
    {
        this.ask("user", "getPaymentData", null);
        
    }
    realizePayment = (ev) => {
        this.ask('user', 'initializePayment', ev.target.dataset.id)
    }
    ask(section, command, data = null)
    { 
        const params = new URLSearchParams(); 
        //params.append('asc',security.securityKey());
        params.append('section', section);
        params.append('command', command);
        if (data!==null){params.append('data', data);}
        params.append('token', Cookies.get("planetarozwoju"))
        var self = this;
        axios.post(window.location.protocol+ '//' + window.location.hostname,  params).then(function (response) {
            ////console.log("Wnioski",response.data);
            //self.setState({wnioski: response.data});
            
        if (response.data.status==="OK")
        {
            console.log("COMMAND:", command);
            switch(command)
            {
                case 'getPaymentData': self.setState({ paymentList:response.data.data, mode:0});break;
                case 'initializePayment':console.log(response.data.data)  ;break;
                default:break;
                
            }
        }
        else
        {
            console.log(response.data);
            if (response.data.status==="err")
            {
                if (typeof response.data.data.target !== 'undefined')
                {
                    if (response.data.data.target === 'modal'){self.setState({modalState:true, server:response.data.data.message})}
                    else
                    {
                        self.setState({serverResponse: response.data.data.message });
                    }
                }
            }
        }
            // self.setState({pobrano: true});
        }).catch(function (error) {console.log(error);});
    }
    showPayment = (ev) => {
        var data = ev.target.dataset;
        console.log(ev.target, ev.target.dataset)
        window.confirmAlert({
            title: 'Informacja',
            message: "Numer konta: " + data.konto +", tytułem: " + data.kod +", płatne do dnia: " + data.termin ,
            buttons: [
            //   {
            //     label: 'Potwierdź',
            //     className:"btn btn-danger",
            //     //onClick: () => {self.ask('administration', 'deleteAccount', dataset.id)}
            //   },
              {
                label: 'Zamknij',
                onClick: () => {}
              }
            ]
          });
    }
    updateForm(data)
    {
        var self = this;
        switch (data.target.dataset.content)
        {
            case "mail": self.setState({mail: data.target.value}); break;
            case "pass": self.setState({pass: data.target.value}); break;
            case 'newpass': self.setState({newpass: data.target.value}); break;
            default:break;
        }
        
    }

    render() {
        var header=[{title:"Imię", data:"imie"},
        {title:"Nazwisko", data:"nazwisko"},
        {title:"Klasa", data:"klasa"},
        {title:"Data urodzenia", data:"urodzony"}
    ];
     return (<div className="container">
        <div className="row">
            <div className="col-sm-12">
                <h3><FaCreditCard /> Lista Twoich płatności</h3>
                <div className="prinfo">
                <table className="table">
                <thead>
                    <tr>
                        <th>Kwota</th>
                        <th>Tytuł</th>
                        <th>kod</th>
                        <th>status</th>
                        <th>Termin</th>
                        <th>Płatności</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.paymentList.map((key)=>(<tr key={key.id}>
                    <td>{parseFloat(key.kwota).toFixed(2)} zł</td>
                    <td>{key.tytul}</td>
                    <td>{key.kod}</td>
                    <td>{key.status}</td>
                    <td>{key.termin}</td>
                    <td>
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button className="btn btn-success btn-sm" onClick={this.realizePayment} data-id={key.id} >online</button>
                        <button className="btn btn-success btn-sm" onClick={this.showPayment} data-id={key.id} data-konto={key.numer} data-termin={key.termin} data-kod={key.kod}>Przelewem</button>
                    </div>
                    
                    </td>
                </tr>))}
                </tbody>
                </table>
                </div>
                {/* <PaymentTable data={this.state.paymentList} readData={this.updateForm} /> */}
                {/* <CodesitTable data={this.state.childrenList} headers={header} /> */}
            </div>
        </div>
     </div>)
    }
 }